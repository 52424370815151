
import React, { useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import directoryServices from '../directoryServices';
import Pagination from '../../Header/Pagination';
import DeleteDialogueBox from '../../Common/deleteDialogue';
import { CommonUtilities } from '../../../commonUtils';

export default function ArtistManagement(param: any) {

    let [RelatedArtistListing, setRelatedArtistListing] = useState<any>([]);
    let [RelatedArtistTotalRow, setRelatedArtistTotalRow] = useState<any>([]);
    var [sliderOpen, setsliderOpen] = useState(param.open);
    let [activeTab, setactiveTab] = useState('grid');
    let [SearchField, setSearch] = useState('');
    let [deleteJob, setDelete] = useState(0);
    let [confirmationData, setconfirmationData] = useState<any>([]);

 //    console.log(param)
    const closePage = () => {
        setsliderOpen(true)
        param.onChangeClose("close");
    }

    useEffect(() => {
        ApiCall();
    }, [param])

    var relatedPayload = {
        pageNumber: 1,
        pageSize: 10,
        text: "",
        display: "related",
    }

    const ApiCall = () => {

        if (SearchField.length > 0) {
            relatedPayload.text = SearchField;
        }
        directoryServices.RelatedArtistListing(relatedPayload).then((res) => {
            setRelatedArtistListing(res)
            setRelatedArtistTotalRow(res[0] ? res[0].totalRows : 0)
        })
    }

    const handleNameOnChange = (n: any) => {
        relatedPayload.pageNumber = n.pageNumber;
        relatedPayload.pageSize = n.pageSize;
        console.log(relatedPayload);
        ApiCall()
    }

    const Confirmation = (e: any) => {

        if (e == 1) {
            directoryServices.saveArtist({ creatorCode: param.selectedDetail.kontactCode, kontactCodes: [confirmationData.kontactCode] }).then((res: any) => {
                ApiCall();
            })
        }
        setDelete(0);
    }

    return (
        <><Drawer open={param.open}
            onClose={closePage}
            direction='right'
            className='react-drawer-left  allowHscroll allowVscroll'>

            <div className="ws-6 box pull-right  full-height-vh">

                <div className="box-header-main">
                    <button className="btn-primary pull-left" onClick={() => { setsliderOpen(false); param.onChangeClose(false) }}>
                        <i className="icon-circle-left"></i> Back
                    </button>
                    <h2 className="pull-left "> Related Artist(s) for {param.selectedDetail.kontactDisplay}</h2>

                </div>

                <div className='ws-12 box'>
                    <div className="landing ws-12 pLarge">
                        <div className="pt ">
                            <h1 className="ws-12 text-bold">Search artist database</h1>
                            <div className="ws-6">
                                <button className="btn-success m0 ws-3 pull-left" onClick={() => ApiCall()}>Search</button>
                                <input className="ws-9 m0 heightReset noBorderRadius" id="searchKontact" type="text" onChange={(e: any) => { setSearch(e.target.value) }} placeholder="Enter Search" name="searchKontact" />
                            </div>
                        </div>

                    </div>

                    <div className="box-content ws-12 pLarge">
                        <div className="ws-12 mbl">
                            <h2 className="ws-8">Showing results for <span className="info "> ({RelatedArtistTotalRow})</span></h2>

                            <div className="nav nav-tabs p0 m0 ws-4 pull-right" ng-init="activeTab = 'grid'">
                                <a onClick={() => { setactiveTab('grid') }} className={activeTab == 'grid' ? "btn-purple btn-sm pull-right bg-level2" : "btn-purple btn-sm pull-right"}><i className="icon-grid"></i></a>
                                <a onClick={() => { setactiveTab('list') }} className={activeTab == 'list' ? "btn-purple btn-sm pull-right bg-level2" : "btn-purple btn-sm pull-right"}><i className="icon-list2"></i></a>
                            </div>
                        </div>

                        {(activeTab == 'grid') && <div className='ws-12'>

                            {RelatedArtistListing?.map((values: any) => {
                                return (
                                    <div className="creatorImg pm pull-left " ng-repeat="directories in directoriesCreatorList">

                                        <div className="box shadowCoverPicture shadow">
                                            <div className="box shadowCoverPicture" ng-show="directories.creatorImage">

                                                {(values.creatorImage) && <div className="coverPicture">
                                                    <img className="ws-12" src={values.creatorImage} />
                                                </div>}

                                                {!(values.creatorImage) && <i className="icon-pictures1 primary pull-none coverPicture ptl"></i>}

                                                <div className="ws-12 mt pl">
                                                    <div className="ws-10">
                                                        <label className="control-label ws-12 text-bold text-uppercase labelFormatting text-m">{ CommonUtilities.longCharacter(values.kontactDisplay, 18) } </label>
                                                        <label className="control-label ws-12 text-bold text-uppercase labelFormatting text-m">{ CommonUtilities.longCharacter(values.kontactCode, 18) } </label>
                                                    </div>

                                                    <div className="ws-2">
                                                        <a className="btn-success pull-right btn-xs" onClick={() => { setDelete(1); setconfirmationData(values) }}>
                                                            <i className="icon-LinkMade border-right">
                                                                <span className="path1 hide"></span>
                                                                <span className="path2 hide"></span>
                                                            </i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) }

                        </div>}

                        {(activeTab == 'list') && <div className='ws-12'>

                            <div className='table-responsive'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th >kontact Name</th>
                                            <th >kontact Code</th>
                                            <th >FirstName</th>
                                            <th >LastName</th>
                                            <th >Operation</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        { RelatedArtistListing?.map((values: any) => {
                                            return (
                                                <tr ng-repeat="directories in directoriesCreatorList" >
                                                    <td> {values.kontactName}</td>
                                                    <td> {values.kontactCode}</td>
                                                    <td> {values.firstName}</td>
                                                    <td> {values.lastName}</td>
                                                    <td>
                                                    <a className="btn-success pull-right btn-xs" onClick={() => { setDelete(1); setconfirmationData(values) }}>
                                                            <i className="icon-LinkMade border-right">
                                                                <span className="path1 hide"></span>
                                                                <span className="path2 hide"></span>
                                                            </i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            )

                                        }) }

                                    </tbody>
                                </table>
                            </div>

                        </div>}

                        {(RelatedArtistTotalRow == 0) && <div className="ws-12 mtl">
                            <span className="alert alert-success ws-12 text-center">No data Available</span>
                        </div>} 

                    </div>

                    <div className='ws-12 pll pbl mbl'>
                        {(() => {

                            if ( RelatedArtistTotalRow > 10) {
                                return <Pagination onChange={handleNameOnChange} totalRows={RelatedArtistTotalRow} />
                            }

                        })()}

                        <button className="btn-danger pull-right" onClick={() => { setsliderOpen(false); param.onChangeClose(false) }}>
                            Close
                        </button>
                    </div>

                    {(() => {
                        if (deleteJob && deleteJob == 1) {
                            return <DeleteDialogueBox param={true} message={"Link " + confirmationData.kontactCode + " to main creator " + param.selectedDetail.kontactDisplay} onChange={Confirmation} />
                        }
                    })()}
                </div>
            </div>

        </Drawer></>
    )
}