import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend,CategoryScale,LinearScale,BarElement, Title } from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { CommonUtilities } from "../../commonUtils";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function AreaChart(chartPassedData : any) { 

  return ( <>
    {chartPassedData?.chartPassedData?.data?.labels.length > 0 && <div style={CommonUtilities.parseStyleString(chartPassedData?.style)}>
      <Line options={chartPassedData.chartPassedData.options} data={chartPassedData.chartPassedData.data} />
    </div>}

    {chartPassedData?.chartPassedData?.data?.labels.length ==0 && <span className="text-center text-bold ws-12 mtl grey"> No Data Available. </span>}
      
  </>)
}