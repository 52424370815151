
import Drawer from 'react-modern-drawer';
import React, { Suspense, useState, useEffect } from "react"; 
import commonServices from '../../Common/commonServices';
import { Filter } from '../../../filter';

export default function NewContainer(param: any) {

    let [transaction_Ref, settransaction_Ref] = useState("");
    let [transTypeCode, setTransTypeCode] = useState("");
    let [description, setdescription] = useState("");


    const closeNewContainerSlider =()=>{
        param.closeNewPanel();
    }

    const closeSaveContainerSlider =()=>{
        
        var payload = {
            containerArea: "",
            periodFrom: String(Filter.DateTimeFormatNumber(global.periodSelected.periodFrom)),
            periodTo: String(Filter.DateTimeFormatNumber(global.periodSelected.periodTo)) ,
            transaction_Ref: transaction_Ref
        }

        commonServices.saveTransaction(payload).then((respond:any)=>{
            console.log(respond);
            param.closeNewPanel();
        })

    }

    return ( <>

        <Drawer open={param.open} onClose={() => closeNewContainerSlider} direction='left' className='react-drawer' >

            <div className="box noBorderRadius noBorder ws-6 pbl pull-left full-height-vh allowVscroll">

                <div className="box-header-main">
                    <h2> Add/Edit Transaction</h2>
                </div>

                <div className="box-content">
                    <div className="form-format">
                        <label className="control-label" >Transaction Reference*</label>

                        <div className="label-entries">
                            <input type="text" id="transactionReference" value={transaction_Ref} onChange={(e:any)=> settransaction_Ref(e.target.value)} name="transactionReference" placeholder="Transaction Reference" required/>
                        </div>
                    </div>

                    <div className="form-format">
                        <label className="control-label">Transaction Type</label>

                        <div className="label-entries">
                            <select name="transTypeCode" id="transTypeCode" value={transTypeCode} onChange={(e:any)=> setTransTypeCode(e.target.value)}>
                                <option value=""  >Choose Transaction Type</option>
                                <option value="0" label="SALES CREDIT">SALES CREDIT</option>
                                <option value="1" label="COST">COST</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-format">
                        <label className="control-label">Description</label>

                        <div className="label-entries">
                            <textarea rows={10} maxLength={2000} placeholder="Description" id="description" onChange={(e:any)=> setdescription(e.target.value)} value={description}></textarea>
                        </div>
                    </div>

                </div>

                <div className="box footer pm noBorderRadius">
                    <button className="btn-danger pull-right" onClick={closeNewContainerSlider}>
                        <i className="icon-close"></i> Close
                    </button>
                    <button className="btn-success pull-right" onClick={closeSaveContainerSlider}>
                        <i className="icon-save"></i> Save
                    </button>
                </div>
            
            </div>

        </Drawer>
    </> )
}