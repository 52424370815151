import React, { useState, useEffect } from 'react';
import korrectLogo from '../../../assets/images/korrectLogoIcon.png';
import adminLogo from '../../../assets/images/app.png';
import assetsCompareServices from './assetsCompareServices';

export default function AssetCompare() {

    let [exclude ,setExclude] = useState<any>([]);
    let [openInclude ,setOpenInclude] = useState(false);
    let [categoryList, setCategoryList] = useState<any[]>([]);
    let [adminAssetsList, setAdminAssetsList] = useState<any[]>([]);
    let [korrectAssetsList, setKorrectAssetsList] = useState<any[]>([]);
    let [exportAll, setExportAll] = useState(true);
    let [searchFor, setsearchFor] = useState("");

    let [criteriaParamsSelection, setCriteriaParamsSelection] = useState<any[]>([
        { selection : false, value : "Release Title", criteria : 'RTitle' } ,
        { selection : false, value : "Track Title", criteria : 'TTitle' },
        { selection : false, value : "Release Artist",criteria : 'RArtist' },
        { selection : false, value : "Track Artist", criteria : 'TArtist' },
        { selection : false, value : "ISRC", criteria : 'ISRC' },
        { selection : false, value : "Catalog Number", criteria : 'catlogNum' },
        { selection : false, value : "Custom ID", criteria : 'CustomID' }
    ]);

    const removeTabName     = (index:any)=>{  
        criteriaParamsSelection[index].selection = false;  
        setCriteriaParamsSelection(existingValues => [ ...criteriaParamsSelection]);
    }
 
    const addTabName= (index:any)=>{  

        criteriaParamsSelection[index].selection = true;  
        setCriteriaParamsSelection(existingValues => [ ...criteriaParamsSelection]);

    }

    const getAssetCompareData = ()=>{  

        const criteriaParams = {
            link           :   "https://master.dws03r4d6820k.amplifyapp.com/api/assets/",
            xapikey        :   "25520aaf-e317-4794-822f-8aae77b48c28",
            searchFor      : searchFor
        }

        for(var $i =0 ; $i < criteriaParamsSelection?.length ; $i++){
            if(criteriaParamsSelection[$i].selection){ 
                Object.assign(criteriaParams, { [criteriaParamsSelection[$i].criteria] : true } );
            }else{ 
                Object.assign(criteriaParams, { [criteriaParamsSelection[$i].criteria] : false } );
            }
        } 

        assetsCompareServices.getAssetCompare(criteriaParams).then((res) => {

            for(var $i =0 ; $i < res[0].adminAssets?.length ; $i++){
                if(res[0].adminAssets[$i]){
                    Object.assign(res[0].adminAssets[$i], { selected : false } );
                }
            } 
            setAdminAssetsList(res[0].adminAssets);
            console.log(adminAssetsList);

            for(var $j =0 ; $j < res[0].korrectAssets?.length ; $j++){
                if(res[0].korrectAssets[$j]){
                    Object.assign(res[0].korrectAssets[$j], { selected : true } );
                }
            } 
            setKorrectAssetsList(res[0].korrectAssets);
            console.log(korrectAssetsList);
        })
    }

    const exportListings = (value : any)=>{  
        console.log('ss');
        let arrayList : any = [];

        if(value == 'korrectAsset'){
            var obj = adminAssetsList.filter(  (asset: any ) => (asset?.selected === true)  );
            console.log(obj);

            for(var $i =0 ; $i < obj?.length ; $i++){
                arrayList.push( { ISRC : obj[$i].ISRC, catalogNumber  : obj[$i].catalogNumber } );
            } 
        }

        if(value == 'adminAsset'){
            var obj1 = korrectAssetsList.filter(  (asset: any ) => (asset?.selected === true)   );
            console.log(obj1);
            for(var $i =0 ; $i < obj1?.length ; $i++){
                arrayList.push( { ISRC : obj1[$i].ISRC, catalogNumber  : obj1[$i].catalogNumber } );
            } 
        }

        if(exportAll){
            var obj3 = korrectAssetsList;
            console.log(obj3);
            arrayList = []
            /*
            for(var $i =0 ; $i < obj3?.length ; $i++){
                arrayList.push( { ISRC : obj3[$i].ISRC, catalogNumber  : obj3[$i].catalogNumber } );
            } */
        }
        
        const criteriaParams = {
            link           :   "https://master.dws03r4d6820k.amplifyapp.com/api/assets/",
            xapikey        :   "25520aaf-e317-4794-822f-8aae77b48c28",
            exportType     : value,
            searchFor      : searchFor,
            assetList      : arrayList
        }
        console.log(criteriaParams);
        
        assetsCompareServices.exportAssetCompare(criteriaParams).then((res) => { });

    }

    const onOptionChange = (type : any ,index :any)=>{  
        var KTCode = korrectAssetsList[index]?.ISRC ? korrectAssetsList[index].ISRC : "" 
        var KRCode = korrectAssetsList[index]?.catalogNumber ? korrectAssetsList[index].catalogNumber : "" 

        var ATCode = adminAssetsList[index]?.ISRC ? adminAssetsList[index].ISRC : ""  
        var ARCode = adminAssetsList[index]?.releaseCode ? adminAssetsList[index].releaseCode : "" 
        
        if(type == 'korrect' && KRCode != "" && KTCode != ""){  
            if(korrectAssetsList[index]){
                korrectAssetsList[index].selected   = true;
            }

            if(adminAssetsList[index]){
                adminAssetsList[index].selected     = false
            }
        }

        if(type == 'admin' && ATCode != "" && ARCode != "") {  

            if(korrectAssetsList[index]){
                korrectAssetsList[index].selected   = false;
            }

            if(adminAssetsList[index]){
                adminAssetsList[index].selected     = true
            }   
               
        } 
        
        setAdminAssetsList(adminAssetsList);
        setKorrectAssetsList(korrectAssetsList);
         
    } 


    const compareDataField = (firstVal :any , secondVal :any) =>{

        if(firstVal &&  !secondVal){
            return "bg-level10"
        }
        
        if(!firstVal &&  secondVal){
            return "bg-level10"
        }


        if(firstVal != secondVal){
            return "bg-level10"
        }

        if(firstVal == secondVal){
            return ""
        }
    }

    const searchSummary =(e:any) =>{
        setsearchFor(e);
        getAssetCompareData();
    }

    return (<> 
    <div className="landing ws-12"> 
        
        <div className='ws-12 pLarge'>

            <div className="ptxl mtl">
                <div className="ws-9">
                    <span className="ws-12 text-bold text-xl">Asset Management</span>
                    <span className="ws-12 ptl text-bold text-m turquoise">Asset Compare</span>
                </div>

                <div className="ws-12 ptxl">
                
                    <div className="ws-12 mbl">
                        <button className="btn white pLarge bg-level14 m0 ws-2" onClick={()=>searchSummary(searchFor)}> Search</button>
                        <input type="text" value={searchFor} onChange={(e) => setsearchFor(e.target.value)}  className="pLarge ws-10 m0 heightReset"  placeholder="Type to search" />
                    </div>

                </div>

                <div className='ws-12 bgWhite'>

                <div className="ws-12 box"> 

                    <div className="pm ws-12"> 
                        <span className="pull-left pt text-bold text-m mr mt">Criteria : </span>
                        
                            { criteriaParamsSelection?.map((item: any, index : any) => { return (
                                <>{<div className={ item.selection? 'tagging bg-level1 mr forceSingleLine' : 'hide' }>
                                    <span className="bgTransparent text-bold">{item.value}</span>
                                    <a className="btn-info pull-left btn-xxs borderNone bgTransparent m0" onClick={()=>removeTabName(index)}><i className="icon-cross1 text-s mt"></i></a> 
                                </div>}</>
                            )} )} 

                        <a className="p0 pull-left btn-success borderNone bgTransparent m0" onClick={()=>setOpenInclude(!openInclude)}><i className="icon-plus text-xxl"></i></a>
                    </div>

                    {openInclude && true && <> <hr/>
                        <div className="pm ws-12"> 
                            
                            { criteriaParamsSelection?.map((item: any, index : any) => { return (
                                <>{<div className={ item.selection?  'hide' : "tagging bg-level1 mr forceSingleLine" }>
                                    <span className="bgTransparent text-bold">{item.value}</span>
                                    <a className="btn-info pull-left btn-xxs borderNone bgTransparent m0" onClick={()=> { addTabName(index) }  }><i className="icon-plus"></i></a> 
                                </div>}</>
                            )} )} 

                            {categoryList?.map((list: any) => { 
                                return (<>
                                {(() => { 
                                    if(exclude.filter((e:any)=> e == list).length == 0 ){
                                        return ( 
                                        <div className="tagging bg-level1 mr forceSingleLine">
                                            <span className="bgTransparent text-bold">{list}</span>
                                            <a className="btn-info pull-left btn-xxs borderNone bgTransparent" onClick={()=>addTabName(list)}><i className="icon-plus2 text-s mt"></i></a>                                                 
                                        </div>
                                        )
                                    }
                                })()} 
                                </>)
                            })} 
                        </div>

                    </>}
                    
                    <div className="ws-12 mtp ptl">
                        <a className="btn bg-level7 white pull-right pm btn-m forceSingleLine" onClick={()=> { exportListings('korrectAsset') }  }>Export For Korrect</a>   
                        <a className="btn btn-success pull-right" onClick={()=> { exportListings('adminAsset') }  }>Export For Admin </a>   

                        <div className='pull-right mrl'>
                            <span className='mtl ml pull-right'>Export All</span>

                            <label className="switch mt">
                                <input type="checkbox" checked={exportAll}name="creatorFlag" onClick={()=>setExportAll(!exportAll)}/>
                                {exportAll && <span className="slider round text-left white pm pts " >Yes</span>}
                                {!exportAll && <span className="slider round text-right white pm pts sliderOff">No</span>  }                  
                            </label>
                        </div>

                        {<div className={ korrectAssetsList?.length > 0 ? 'hide' : 'ws-12' }><span className="text-center text-bold ws-12 mtl"> No Data Available. </span></div>} 

                    </div>

                    <div className= { korrectAssetsList?.length <= 0 ? "hide" : 'ws-12 pm ptl pt pb' }><span className="text-bold text-m">Showing <span className="info">{ korrectAssetsList?.length }</span> results</span></div>


                    <div className='ws-12 mtl pLarge tableVertical'>
                        <table className={ adminAssetsList.length > 0 ? 'tableVertical ws-12' : 'hide tableVertical'}>
                            <thead>
                                <tr>  
                                    <th></th>
                                    <th className="text-center ws-">ISRC</th>
                                    <th className="text-center">Track Title</th>
                                    <th className="text-center">Track Artist</th>
                                    <th className="text-center">Release Code</th>
                                    <th className="text-center">Release Title</th>
                                    <th className="text-center">Release Artist</th>
                                    <th className="text-center">CustomID</th>
                                </tr>
                            </thead>
                            <tbody>

                                {korrectAssetsList?.map((list: any, index: any) => { return (
                                    <>
                                        <tr className='bg-level1'>   
                                            <td width='60' className='ptl pbl'> <input className='mt mrl' type='radio' name = { index } onChange={(e)=>onOptionChange("korrect" , index)}  />  <img className="pull-none" src={korrectLogo} width={20} /></td> 
                                            <td className={compareDataField(list?.ISRC          , adminAssetsList[index]?.ISRC) + " text-uppercase"}            >{ list?.ISRC }</td>
                                            <td className={compareDataField(list?.trackTitle    , adminAssetsList[index]?.trackTitle) + " text-uppercase"}      >{ list?.trackTitle }</td> 
                                            <td className={compareDataField(list?.trackArtist   , adminAssetsList[index]?.trackArtist) + " text-uppercase"}     >{ list?.trackArtist }</td>                                             
                                            <td className={compareDataField(list?.catalogNumber , adminAssetsList[index]?.releaseCode) + " text-uppercase"}     >{ list?.catalogNumber }</td>
                                            <td className={compareDataField(list?.name          , adminAssetsList[index]?.releaseName) + " text-uppercase"}     >{ list?.name }</td> 
                                            <td className={compareDataField(list?.artist        , adminAssetsList[index]?.releaseArtist) + " text-uppercase"}   >{ list?.artist }</td>     
                                            <td className={compareDataField(list?.customID      , adminAssetsList[index]?.customID) + " text-uppercase"}        >{ list?.customID }</td>                                                 
                                        </tr>

                                        <tr className='bg-level8'>   
                                            <td  className='ptl pbl'> <input className='mt mrl' type='radio' name = { index } value='admin'  onChange={(e)=>onOptionChange("admin" , index)} /> <img className="pull-none" src={adminLogo} width={20} /> </td>   
                                            <td className={compareDataField(list?.ISRC          , adminAssetsList[index]?.ISRC) + " text-uppercase"}            >{ adminAssetsList[index]?.ISRC }</td>
                                            <td className={compareDataField(list?.trackTitle    , adminAssetsList[index]?.trackTitle) + " text-uppercase"}      >{ adminAssetsList[index]?.trackTitle }</td> 
                                            <td className={compareDataField(list?.trackArtist   , adminAssetsList[index]?.trackArtist) + " text-uppercase"}     >{ adminAssetsList[index]?.trackArtist }</td>
                                            <td className={compareDataField(list?.catalogNumber , adminAssetsList[index]?.releaseCode) + " text-uppercase"}     >{ adminAssetsList[index]?.releaseCode }</td>
                                            <td className={compareDataField(list?.name          , adminAssetsList[index]?.releaseName) + " text-uppercase"}     >{ adminAssetsList[index]?.releaseName }</td> 
                                            <td className={compareDataField(list?.artist        , adminAssetsList[index]?.releaseArtist) + " text-uppercase"}   >{ adminAssetsList[index]?.releaseArtist }</td>      
                                            <td className={compareDataField(list?.customID      , adminAssetsList[index]?.customID) + " text-uppercase"}        >{ list?.customID }</td>                                                    
                                        </tr>
                                        <tr>    
                                            <td > <span className='ps ws-12'> &nbsp;</span></td>                                      
                                            <td className="text-uppercase"></td>
                                            <td className="text-uppercase"></td>
                                            <td className="text-uppercase"></td>
                                        </tr>
                                    </>
                                )} )} 

                            </tbody>
                        </table>
                    </div>
                </div>

                </div>

            </div>

        </div>
    </div>
    </>)
}