import React, { useState } from "react";
import Drawer from 'react-modern-drawer';
import contactService from "./contactService";
import Pagination from "../Header/Pagination";
import DeleteDialogueBox from "../Common/deleteDialogue";

export function ContactFormSearch (param :any) {
 
    let [isOpen, setIsOpen] = useState(false);
    let [creatorFlag, setcreatorFlag] = useState(false);  
    let [digitalprovideropt , setdigitalprovideropt] = useState("All"); 
    let [contactNameOrCode, setcontactNameOrCode] = useState("");   
    let [addContact , setaddContact] = useState(false);
    let [emptyField , setemptyField] = useState(false);
    let [ContactList, initContactList] = useState<any[]>([]);
    let [ContactListSearch, initContactSearchList] = useState<any[]>([]);
    let [totalRows ,setTotalRows] = useState(0);
    let [deleteJob , setDelete] = useState(0);
    let [deletekontactName , setdeletekontactName] = useState<any>([]);

    var payload = {"pageSize": 1, 'pageNumber': 10, "creator": creatorFlag , "digitalOnly": digitalprovideropt  == "All"?  null :false, "contactNameOrCode" : contactNameOrCode}
    
    const searchContact = () => {
        if(contactNameOrCode != ''){
            setemptyField(false)
            if(digitalprovideropt == "All"){
                payload.digitalOnly = null;
            }

            if(digitalprovideropt == "Only"){
                payload.digitalOnly = true;
            }

            if(digitalprovideropt == "Non"){
                payload.digitalOnly = false;
            }

            contactService.SelectContact({...payload , 'pageSize' : 10 , 'pageNumber' : 10}).then((res) => {
                initContactList(res)
                initContactSearchList(res)
                setTotalRows( res[0] ? res.length: 0);

                if(res.length > 0){   
                    ContactListSearch = res;        
                    var obj = res.splice(0,10) 
                    initContactList(obj)   
                }
            })
        }else {
            setemptyField(true)
        }
    }

    const Confirmation = (e :any) =>{
        
        if(e == 1 ){
            contactService.deleteContact(deletekontactName);
            contactService.SelectContact(payload).then((res :any) => {
                initContactList(res)
                setTotalRows( res[0] ? res[0].totalRows : 0)
            })
        }
        setDelete(0);
    }

    const handleNameOnChange = (n: any) => { 
        ContactList = ContactListSearch;
        var num = n.pageNumber;
        var size  = n.pageSize;
        var obj = ContactList.splice((num),size)  
        initContactList(obj)  
    } 

    const selectContact = (e:any) =>{
        param.onChange(e)
    }

    return (<div className="">
        
        <button type="button" className="btn-success" onClick={() => {setIsOpen(true)} }><i className="icon-user-add"></i></button>
        <Drawer 
        open={isOpen}
        onClose={()=>setIsOpen(false)}
        direction='right'
        className='react-drawer'>

            <div className="ws-8 box pull-right  full-height-vh  allowHscroll allowVscroll">
                <div className="ws-12">
                    <div className="modal-header">
                        <div className="box-header-main">
                            <h2 className="ws-10">Search Contact Form</h2>
                            <i className="icon-close pull-right ptl" onClick={()=>setIsOpen(false)}></i>  
                        </div>
                    </div>

                    <div className="box-content">
                        { emptyField && <div className="alert alert-info">Try entering a Contact Code to search</div>}

                        <div className="form-format ws-4">
                            <label className="control-label p0" >Contact code/display/name</label>
                            <div className="label-entries"> 
                                <input type="text" className="form-control forceSingleLine" onChange={(e:any) => setcontactNameOrCode(e.target.value)} placeholder="Enter Contact Code/Display/Name"  name="searchBox"/> 
                            </div>
                        </div>

                        <div className="form-format ws-1">
                            <label>Creator</label>
                            <label className="switch">
                                <input type="checkbox" checked={creatorFlag}name="creatorFlag" onClick={()=>setcreatorFlag(!creatorFlag)}/>
                                {creatorFlag && <span className="slider round text-left white pm pts " >Yes</span>}
                                {!creatorFlag && <span className="slider round text-right white pm pts sliderOff">No</span>  }                  
                            </label>
                        </div>

                        <div className="form-format ws-7">
                            <div className="label-entries">
                                <div className="ws-2 forceSingleLine" onClick={()=>setdigitalprovideropt("All")}  > <input type="radio" value="null" checked={true} name="digitalprovideropt" /> All </div>
                                <div className="ws-5 forceSingleLine" onClick={()=>setdigitalprovideropt("Only")} > <input type="radio"name="digitalprovideropt" />Digital Provider Only</div>
                                <div className="ws-5 forceSingleLine"  onClick={()=>setdigitalprovideropt("Non") }> <input type="radio" name="digitalprovideropt" /> Non digital Provider</div>
                            </div>
                        </div>
                    </div>

                    <div className="box-footer">
                        <a className="btn btn-small btn-success mr" onClick={searchContact}><i className="icon-search"></i>Search </a>
                        <a className="btn btn-small btn-success hide"><i className="icon-tick"></i> New Contact</a>
                    </div>
                </div>
                
                <div className="ws-12 pm">
                    <div className="ws-12 box"> 

                    <div className="box-header well">  <h2><span>{totalRows} record(s) found</span></h2> </div>

                    {addContact && <div className="alert alert-success"> Contact  has been deleted successfully </div> }

                    <div className="box-content ws-12">
                        { (totalRows == 0) && <div className="alert alert-info">No records to display </div> }

                        { (totalRows > 0) && <div className="table-responsive">
                            <table className="table box">
                                <thead>
                                <tr className="forceSingleLine">
                                    <th>Contact code</th>
                                    <th>Contact display</th>
                                    <th>Contact name</th>
                                    <th style={{width : "100px"}}>Operation</th>
                                </tr>
                                </thead>
                                    {ContactList?.map( (value) => {
                                        return (
                                            <tr>
                                                <td>{value.kontactCode} </td>
                                                <td>{value.kontactDisplay} </td>
                                                <td>{value.kontactName} </td>            
                                                <td>
                                                    <a className="btn btn-success btn-sm" onClick={() => {selectContact(value) ;setIsOpen(false)} }><i className="icon-user-add"></i></a>
                                                    <a className="btn btn-success btn-sm hide" ><i className="icon-edit"></i></a>
                                                    <a className={value.enableDeleteFlag? "btn btn-danger btn-sm " : "hide"} onClick={() =>{setDelete(1);setdeletekontactName(value)}}><i className="icon-close"></i> </a>
                                                </td>
                                            </tr>
                                        )                                    
                                        })}
                                <tbody>
                                </tbody>
                            </table>
                        </div>}

                        <div className="ws-12">
                        {     
                            (() => {
                                if(totalRows>0 && totalRows >10){  return <Pagination onChange={handleNameOnChange} totalRows={totalRows} /> }                                                                        
                            })()
                        }
                        </div>
                    </div>

                    { (totalRows > 0 || true) && <div className="box-footer ">
                        <button className="btn-pink pull-right" onClick={()=>setIsOpen(false)}>Close </button>
                    </div>}

                    {
                        (() => {
                            if(deleteJob && deleteJob==1){
                                    return <DeleteDialogueBox param={true} message ={"delete contact " + deletekontactName.kontactName} onChange={Confirmation}/>
                            }
                        })()
                    }
                    </div>

                </div>
            </div>
        </Drawer>
        
    </div>)
}