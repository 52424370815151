import Drawer from 'react-modern-drawer';
import React, { useEffect, useState } from "react";
import directoryServices from '../directoryServices';

export default function LinkAssetBundle(param: any) {
    console.log(param)

    let options = [ "Track" , "Release" , "Song" ]; 
    var [AssetType, setAssetType] = useState(param.activeListing);
    var [sliderOpen, setsliderOpen] = useState(param.open);
    const closePage = () => {
        setsliderOpen(true)
        param.onAddClose("close");
    }

    useEffect(() => {
        if (param.editData) {
            setEditValue({ ...EditValue, ...{ bundleCode: param.editData.bundleCode } });
        }
    }, [param.open]);


    let [EditValue, setEditValue] = useState<any>([]);
    const EditHandler = (e: any) => {
        var field_name = e.target.name;
        var field_value = e.target.value;

        console.log(field_name);
        setEditValue({ ...EditValue, ...{ [field_name]: field_value } });

    }

    let [saveStatus, setSaveStatus] = useState(false)
    const SaveAsset = () => {
        setSaveStatus(true)

        setEditValue({ ...EditValue });
        console.log(EditValue)

        directoryServices.bundleAssetLink(EditValue)
    }

    var assetCode = {
        AssetType: "T",
        CategoryName: " ",
        display: "category",
        pageNumber: 1,
        pageSize: 10,
        searchFor: ""
    }
    let [AssetCode, setAssetCode] = useState<any>([]);
    const AssetCodeSearch = () => {
        if(AssetType == "Track"){ assetCode.AssetType = 'T'} 
        if(AssetType == "Release"){ assetCode.AssetType = 'R'}  
        if(AssetType == "Song"){ assetCode.AssetType = 'S'}  

        directoryServices.MainAssetListing(assetCode).then((values: any) => {
            setAssetCode(values[0].categoryDetails)
        })
    }

    const AssignAssetCode = (e: any) => {
        setEditValue({ ...EditValue, ...{ assetCode: e.assetCode } });
    }

    const handleChangeButt = (e:any) => {
        console.log(e)
        AssetType= e.target.value;
        setAssetType(e.target.value) 
    }

    return (
    <>
        <Drawer open={param.open}
            onClose={closePage}
            direction='right'
            className='react-drawer-left allowVscroll allowHscroll'>

            <div className="ws-6 box pull-right box noBorderRadius noBorder">
                <div className='box-header bg-blue'>
                    <h1 className="pull-left ws-10"><i className="fa fa-sticky-note"></i> New Asset to Bundle</h1>
                </div>

                <div className='box-content'>
                    <div className="ws-12">

                        <div className="ws-11 form-format m0 mb">
                            <label className="control-label">Bundle Code</label>
                            <div className="label-entries">
                                <input key="bundleCode" type="text" disabled={EditValue.bundleCode} name="bundleCode" value={EditValue.bundleCode} onChange={EditHandler} placeholder="Enter bundle Code" />
                            </div>
                        </div>

                        <div className="ws-11 form-format m0 mb">
                            <label className="control-label">Asset Type*</label>
                            <div className="label-entries">
                                <select value={AssetType} onChange={handleChangeButt} className="p0">
                                    {options.map((option) => ( <option value={option}>{option}</option> ))}   
                                </select> 
                            </div>
                        </div>

                        <div className="ws-11 form-format m0 mb">
                            <label className="control-label ws-12">Asset Code*</label>
                            <div className="label-entries ws-10">
                                <input key="assetCode" type="text" name="assetCode" value={EditValue.assetCode} onChange={EditHandler} placeholder="Enter asset Code" />
                            </div>
                            <button className="btn-success btn-xs pts" onClick={() => AssetCodeSearch()}>
                                <i className="icon-search"></i>
                            </button>


                            <div className="ws-12 ptl">
                                <table className="table ml ws-10">
                                    <tbody>
                                        {AssetCode?.map((values: any) => {
                                            return (
                                                <tr>
                                                    <td width="20%"> <input key="addassetCode" type="checkbox" onClick={() => AssignAssetCode(values)} /> </td>
                                                    <td>{values.name} ({values.assetCode})</td>
                                                </tr>
                                            )
                                        })
                                        }

                                    </tbody>

                                </table>

                            </div>
                        </div>

                        <div className="ws-11 form-format m0 mb">
                            <label className="control-label">Asset Sequence</label>
                            <div className="label-entries">
                                <input key="assetSequence" type="number" name="assetSequence" value={EditValue.title} onChange={EditHandler} placeholder="Enter asset Sequence" />
                            </div>
                        </div>

                        <div className="ws-11 form-format m0 mb">
                            <label className="control-label">Asset Weighting</label>
                            <div className="label-entries">
                                <input key="assetWeighting" type="number" name="assetWeighting" value={EditValue.title} onChange={EditHandler} placeholder="Enter asset Weighting" />
                            </div>
                        </div>

                    </div>

                </div>

                <div className="box-footer">

                    <div className="pull-right">
                        <button className="btn-success pull-right" onClick={SaveAsset}> <i className="icon-save"></i> Save </button>
                        <button className="btn-pink pull-right" onClick={closePage}> <i className="icon-close"></i> Close </button>
                    </div>
                </div>
        
            </div>

        </Drawer>
    </>
    )
}