import React, { useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import directoryServices from '../directoryServices';

export default function BundleSlider(param: any) {
    let [saveStatus, setSaveStatus] = useState(false);
    let [EditValue, setEditValue] = useState<any>([]);
    let [imageUpload, setImageUpload] = useState<any>([]);
    let [listImprint, setlistImprint] = useState<any>([]);

    const closePage = () => {
        if (saveStatus) {
            param.onAddClose(EditValue);
        }
        if (!saveStatus) {
            param.onAddClose("close");
        }
    }

    useEffect(() => {
        directoryServices.imprintListing().then((res) => {
            setlistImprint(res)
        })

        if (param.editData) {
            setEditValue({ ...EditValue, ...param.editData });
        }

    }, [param.open]);

    const EditHandler = (e: any) => {
        var field_name = e.target.name;
        var field_value = e.target.value;

        console.log(field_name)
        if (field_name == "label") {
            var value = (listImprint.filter((data: any) => (data.companyName == field_value))[0]);
            setEditValue({ ...EditValue, ...{ [field_name]: value } });
        } else {
            setEditValue({ ...EditValue, ...{ [field_name]: field_value } });
        }
    }

    const handleFileChange = (e: any) => {
        var fileName = e.target.files[0];
        if (e.target.files) {
            setImageUpload(fileName)
            fileName = URL.createObjectURL(fileName)
            setEditValue({ ...EditValue, ...{ bundleImage: fileName } });
        }
    }

    const SaveAsset = () => {
        console.log(EditValue)
        setSaveStatus(true)
        directoryServices.BundleSave(imageUpload, EditValue)
    }

    return (<>
        <Drawer open={param.open}
            onClose={closePage}
            direction='right'
            className='react-drawer-left allowVscroll allowHscroll'>

            <div className="ws-6 box pull-right box noBorderRadius noBorder full-height-vh">
                <div className='box-header-main'>
                    <h1 className="pull-left ws-10 white">New Bundle </h1>
                </div>

                <div className='box-content'>
                    <div className="ws-12">
                        <div className="form-format m0 mb">
                            <label className="control-label" >Bundle Code*</label>

                            <div className="label-entries ws-12"> 
                                <input key="bundleCode" type="text" className="ws-11" value={EditValue.bundleCode} name="bundleCode" onChange={EditHandler} placeholder="Enter bundleCode" />                                
                            </div>
                        </div>

                        <div className="ws-11">
                            <div className="form-format m0 mb">
                                <label className="control-label">Imprint*</label>
                                <div className="label-entries ws-12">
                                    <select key="label" className="" name="bundleImprint" value={EditValue.bundleImprint} onChange={EditHandler}>
                                        <option value="" > Choose an Imprint</option>
                                        {listImprint?.map(((values: any) => {
                                            return (
                                                <option value={values.companyName} label={values.companyName}>{values.companyName}</option>
                                            )
                                        }))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="form-format m0 mb">
                            <label className="control-label">Title*</label>
                            <div className="label-entries">
                                <input key="bundleTitle" type="text" name="bundleTitle" value={EditValue.bundleTitle} onChange={EditHandler} placeholder="Enter Title" />
                            </div>
                        </div>

                        <div className="form-format m0 mb">
                            <label className="control-label">Dim 1</label>
                            <div className="label-entries">
                                <input key="bundleDim1Code" type="text" name="bundleDim1Code" value={EditValue.bundleDim1Code} onChange={EditHandler} placeholder="Enter Dim 1" />
                            </div>
                        </div>

                        <div className="form-format m0 mb">
                            <label className="control-label">Dim 2</label>
                            <div className="label-entries">
                                <input key="bundleDim2Code" type="text" name="bundleDim2Code" value={EditValue.bundleDim2Code} onChange={EditHandler} placeholder="Enter Dim 2" />
                            </div>
                        </div>

                        <div className="form-format m0 mb">
                            <label className="control-label">Dim 3</label>
                            <div className="label-entries">
                                <input key="bundleDim3Code" type="text" name="bundleDim3Code" value={EditValue.bundleDim3Code} onChange={EditHandler} placeholder="Enter Dim 3" />
                            </div>
                        </div>

                        <div className="ws-12 mtl">
                            <label className="control-label text-left ws-12 mt text-bold">Bundle Image</label>

                            <div className="ws-12 pm mb">
                                <div className="ws-4">

                                    {(EditValue.bundleImage) && <div className="box shadowCoverPicture shadow " >
                                        <div className="coverPicture" style={{ height: "100%" }}>
                                            <img src={EditValue.bundleImage} className="ws-12" style={{ height: "100%" }} />
                                            <img className="ws-12 hide" />
                                        </div>
                                    </div>}

                                    {!(EditValue.bundleImage) && <table className="coverPictureContainer">
                                        <tbody><tr>
                                            <td style={{ verticalAlign: "middle" }}>
                                                <i className="icon-new-registration"></i>
                                            </td>
                                        </tr>
                                        </tbody></table>}
                                </div>

                                <div className="ws-8 pm">
                                    <label className="btn-primary" style={{ marginTop: "15%" }}>Upload Image
                                        <input type="file" accept="image/*" id="file-upload" className="hide" onChange={handleFileChange} />
                                    </label>
                                    <span className="ws-12 mt">Maximum file size 2mb file types accepted .jpg/ .png/ .bmp (Resolution Minimum 300px X 230px).</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                </div>

                <div className="box-footer">

                    <div className="pull-right">
                        <button className="btn-pink pull-right" onClick={closePage}> <i className="icon-close"></i> Close </button>
                        <button className="btn-success pull-right" onClick={SaveAsset}> <i className="icon-save"></i> Save </button>
                    </div>
                </div>
            </div>
        </Drawer>
    </>)
}