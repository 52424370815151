import Summary from './summary'; 
import 'react-responsive-modal/styles.css';
import React, { Suspense, useState, useEffect } from "react"; 
import DirectoryAnalytic from './Analytic/DirectoryAnalytic';

export default function Management(param: any) {
 
    let [activeTab, setActiveTab] = useState('Summary');

    return(
    <div className="landing ws-12"> 
 
        <div className="ws-12 plxl prxl mtl">
            <div className="ptxl">
                <div className="ws-6">
                    <span className="ws-12 text-bold text-xl">Music Directories</span>
                    <span className="ws-12 ptl text-bold text-m turquoise">Chart Summary</span>
                    
                </div>
                <div className='ws-6'>
                     <ul className="nav nav-tabs p0 m0 pull-right"> 
                        <li className={`m0 mt mr borderNone pll prl ${activeTab === 'Summary' ? 'active' : ''}`} onClick={() => setActiveTab('Summary')}>Chart Summary</li>
                        <li className={`m0 mt mr borderNone pll prl ${activeTab === 'Analytic' ? 'active' : ''}`} onClick={() => setActiveTab('Analytic')}>Data Table</li>
                    </ul> 
                </div>
            </div>

            <div className="ws-12 pull-right">  

                {activeTab === 'Analytic' && <div className="ws-12 allowHscroll allowVscroll forceScroll mbl mtxl"> 
                    <DirectoryAnalytic selectedDetail={{}} DAOpen= {"All"}/>
                </div>}

                {activeTab === 'Summary' && <div className="ws-12 allowHscroll allowVscroll forceScroll mbl mtxl"> 
                    <Summary/>
                </div>}

            </div> 
        </div>   
          
    </div>
    )
}