
import React, { useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import directoryServices from '../directoryServices';

export default function AssetManagement(param: any) {

 //    console.log(param)
    console.log(param.selectedDetail.kontactCode)
    var [sliderOpen, setsliderOpen] = useState(param.open);
    const closePage = () => {
        setsliderOpen(true)
        param.onChangeClose("close");
    }

    var relatedAssetPayload = {
        creatorCode: param.selectedDetail.kontactCode,
        RelatedAssets: 1,
        pageNumber: 1,
        pageSize: 10
    }

    var UnrelatedAssetPayload = {
        creatorCode: param.selectedDetail.kontactCode,
        UnRelatedAssets: 1,
        pageNumber: 1,
        pageSize: 10
    }


    var AllAssetPayload = {
        creatorCode: param.selectedDetail.kontactCode,
        pageNumber: 1,
        pageSize: 10
    }


    let [AssetCatalogue, setAssetCatalogue] = useState<any>([]);
    let [RelatedAssetCatalogue, setRelatedAssetCatalogue] = useState<any>([]);
    let [UnRelatedAssetCatalogue, setUnRelatedAssetCatalogue] = useState<any>([]);
    useEffect(() => {

        console.log("open")
        ApiCall();

    }, [param])

    const ApiCall = () => {
        directoryServices.creatorRelatedAsset(AllAssetPayload).then((res) => {
            setAssetCatalogue(res)
            console.log(res)
        })

        directoryServices.creatorRelatedAsset(relatedAssetPayload).then((res) => {
            setRelatedAssetCatalogue(res)
            console.log(res)
        })


        directoryServices.creatorRelatedAsset(UnrelatedAssetPayload).then((res) => {
            setUnRelatedAssetCatalogue(res)
            console.log(res)
        })
    }

    const AssetManagementSave = (e: any) => {
        console.log(e)
        directoryServices.MusicAssetDirectorySave(e).then((res) => {
            ApiCall();
        })
    }

    const AssetManagementDelete = (e: any) => {
        console.log(e)
        directoryServices.MusicAssetDirectoryDelete({ CreatorCode: e.creatorCode, MusicAssetCode: e.musicAssetCode, MusicAssetType: e.musicAssetType }).then((res) => {
            ApiCall();
        })
    }

    return (

        <div>
            <Drawer open={param.open}
                onClose={closePage}
                direction='right'
                className='react-drawer-left full-height-vh allowHscroll' style={{ width: "100%" }}>
                <div className="ws-7 box pull-right " style={{ height: "100%"}}>

                    <div className="box-header-main ws-12">
                        <h2 className="pull-left"> Asset Management for {param.selectedDetail.kontactCode}</h2>
                    </div>

                    <div className='box-content pLarge'>
                        <div className="ws-12 mt mbl">
                            <button className="btn-primary pm" onClick={() => { setsliderOpen(false); param.onChangeClose(false) }}>
                                <i className="icon-circle-left"></i> Back
                            </button>
                        </div>

                        <div className='ws-12'>
                            <div className='ws-4'>
                                <div className="ws-12 border-bottom">
                                    <h2 className="pm text-center">Asset catalogue</h2>
                                </div>

                                <div className="ws-12 box-content">

                                    {
                                        AssetCatalogue?.map((values: any) => {
                                            return (
                                                <div className="box shadow pm mb">
                                                    <div className="ws-2">
                                                        <a className="ml pull-left p0 invisible" onClick={() => AssetManagementSave(values)}>
                                                            <i className="icon-circle-left text-l"></i>
                                                        </a>
                                                        <div className="circularLogo text-center pull-left">
                                                            <i className="icon-play-with-c zFront" style={{ left: "0" }}> </i>

                                                            <img style={{ width: "50px", height: "50px" }} src="" ng-show="searchSongItem.songImage" className="hide" />
                                                            <i className="icon-pictures1 primary positionRelative" style={{ fontSize: "43px" }}></i>
                                                        </div>
                                                    </div>
                                                    <div className="ws-10">
                                                        <div className="ws-12">
                                                            <div className="ws-12 text-right text-bold ">
                                                                {values.name} <a className="ml pull-right p0" onClick={() => AssetManagementSave(values)}> <i className="icon-circle-right text-l"></i> </a>
                                                            </div>
                                                            <div className="ws-12 text-right pt pb "> {values.musicAssetCode} </div>
                                                            <div className="ws-12 text-right ">  {values.musicAssetType} </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>

                            </div>
                            <div className='ws-4 border-right border-left'>
                                <div className="ws-12 border-bottom">
                                    <h2 className="pm text-center ">Related Assets</h2>
                                </div>
                                <div className='border-right border-left'>
                                    <div className="ws-12 box-content">

                                        {
                                            RelatedAssetCatalogue?.map((values: any) => {
                                                return (
                                                    <div className="box shadow pm mb">
                                                        <div className="ws-2">
                                                            <a className="ml pull-left p0" onClick={() => AssetManagementDelete(values)}> <i className="icon-circle-left text-l"></i> </a>
                                                            <div className="circularLogo text-center pull-left">
                                                                <i className="icon-play-with-c zFront" style={{ left: "0" }}> </i>

                                                                <img style={{ width: "50px", height: "50px" }} src="" ng-show="searchSongItem.songImage" className="hide" />
                                                                <i className="icon-pictures1 primary positionRelative" style={{ fontSize: "43px" }}></i>
                                                            </div>
                                                        </div>
                                                        <div className="ws-10">
                                                            <div className="ws-12">
                                                                <div className="ws-12 text-right text-bold "> {values.name}
                                                                    <a className="ml pull-right p0" onClick={() => AssetManagementDelete(values)}> <i className="icon-circle-right text-l"></i> </a>
                                                                </div>
                                                                <div className="ws-12 text-right pt pb "> {values.musicAssetCode} </div>
                                                                <div className="ws-12 text-right ">  {values.musicAssetType} </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className='ws-4'>
                                <div className="ws-12 border-bottom">
                                    <h2 className="pm text-center ">Unrelated Assets</h2>
                                </div>
                                <div className="ws-12 box-content">

                                    {
                                        UnRelatedAssetCatalogue?.map((values: any) => {
                                            return (
                                                <div className="box shadow pm mb">
                                                    <div className="ws-2">
                                                        <a className="ml pull-left p0" onClick={() => AssetManagementSave(values)}> <i className="icon-circle-left text-l"></i> </a>
                                                        <div className="circularLogo text-center pull-left">
                                                            <i className="icon-play-with-c zFront" style={{ left: "0" }}> </i>

                                                            <img style={{ width: "50px", height: "50px" }} src="" ng-show="searchSongItem.songImage" className="hide" />
                                                            <i className="icon-pictures1 primary positionRelative" style={{ fontSize: "43px" }}></i>
                                                        </div>
                                                    </div>
                                                    <div className="ws-10">
                                                        <div className="ws-12">
                                                            <div className="ws-12 text-right text-bold "> {values.name}
                                                                <a className="ml pull-right p0 invisible" > <i className="icon-circle-right text-l"></i> </a>
                                                            </div>
                                                            <div className="ws-12 text-right pt pb "> {values.musicAssetCode} </div>
                                                            <div className="ws-12 text-right ">  {values.musicAssetType} </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer></div>
    )
}