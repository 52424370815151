import CommonSearchSlider from "./commonSearchSlider";
import React, { Suspense, useState, useEffect } from "react";

export default function CommonSearch(param: any) {
 
    let [searchFor, setsearchFor] = useState("");
    let [loopSearch , setLoopSearch] = useState(0)
    let [SliderField, setSliderField] = useState<any>([]); 
    let [conAdditional, setcontractAdditional] = useState<any>([]);
    let [commonSearchSliderOpen, setcommonSearchSliderOpen] = useState(false);

    var pickerColors = [
        '#EF534F', '#C62928', '#980A0B',
        '#BA69C8', '#7B1FA2', '#4A148C',
        '#F57C02', '#E65100',
        '#81C784', '#2F7D31', '#1A5E20',
        '#7FDEEA', '#00838F', '#004D41',
        '#90CAF9', '#029BE5', '#01579B',
        '#DBDBDB', '#000000'
    ];

    const closeSlider = (e: any) => { setcommonSearchSliderOpen(false)  }

    const onchangeTag = (e: any) => {
         
        if(e.SliderField?.length > 0){
            SliderField = e.SliderField;
            setSliderField(SliderField); 
        } 

        conAdditional=e.conAdditional; 
        setcontractAdditional({ ...conAdditional, ...e.conAdditional} )  
    }

    const removeTag = (fieldName: any, value: any) => {
 
        Object.keys(conAdditional).map((key, index) => {

            for (var i = 0; i < conAdditional[key].length; i++) {

                if (conAdditional[key][i] == value) {
                    setcontractAdditional((prevUser: any) => ({  ...prevUser, [fieldName]: conAdditional[fieldName].filter((hobby: any) => hobby !== value),  })); 
                }
            }
        })
    }
    
    const searchForTag = (e: any) => {
        console.log(e)
        searchFor=e;
        setsearchFor(e)
        Object.keys(conAdditional).map(key => {

            if (key == 'searchFor' && searchFor.length > 0) {
                var valueTest = false;
                Object.keys(conAdditional['searchFor']).forEach(function (key) {
                    if (conAdditional['searchFor'][key] == searchFor) { valueTest = true }
                }) 

                if (valueTest == false) {
                    setcontractAdditional((values: any) => ({ ...values, ...{ 'searchFor': [...conAdditional['searchFor'], searchFor] } })); 
                }
 
            } else if (searchFor.length > 0) {
                setcontractAdditional({ ...conAdditional, ...{ 'searchFor': searchFor } });
            }
        })

        if (conAdditional.length == 0 && searchFor.length > 0) {
            setcontractAdditional({ ...conAdditional, ...{ 'searchFor': [searchFor] } }); 
        }

        setLoopSearch(loopSearch+1);
    }
    useEffect(() => {  param.SearchForHandle(conAdditional, true);  }, [loopSearch]);
 
    useEffect(() => {  param.SearchForHandle(conAdditional, false);  }, [conAdditional]);

    const onchangeSearch = (e: any) => {   
        console.log(e)
        param.SearchForHandle(conAdditional, true);   
    } 

    return (<>

        <div className={"mtl " + param.width} >
            <div className="ws-10 pr">
                <button className="btn white pLarge bg-level14 m0 ws-2" onClick={()=>searchForTag(searchFor)}> Search</button>
                <input type="text" value={searchFor} onChange={(e) => setsearchFor(e.target.value)}  className="pLarge ws-10 m0 heightReset" placeholder="Type to search" />
            </div>

            <div className="ws-2">
                <button className="btn white bg-level14 pLarge m0 ws-12" onClick={() => { setcommonSearchSliderOpen(true);}}>
                    Advanced Search
                </button>
            </div>

            <div className="ws-12 forceSingleLine"> 
            {(() => {
                return SliderField.map((value: any) => {
                    return (
                        Object.keys(conAdditional).map((key: any, index: any) => {

                            if (key == value.filterName && conAdditional[key].length > 0) {
                                return (
                                    <div className="tagging-container pull-left" style={{ color: pickerColors[index] }} >
                                        <div className="tagging">
                                            <div className="pull-left pts mrs" style={{ color: pickerColors[index] }}>{value.displayName}:</div>

                                            {(() => {

                                                return ((conAdditional[key]).map((value: any) => {
                                                    return (
                                                        <span className="mrs" style={{ background: pickerColors[index], color : "#ffffff" }} > {value}
                                                            <i className="icon-cancel2 pls pull-right" onClick={() => removeTag(key, value)}></i> 
                                                        </span>
                                                    )
                                                }))
                                            })()}
                                        </div>
                                    </div>
                                )
                            }

                        })
                    )
                })
            })()}

            {Object.keys(conAdditional).map((key: any, index: any) => {
                if (key == 'searchFor' && conAdditional["searchFor"].length > 0) {
                    return (
                    <div className="tagging-container ws-12 pull-left" style={{ color: pickerColors[index] }} >
                        <div className="tagging">
                            <div className="pull-left pts mrs" style={{ color: pickerColors[index] }}>{'SearchFor'}:</div>

                            {(() => {
                                return (
                                    (conAdditional[key]).map((value: any) => {
                                        return (<>
                                            <span className="mrs" style={{ background: pickerColors[index], color : "#ffffff" }} > {value}
                                                <i className="icon-cancel2 pls pull-right" onClick={() => removeTag(key, value)}></i>
                                            </span>
                                        </>)
                                    }))
                            })()}
                        </div>
                    </div>
                    )
                }
            })}
            </div>
        </div>

        {(() => {
            if (commonSearchSliderOpen) {
                return <CommonSearchSlider open={commonSearchSliderOpen} SliderField={SliderField} conAdditional={conAdditional}
                    onchange={closeSlider} onchangeTag={onchangeTag} onchangeSearch={onchangeSearch} type={param.type} title={param.title} />
            }
        })()}

    </> )
}