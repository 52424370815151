import React, { useEffect, useState } from "react";
import ContainerSlider from "./ContainerSlider";
import ContainerServices from "../../container/containerServices";
import { CommonUtilities } from "../../../commonUtils";
import AreaChart from "../../Charts/AreaChart";
import PeriodSelectionForm from "../../PeriodSelection/periodSelectionForm";
import { format } from "date-fns";
import { Filter } from "../../../filter";
import directoryServices from "../directoryServices";
import Pagination from "../../Header/Pagination";

export default function DirectoryAnalytic(param: any) {

    let [totalRows, setTotalRows] = useState(0);
    let [hideGraph,sethideGraph] = useState(true);   
    let [reportingTo, setreportingTo] =useState(""); 
    let [execHeader, setexecHeader] = useState<any>([]);    
    let [headerType, setHeaderType] = useState<any>([]); 
    let [reportingFrom, setreportingFrom] =useState("");
    let [transaction_Ref, setTransaction_Ref] =useState("");
    let [headerMaping, setHeaderMaping] = useState<any>([]);
    let [activeAllTime, setactiveAllTime] = useState(false);
    let [periodSelection, setperiodSelection] = useState(false);
    let [dialogueOpenView,setdialogueOpenView] = useState(false);
    let [activePerformanceTab,setactivePerformanceTab] = useState("Performance");
    let [financialPerformanceList, setfinancialPerformanceList] = useState<any>([]); 

    var payload = {
        type            : "Performance",
        level           : 2,
        pageNumber      : 1,
        pageSize        : 10,
        directoryCode   : "",
        kontactCode     : "",
        companyName     : "",
        assetCode       : "",
        Workspace       : param.DAOpen == "Creator"  || param.DAOpen == "Imprint" || param.DAOpen == "Asset"? "d" : "All",
        periodTo        : reportingTo,
        periodFrom      : reportingFrom,
        transaction_Ref : transaction_Ref,
        SubProvider     : "",
        activeAllTime   : 0
    }
    
    var payBuildload = { type: "BuildWorkSpace" , directoryCode: "", kontactCode: "", companyName: "", assetCode: "",  Workspace: "d" ,  SubProvider : "" }

    useEffect(() => { 
        UpdatePayload();
        setreportingFrom( String(Filter.DateTimeFormatNumber(global.periodSelected.periodFrom)) );
        setreportingTo( String(Filter.DateTimeFormatNumber(global.periodSelected.periodTo)) );

    }, [])    

    const onChangeClose =(e:any)=>{
        console.log(e)
        if(e && e.action === 'search'){ 
            transaction_Ref=e.tranRef
            setTransaction_Ref(transaction_Ref)
            payload.transaction_Ref = transaction_Ref;
            containerSearch();
        }

        if(e && e.action === 'Close'){
            console.log("Close")
        }
        setdialogueOpenView(false);
    }

    const UpdatePayload =()=> { 
        if(param.DAOpen == "Creator"){
            payload.directoryCode   = param?.selectedDetail?.kontactCode;
            payload.kontactCode     = param?.selectedDetail?.kontactCode;
            
            payBuildload.directoryCode   = param?.selectedDetail?.kontactCode;
            payBuildload.kontactCode     = param?.selectedDetail?.kontactCode;            
        } 

        if(param.DAOpen == "Imprint"){
            payload.directoryCode   = param?.selectedDetail?.companyName;
            payload.companyName     = param?.selectedDetail?.companyName;
            
            payBuildload.directoryCode   = param?.selectedDetail?.companyName;
            payBuildload.companyName     = param?.selectedDetail?.companyName;
        } 

        if(param.DAOpen == "Asset"){
            payload.directoryCode   = param?.selectedDetail?.assetCode;
            payload.assetCode     = param?.selectedDetail?.assetCode;
            
            payBuildload.directoryCode   = param?.selectedDetail?.assetCode;
            payBuildload.assetCode     = param?.selectedDetail?.assetCode;
        }

        if(param.DAOpen == "Account"){
            payload.directoryCode   = param?.selectedDetail?.code;
            payload.SubProvider     = param?.selectedDetail?.code;
            
            payBuildload.directoryCode   = param?.selectedDetail?.code;
            payBuildload.SubProvider     = param?.selectedDetail?.code;
        }
    }

    const containerSearch =()=>{
        if(!activeAllTime)  { payload.activeAllTime = 0}
        if(activeAllTime)   { payload.activeAllTime = 1}
        UpdatePayload();
        payload.type = activePerformanceTab;
        CommonUtilities.BtnLoading("containerSearch");
        ContainerServices.financialPerformancesearch(payload).then((values) => { 
            setTotalRows(values?.totalRows ? values.totalRows : 0)
            execHeader = [];
            Object.entries(values.headers.original).forEach(([key, value]) => { 
                var name  = value?.toString().replace(/\s+/g, '')
                execHeader.push(name) 
            });
            setexecHeader(execHeader)

            headerMaping = [];
            Object.entries(values.headers.original).forEach(([key, value]) => {  
                headerMaping.push(value) 
            });
            setHeaderMaping(headerMaping)

            headerType = [];
            Object.entries(values.headers.fieldType).forEach(([key, value]) => {  
                headerType.push(value) 
            });
            setHeaderType(headerType)

            console.log(headerType)
            setfinancialPerformanceList(CommonUtilities.relativeDataRemapping(values.data, values.headers.original));
            //console.log( CommonUtilities.relativeDataRemapping(values.data, values.headers.original))
            if(values){ CommonUtilities.RemoveBtnLoading("containerSearch");}
        })
    }

    const containerExport =()=>{ 
        if(!activeAllTime)  { payload.activeAllTime = 0}
        if(activeAllTime)   { payload.activeAllTime = 1}
        UpdatePayload();
        payload.type = activePerformanceTab;
        CommonUtilities.BtnLoading("containerExport");
        payload = {...payload ,...{exportType : "exportType"}}
        console.log(payload)
        directoryServices.excelGenericExport(payload).then((values :any) => { 
            console.log(values)
            CommonUtilities.RemoveBtnLoading("containerExport");
        } )

    }

    const CallChart = (data : any) => {

        console.log(data)
        var backgroundColor     : any = [];
        var percentageDataRev   : any = [];
        var percentageDataRoy   : any = [];
        var labelsData          : any = [];    
    
        for (var i = 0; i < data.length; i++) { 
            percentageDataRev.push(data[i].totalRev);
            percentageDataRoy.push(data[i].totalRoy);
            labelsData.push(data[i].assetCode);
            
            for (var j = 0; j < CommonUtilities.chartColors.length; j++) {
                backgroundColor.push(CommonUtilities.chartColors[j]);
            }
        }

        const options = {
            layout  : { padding : { top: 0 } },
            plugins : { elements: { bar: { borderWidth: 0, }, } }
        }; 

        var dataObj = {
            labels: labelsData,
            datasets: [
                {
                    label           : 'Asset Rev',
                    data            : percentageDataRev,
                    borderColor     : backgroundColor[0],
                    backgroundColor : backgroundColor,
                    yAxisID         : 'y',
                }, 
                {
                    label           : 'Asset Roy',
                    data            : percentageDataRoy,
                    borderColor     : backgroundColor[1],
                    backgroundColor : backgroundColor,
                    yAxisID         : 'y',
                },
            ]
        };  

        console.log({options : options ,data : dataObj})
        return {options : options ,data : dataObj}        
    }

    const closePeriod = (e: any) => {
        console.log(e)
        periodSelection = false;
        setperiodSelection(false)
    }

    const onChange = (period: any) => {
    
        if (period.periodFrom) { 
            payload.periodFrom = period.periodFrom.year + format(new Date(period.periodFrom.month), 'MMdd');
            reportingFrom = period.periodFrom.year + format(new Date(period.periodFrom.month), 'MMdd');
            setreportingFrom(period.periodFrom.year + format(new Date(period.periodFrom.month), 'MMdd')); 
        }

        if (period.periodTo) {           
            payload.periodTo = period.periodTo.year + format(new Date(period.periodTo.month), 'MMdd');
            reportingTo = period.periodTo.year + format(new Date(period.periodTo.month), 'MMdd') ;
            setreportingTo(period.periodTo.year + format(new Date(period.periodTo.month), 'MMdd')) ;
        }

        containerSearch();
    }

    const tabChange = (e:any) => {
        payload.type = e ;
        activePerformanceTab = e ;
        setactivePerformanceTab(activePerformanceTab)
        containerSearch();
    }

    const BuildWorkSpace = () =>{

        CommonUtilities.BtnLoading("WorkSpaceCall");
        directoryServices.financialPerformance(payBuildload).then((value :any)=>{
            if(value){ CommonUtilities.RemoveBtnLoading("WorkSpaceCall");}
        })
    }

    const PaginationList = (e:any)=>{
        payload.pageNumber  = e.pageNumber;
        payload.pageSize    = e.pageSize;
        payload.type        = activePerformanceTab;
        containerSearch();
    }
    
    return (<> 
        <div className="ws-12">
            
            <div className="pull-left mrxl p0">
                <a className={activePerformanceTab=='Performance' ? 'btn-success active' : 'btn-success'} onClick={()=>{setactivePerformanceTab('Performance'); tabChange("Performance")}}>Performance</a>
                <a className={activePerformanceTab=='Financial'   ? 'btn-success active' : 'btn-success'} onClick={()=>{setactivePerformanceTab('Financial'); tabChange("Financial")}}>Royalties</a>                                        
            </div>
            
            <div className="pull-left mrxl">
                <div className="centerWindow ws-12">
                    <a className="btn-success btn-xl" onClick={()=>setperiodSelection(true)}>                    
                        <i className="icon-angle-right text-m pull-right mll"></i>
                        <span className="pull-right success"> {Filter.NumToDate(reportingTo)}</span>                        
                        <i className="icon-vertical-line ml pull-right mr text-sl success"></i>
                        <span className="pull-right success">{Filter.NumToDate(reportingFrom)}</span>
                    </a>
                </div>

            </div>

            <div className="pull-left mt mr">
                <span className="pull-left mr"> All Time </span>
                <label className="switch">
                    <input type="checkbox" ng-model="activeAllTime" onChange={()=>{activeAllTime = !activeAllTime;setactiveAllTime(activeAllTime);containerSearch();}}/>
                    {activeAllTime && <span className="slider round text-left white pm pts">Yes</span>}
                    {!activeAllTime && <span className="slider round text-right white pm pts sliderOff">No</span>}
                </label>
            </div>

            <div className="pull-left mrxl hide"> 
                <a className={hideGraph? "btn-info bg-level7 btn-xl":"btn-info btn-xl "} onClick={()=>sethideGraph(true)}> <i className="icon-price-list"/>  </a> 
                <a className={!hideGraph?  "btn-info bg-level7 btn-xl":"btn-info btn-xl "} onClick={()=>sethideGraph(false)}> <i className="icon-graph"/>  </a>          
            </div>

            <div className="pull-right">
                <button className="btn-warning buttonLoad" id="WorkSpaceCall" onClick={()=>BuildWorkSpace()}> Build WorkSpace </button>                 
                <button className="btn-success buttonLoad" id="containerExport" onClick={containerExport}> Export  </button>              
                <button className="btn-success buttonLoad" id="containerSearch" onClick={containerSearch}> Search  </button>
                <button className="btn-queue" onClick={()=>setdialogueOpenView(true)}>Filter </button>
            </div>

            <div className="ws-12 ptl">
                {(totalRows > 0 ) && <h2 className="pull-left capitalize  m0"> Search results: {totalRows} </h2>}

                {(totalRows == 0 ) &&<div className="ws-12 mtl prxl pbl">
                    <span className="alert alert-success ws-12 text-center">No data Available</span>
                </div>}

                {(totalRows > 0 ) && <>

                    {(hideGraph) &&<><table className="bgWhite table tablePresentation"> 
                        <thead>
                            <tr className="forceSingleLine"> 
                            { headerMaping.map((headerName :any )=>{
                                return ( <th>{CommonUtilities.headerName(headerName)}</th>)
                            })}                                    
                            </tr>
                        </thead>
                        <tbody>
                        {financialPerformanceList.map((listing :any)=>{
                            return (
                                <tr> 
                                {  execHeader.map((headerName :any,index :any)=>{

                                    if(headerType[index] == "Number"){
                                        return ( <td className="text-right">{listing[headerName]?.toFixed(2)}</td>)
                                    }

                                    if(headerType[index] == "Text"){
                                        return ( <td>{listing[headerName]}</td>)
                                    }
                                }) } 
                                </tr>
                            )
                        })}

                        </tbody>
                    </table>                    

                    { (() => {
                        if (totalRows > 10) {
                            return <div className="ws-12 ptl mbl pbl"><Pagination onChange={PaginationList} totalRows={totalRows} /></div>
                        }
                    })()} 
                    </>}

                    {(!hideGraph) && <div className="ws-12">
                        <div className="box-content box mtl mbl ws-12" > 
                            <div className="ws-12 mbl pb mb"> 
                                <div className="box text-center mlxl" style={{height: "350px" , width: "700px"}}>
                                    <AreaChart chartPassedData={ CallChart(financialPerformanceList) } style={"height: 350px; width: 700px"}/> 
                                </div>
                            </div>
                        </div>
                    </div>}

                </>}
            </div> 
        </div>   
        
        {(() => {         
            if (periodSelection == true) {
                return <PeriodSelectionForm open={periodSelection} closePeriod={closePeriod} onChange={onChange} periodFrom={Filter.convertToDateString(reportingFrom)}  periodTo={Filter.convertToDateString(reportingTo)} />;
            } 
            if (dialogueOpenView) {
                return (<ContainerSlider onChangeClose={onChangeClose} open={dialogueOpenView} selectedDetail={param.selectedDetail} type={param?.DAOpen}/>)
            }
            
        })()} 
    </>)
}