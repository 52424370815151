import { apiUrlPrefix } from "./settings";
import ReactDOM from 'react-dom';
import { format } from "date-fns";
import ScrapingTool from "./components/Scraping/ScrapingTool";
import DirectoriesIndex from "./components/directories/directoriesIndex";
import React, { useState, useEffect } from 'react';
import DataLoader from "./components/DataLoading/DataLoader";
import QuarterRun from "./components/Mechanical/quarterRun"; 
import Reports from "./components/Reports/Reports";
import AssetCompareIndex from './components/MetaData/Assets/AssetCompareIndex'

export class CommonUtilities {

    static monthShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    static monthFull = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    static removeLastCharacter(input: any) {
        var CharLength = input?.length;
        return input.slice(0,(CharLength- 1));
    }

    static BtnLoading = (idName : any) => {
        const value = document.querySelector('[id^="'+ idName+'"]');
        value?.classList.add("loading");
    }

    static RemoveBtnLoading = (idName : any) => {
        const value = document.querySelector('[id^="'+ idName+'"]');         
        value?.classList.remove('loading');         
    }

    static mapQueryParams(queryParams: string) {
        return queryParams
            ? Object.keys(queryParams).map(function (key) {
                return key;// + '=' + queryParams[key]
            }).join('&')
            : ""
    }

    static getAuthAccess() {
        var authToken = { email: '', userId: '' };

        if (window.sessionStorage.getItem('loggedUserRight')) {
            authToken = JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string);
        }
        return authToken;

        if (authToken) {
            return authToken;
        } else {
            this.clearAuthToken();
        }

    }

    static getAuthToken() {
        var authToken = '';

        if (window.sessionStorage.getItem('loggedUserRight')) {
            authToken = JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string).token;
        }
        return authToken;

        if (authToken) {
            return authToken;
        } else {
            this.clearAuthToken();
        }

    }

    static setAuthToken(userDetails: any) {

        //const [myArray, updateMyArray] = useState([]);

        //console.log(userDetails.userEntityRight[0]);
        //console.log(JSON.stringify(userDetails));


        if (!JSON.stringify(userDetails)) {
            this.clearAuthToken();
        } else {

            /* var system = {
                 entityName: "System",
                 entityType: 1,
                 subEntities:
                     [{
                         entityType: 1,
                         isActive: 1,
                         subEntity: "Home",
                         subEntityCode: "home",
                         subEntityID: 1,
                         subEntityRef: 1
                     },  {
                         entityType: 1,
                         isActive: 1,
                         subEntity: "Reports",
                         subEntityCode: "Grid_Reporting",
                         subEntityID: 1,
                         subEntityRef: 1
                     }, {
                         entityType: 1,
                         isActive: 1,
                         subEntity: "Uploader",
                         subEntityCode: "download",
                         subEntityID: 1,
                         subEntityRef: 1
                     }/*, {
                         entityType: 8,
                         isActive: 1,
                         subEntity: "Affiliate",
                         subEntityCode: "Affiliate",
                         subEntityID: 18,
                         subEntityRef: 2622144
                     },{
                         entityType: 1,
                         isActive: 1,
                         subEntity: "Directories",
                         subEntityCode: "Portal_Directories",
                         subEntityID: 1,
                         subEntityRef: 1
                     }]
             };*/

            var temp = userDetails.userEntityRight;
            userDetails.userEntityRight = [];
            //userDetails.userEntityRight.push(system);
            userDetails.userEntityRight.push(...temp);

            //const [names, setNames] = useState(['Alice', 'Bob']);
            //setNames(current => [...current, ...arr]);

            window.sessionStorage.setItem('loggedUserRight', JSON.stringify(userDetails));
            window.sessionStorage.setItem('loggedInUser', JSON.stringify(userDetails));

            window.location.reload();
        }
    }

    static clearAuthToken() {
        window.sessionStorage.removeItem('loggedUserRight');
        window.sessionStorage.removeItem('loggedInUser');
        window.location.reload();
    }

    static async dataPassingHandler(data: any) {
        this.blockUiLoading(false);
        return data.json();
    }

    static async getData(url: string) {
        try {

            this.blockUiLoading(true);

            let response = fetch(apiUrlPrefix + url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-AuthToken': this.getAuthToken()
                }
            })

            response.then(status => {
                // console.log(status.status);
                //console.log(status);
                if (status.status && status.status == 401) {
                    this.clearAuthToken();
                }
            });
            //console.log(response)

            return (this.dataPassingHandler(await response));


        } catch (error) {
            this.blockUiLoading(false);
            console.log('error Api get');
            this.clearAuthToken();
            return null;
        }
    }

    static async getDataThirdParty(url: string, data: any) {

        url = url + data;
        //console.log(url);

        try {

            this.blockUiLoading(true);

            let response = fetch(url, {
                headers: {
                    //"Access-Control-Allow-Origins": "*",
                    //"Access-Control-Allow-Headers": "Content-Type",
                    //"Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-AuthToken': this.getAuthToken()
                }
            })

            response.then(status => {
                // console.log(status.status);
                //console.log(status);
                if (status.status && status.status == 401) {
                    //this.clearAuthToken();
                }
            });
            //console.log(response)

            return (this.dataPassingHandler(await response));


        } catch (error) {
            this.blockUiLoading(false);
            console.log('error Api get');
            //this.clearAuthToken();
            return null;
        }
    }

    static blockUiLoading(startStop: boolean) {
        var element = (<div></div>);

        if (startStop) {
            element = (
                <div>
                    <div className="loader">
                        <div className="bar1"></div>
                        <div className="bar2"></div>
                        <div className="bar3"></div>
                        <div className="bar4"></div>
                        <div className="bar5"></div>
                        <div className="bar6"></div>
                    </div>
                    <div id="block-ui-message-container">
                        &nbsp;
                    </div>
                </div>
            );
        }
        ReactDOM.render(element, document.getElementById('block-ui-container'));
    }

    static reMap = (mapReturn: any) => {
        var tempmapReturn: any[] = [];
        Object.entries(mapReturn).forEach(([key, value]) => { tempmapReturn.push(value) });
        return tempmapReturn;
    }

    static async postData(url: string, params: any) {
        try {
            this.blockUiLoading(true);

            let response = await fetch(apiUrlPrefix + url, {
                method: "POST",
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-AuthToken': this.getAuthToken()
                }
            })

            //console.log(this.dataPassingHandler(await response));

            return (this.dataPassingHandler(await response));

        } catch (error) {

            this.blockUiLoading(false);

            console.log('error Api get');
            this.clearAuthToken();
            return null;
        }
    }

    static async postUploadFile(url: string, file: any, data: any) {
        this.blockUiLoading(true);
        //console.log(data);
        var imgdata = new FormData();
        imgdata.append("file", file) 
        imgdata.append("data", JSON.stringify(data));
        

        try {
            let response = await fetch(apiUrlPrefix + url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'X-AuthToken': this.getAuthToken()
                },
                body: imgdata
            })
            let jsonResponse = await response.json();
            this.blockUiLoading(false);
            return jsonResponse;
        } catch (error) {
            this.blockUiLoading(false);
            this.clearAuthToken();
            return null;
        }

    }

    static async postUploadFileClientProfile(url: string, file: any) {
        this.blockUiLoading(true);

        var imgdata = new FormData();
        imgdata.append("file", file);

        try {
            let response = await fetch(apiUrlPrefix + url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'X-AuthToken': this.getAuthToken()
                },
                body: imgdata
            });

            let jsonResponse = await response.json();
            this.blockUiLoading(false);
            return jsonResponse;
        } catch (error) {
            this.blockUiLoading(false);
            this.clearAuthToken();
            return null;
        }
    }

    static async putData(url: string, body: FormData) {
        this.blockUiLoading(true);
        try {
            let response = await fetch(apiUrlPrefix + url, {
                method: "PUT",
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-AuthToken': this.getAuthToken()
                }
            })
            let jsonResponse = await response.json();
            this.blockUiLoading(false);
            return jsonResponse;
        } catch (error) {
            this.blockUiLoading(false);
            this.clearAuthToken();
            return null;
        }
    }

    static async deleteData(url: string) {
        this.blockUiLoading(true);
        try {
            let response = await fetch(apiUrlPrefix + url, {
                method: "DELETE",
                /// body: JSON.stringify(url),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-AuthToken': this.getAuthToken()
                }
            })
            let jsonResponse = await response.json();
            this.blockUiLoading(false);
            return jsonResponse;
        } catch (error) {
            this.blockUiLoading(false);
            this.clearAuthToken();
            return null;
        }
    }

    static weekNumber(dt: any) {
        const tdt: any = new Date(dt);
        const dayn = (dt.getDay() + 6) % 7;
        tdt.setDate(tdt.getDate() - dayn + 3);
        const firstThursday = tdt.getTime();
        tdt.setMonth(0, 1);
        if (tdt.getDay() !== 4) {
            tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
        }
        return 1 + Math.ceil((firstThursday - tdt) / 604800000);
    }

    static objectSortingAsc = (objSort:any ,fieldSorting :any) =>{
        return [...objSort].sort((a, b) => b.fieldSorting - a.fieldSorting);
    }

    static lookUpTypeList(lookUpType: string, itemName: any, itemReference: string) {            
        

        if (lookUpType && itemName) {
            var temp = global.tagColor?.filter((lookUpListItem: any) => lookUpListItem.lookUpType == lookUpType && lookUpListItem.itemName == itemName);
        } else if (lookUpType) {
            var temp = global.tagColor?.filter((lookUpListItem: any) => lookUpListItem.lookUpType == lookUpType);
            return temp;
        } else {
            var temp = global.tagColor?.filter((lookUpListItem: any) => lookUpListItem.lookUpType == "General");
        }

        if(temp && temp.length == 0){
            var temp = global.tagColor?.filter((lookUpListItem: any) => lookUpListItem.lookUpType == "General");
        }

        if (itemReference == 'fontColor' && temp && temp.length > 0) {
            return (temp[0].fontColor)?.toString();

        } else if (itemReference == 'bgColor' && temp && temp.length > 0) {
            return (temp[0].bgColor)?.toString();
        } else {
            return '';
        }

    }

    static lookUpTypeListByKey(lookUpType: string, key: string, itemReference: string) {

        if (lookUpType && itemReference) {
            var temp = global?.lookUpTypeList?.filter((lookUpListItem: any) => lookUpListItem.lookUpType == lookUpType && lookUpListItem.key == key);
        } else {
            var temp = global?.lookUpTypeList;
        }
        //console.log(temp)
        if (itemReference == 'value' && temp && temp.length > 0) {
            return (temp[0].value)?.toString();

        } else {
            return '';
        }

    }

    static iconUrlAssignment(subEntityName: string) {
        const landingPage = [
            {
                subEntityName: 'Home',
                subEntityURL: '../../'
            }, {
                subEntityName: 'file2',
                subEntityURL: '../../apps/index.html'
            }, {
                subEntityName: 'pie-chart',
                subEntityURL: '../../apps/contracts/#/summary/'
            }, {
                subEntityName: 'Management',
                subEntityURL: '../../apps/dataMGT/#/itunesMonthly'
            }, {
                subEntityName: 'Matching',
                subEntityURL: '../../apps/dataMGT/#/matching'
            }, {
                subEntityName: 'Management',
                subEntityURL: '../../apps/dataMGT/#/matching'
            }, {
                subEntityName: 'Analytics',
                subEntityURL: '../../apps/affiliates/'
            }, {
                subEntityName: 'Custom Splice',
                subEntityURL: '../../apps/affiliate/'
            }, {
                subEntityName: 'TrackSplit',
                subEntityURL: '../../apps/metadata/#/trackSplit'
            }, {
                subEntityName: 'Portal_TrackSplit',
                subEntityURL: '../../apps/metadata/#/trackSplit'
            }, {
                subEntityName: 'Transaction',
                subEntityURL: '../../apps/dataMGT/#/salesAnalysis'
            }, {
                subEntityName: 'contacts',
                subEntityURL: '../../apps/userManagement/#/register'
            }, {
                subEntityName: 'Licensing',
                subEntityURL: '../../apps/licensing/'
            }, {
                subEntityName: 'Payments',
                subEntityURL: '../../apps/transaction/#/suggestedPayment'
            }, {
                subEntityName: 'stats',
                subEntityURL: '../../apps/transaction/#/quarterRun'
            }, {
                subEntityName: 'calendar5',
                subEntityURL: '../../apps/systemSettings/#/periodMGT'
            }, {
                subEntityName: 'settings1',
                subEntityURL: '../../apps/systemSettings/#/controlPanel'
            }, {
                subEntityName: 'user2',
                subEntityURL: '../../apps/systemSettings/#/clientProfile'
            }, {
                subEntityName: 'line-chart',
                subEntityURL: '../../apps/systemSettings/#/auditTrail'
            }, {
                subEntityName: 'Contracts',
                subEntityURL: '../../apps/contracts/#/'
            }, {
                subEntityName: 'Contacts',
                subEntityURL: '../../apps/contacts/#/'
            }, {
                subEntityName: 'Grid_Approval',
                subEntityURL: '../../apps/statements/'
            }, {
                subEntityName: 'Reports',
                subEntityURL: '../../apps/reportings/'
            }, {
                subEntityName: 'Project',
                subEntityURL: '../../apps/licensingPortal/#/projectGrid'
            }, {
                subEntityName: 'Pitch',
                subEntityURL: '../../apps/licensingPortal/#/pitchGrid'
            }, {
                subEntityName: 'tasks',
                subEntityURL: '../../apps/licensingPortal/#/bulkGrid'
            }, {
                subEntityName: 'Uploader',
                subEntityURL: '../../apps/dataLoader/#/metaData'
            }, {
                subEntityName: 'stack2',
                subEntityURL: '../../apps/systemListings/#/'
            }, {
                subEntityName: 'Publishing_Project',
                subEntityURL: '../../apps/contracts/'
            }, {
                subEntityName: 'Synch_Project',
                subEntityURL: '../../apps/licensingPortal/#/projectGrid?Synch'
            }, {
                subEntityName: 'Mechanical_Project',
                subEntityURL: '../../apps/contracts/'
            }, {
                subEntityName: 'Mechanicals',
                subEntityURL: '../../apps/transaction/#/quarterRun'
            }, {
                subEntityName: 'Transaction',
                subEntityURL: '../../apps/dataMGT/#/salesAnalysis/digital'
            }, {
                subEntityName: 'Publishing_Transaction',
                subEntityURL: '../../apps/dataMGT/#/salesAnalysis/publishingSong'
            }, {
                subEntityName: 'Other_Transaction',
                subEntityURL: '../../apps/dataMGT/#/salesAnalysis/costs'
            }, {
                subEntityName: 'Track',
                subEntityURL: '../../apps/metadata/#/tracks'
            }, {
                subEntityName: 'Release',
                subEntityURL: '../../apps/metadata/#/'
            }, {
                subEntityName: 'Song',
                subEntityURL: '../../apps/metadata/#/songs'
            }, {
                subEntityName: 'Assets',
                subEntityURL: '../../apps/metadata/#/assets'
            }, {
                subEntityName: 'SongRegistration',
                subEntityURL: '../../apps/metadata/#/songRegistration?type=songBasket'
            }, {
                subEntityName: 'Projects',
                subEntityURL: '../../apps/contracts/#/projectContracts'
            }, {
                subEntityName: 'Recorded_Music',
                subEntityURL: '../../apps/contracts/'
            }, {
                subEntityName: 'Directories',
                subEntityURL: '../../apps/directories/#/creators'
            }

        ];

        for (var i = 0; i < landingPage.length; i++) {
            if (landingPage[i].subEntityName == subEntityName) {
                return landingPage[i].subEntityURL;
            }
        }
    }

    static portalSelection(portalSelection: any) {

        console.log(portalSelection);

        if (portalSelection.subEntity.subEntity == "Scraping Tool") {
            return (
                <ScrapingTool subEntity={portalSelection} />
            )
        }
        else if (portalSelection.subEntity.subEntity == "Directories" || portalSelection.subEntity == "Directories") {

            if(window.location.pathname == '/directories/'){
                return (
                    <DirectoriesIndex subEntity={portalSelection} pathname={window.location.pathname}/>
                )
            }else {                
                return (
                    <DirectoriesIndex subEntity={portalSelection} />
                )
            }
        }

        else if (portalSelection.subEntity.subEntity == "Asset Compare") {
            return (
                <AssetCompareIndex subEntity={portalSelection} />
            )
        }

        else if (portalSelection.subEntity.subEntity == "TrackSplit") {
            return (
                <ScrapingTool subEntity={portalSelection} />
            )
        }

        else if (portalSelection.subEntity.subEntity == "Uploader") {
            return (
                <DataLoader subEntity={portalSelection} />
            )
        }

        else if (portalSelection.subEntity.subEntity == "Mechanicals") {
            return (
                <QuarterRun subEntity={portalSelection} />
            )
        }

        else if (portalSelection.subEntity.subEntity == "Reports") {
            return (
                <Reports subEntity={portalSelection} />
            )
        }

        return false;
    }

    static longCharacter(character: any, length: any) {
        var newCharacter = '';
        //console.log(character.toString())
        if ((character) && (character.toString().length > length)) {
            newCharacter = character.toString().slice(0, length) + '...';
        }

        if ((character) && (character.toString().length <= length)) {
            newCharacter = character.toString();
        }

        return newCharacter;
    }
    static stringToArray(stringToArray: string, delimeter: string) {
        if (stringToArray) {
            return stringToArray.split(delimeter);
        } else {
            return [];
        }
    }

    static numberFormat(number: any, decimalPlace: number) {
        if (!number) {
            number = 0;
        }

        number = (Math.round(Number(number) * 100) / 100)?.toFixed(decimalPlace);

        if (number == 0.00) {
            return number;
        } else {
            number = (Math.round(Number(Math.abs(number)) * 100) / 100)?.toFixed(decimalPlace);
            return number;
        }

    }

    static parseStyleString(styleString: string) {
        const styleObject: { [key: string]: string } = {};
        const styleArray = styleString?.split(';')?.filter(Boolean);  
      
        styleArray?.forEach((style) => {
          const [key, value] = style?.split(':')?.map((s) => s.trim());
          if (key && value) {
            styleObject[key] = value;
          }
        });
      
        return styleObject;
    };      

    static setAvatar(avatar: string) {
        //console.log(avatar);
        var authToken = JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string);
        //console.log(authToken);
        authToken.avatar = avatar;
        window.sessionStorage.setItem('loggedUserRight', JSON.stringify(authToken));
    }

    static setDisplayName(firstName: string, lastName: string) {
        var authTokenn = JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string);
        authTokenn.firstName = firstName;
        authTokenn.lastName = lastName;
        window.sessionStorage.setItem('loggedUserRight', JSON.stringify(authTokenn));
    }

    static async downloadFile(fileURL: string, filename: string) {

        fetch('https://cors-anywhere.herokuapp.com/' + fileURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `FileName.pdf`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                //link.parentNode.removeChild(link);
            });
    }

    static relativeDataRemapping(data: any, dataHeaders: any) {
        var sep_array = [];
        var mix_array = [];
        var subCounter = 0;
        var setTo_Data = {};

        var HeaderName: any[] = [];
        var row_data: { [s: string]: any; } = [];
        var in_data: any[] = [];

        Object.entries(dataHeaders).forEach(([key, value]: any) => { HeaderName.push(value.replace(/ /g, '')) });
        Object.entries(data).forEach(([key, value]) => { row_data.push(value) });


        for (var i = 0; i < row_data.length; i++) {

            Object.entries(row_data[i]).forEach(([key, value]) => { in_data.push(value) });
        }

        for (var count = 0; count < in_data.length; count++) {

            setTo_Data = Object.assign({ [HeaderName[subCounter]]: in_data[count] });
            subCounter++;

            if (subCounter == HeaderName.length) {
                subCounter = 0;
            }
            sep_array.push(setTo_Data);
        }

        for (var D_length = 0; D_length < sep_array.length; D_length++) {
            let array_Temp = {};

            for (var H_length = 0; H_length < HeaderName.length; H_length++) {
                Object.assign(array_Temp, sep_array[D_length + H_length]);
            }

            mix_array.push(array_Temp);
            D_length += HeaderName.length - 1;
        }

        return mix_array;
    };

    static buildQueryString(params: any, preserveParams: boolean, allowEmpty: boolean) {
        const queryParams: string[] = [];

        for (const k in params) {
            if (params.hasOwnProperty(k) &&  !CommonUtilities.isNullOrEmpty(params[k])) {
                queryParams.push(
                    preserveParams
                        ? `${k}=${params[k]}`
                        : `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`
                );
            }

            else if (allowEmpty) {
                queryParams.push(`${encodeURIComponent(k)}=`);
            }

        }
        return queryParams.join('&');

    };

    static exportWithToken = async function (this: any, verb: any, url: any, data: any, target: any, filename: any) {

        let response = fetch(apiUrlPrefix + url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-AuthToken': this.getAuthToken()
            }
        })

        const blob = await (await response).blob();

        const objectUrl = URL.createObjectURL(blob);
        //console.log(objectUrl)

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = objectUrl;
        a.download = filename + '.zip';
        a.click();

        URL.revokeObjectURL(objectUrl);
    };

    static download = async (urlLink : any, dataSend :any) => {
        let url = 'YOUR_API_ENDPOINT'; 
        const filename = 'example.zip'; 
        let data = {}; 

        url = urlLink; 
        data = dataSend;
        // Create a fetch request to download the ZIP file
        fetch(apiUrlPrefix + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/zip',
                'X-AuthToken': this.getAuthToken()
            },
            body: JSON.stringify(data) 
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob(); 
            })
            .then(blob => {
                // Create a temporary URL for the blob
                const objectUrl = URL.createObjectURL(blob);

                // Create a temporary anchor element to trigger download
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();

                // Clean up
                URL.revokeObjectURL(objectUrl);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    };

    static postExport = async (url : any , payload :any , fileName :any) => {
        //console.log( url )
        //console.log( payload )

        try {
            const response = await fetch(apiUrlPrefix + url, {
              method: 'POST', 
              headers: {'Content-Type': 'application/json', 'X-AuthToken': this.getAuthToken()},
              body: JSON.stringify(payload),
            });
      
            if (response.ok) {
              const blob = await response.blob(); 
              //console.log( blob )

              const url = window.URL.createObjectURL(new Blob([blob]));
              //console.log( url )

              var file_Name = fileName?fileName +'.zip':'Export' +'.zip'
              //console.log( file_Name )

              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', file_Name);
              document.body.appendChild(link);
              link.click();
        

            } else {
                console.log('Response body:', await response.text());
                alert('Failed to download file NOT OK');
            }

          } catch (error) {
            console.error('Error downloading file:', error);
            alert('Error downloading file');
          }
    };

    static dateFilter(date: any) {

        if (`${date}`.length == 8) {
            // console.log(format(new Date(`${date}`.substring(4, 6)) , "MMM" ))
            return `${date}`.substring(0, 4) + format(new Date(`${date}`.substring(4, 6)), "MM") + `${date}`.substring(6, 8)

        } else if (`${date}`.length == 19) {
            //  console.log(date);
            return `${date}`.substring(0, 4) + format(new Date(`${date}`.substring(5, 7)), "MM") + `${date}`.substring(8, 10)
        }
    }

    static dateNameFilter(date: any) {

        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        console.log(monthNames[new Date(date).getMonth()])
        console.log(format(new Date(date), 'm'))
        console.log(format(new Date(date), 'yyyy'))
        console.log(format(new Date(date), 'd'))

        if(date)
            return monthNames[new Date(date).getMonth()].substring(0, 3) +" " + format(new Date(date), 'd') +', ' +format(new Date(date), 'yyyy')
    };

    static exportVsWindow(data: any) {
        window.open(apiUrlPrefix + data);
    };

    static generateChartData(percentageData: any, labels: any, backgroundColor: any, borderColor: any, chartLabel: string, legend: boolean) {
        const options = {
            layout: {
                padding: {
                    top: 0
                }
            },
            plugins: {
                elements: {
                    bar: {
                        borderWidth: 0,
                    },
                }
            }
        };

        const data = {
            labels: labels,
            datasets: [
                {
                    label: chartLabel,
                    data: percentageData,
                    backgroundColor: backgroundColor,
                    borderColor: borderColor[3],
                    borderWidth: 1
                },
            ]
        };

        var chartPassedData = {
            data: data,
            options: options
        }

        //console.log(chartPassedData);

        return chartPassedData;
    }

    static getdspImages(data: any) { 
 
        var imagePath = ""    
        var obj = global.ClientDSP?.filter((item:any)=> { return item.name.toLocaleLowerCase()?.replace(/ /g, '')?.includes(data?.toLowerCase()?.replace(/ /g, '')) &&  item.logo})[0];

        if(obj && obj.logo){
            imagePath = obj.logo;
        } 
        //console.log(imagePath)
        return imagePath ;
    }

    static myArray = [
        "#EF534F",        "#C62928",        "#FFFFFF",
        "#980A0B",        "#BA69C8",        "#7B1FA2",        "#4A148C",
        "#FFD54F",        "#F57C02",        "#E65100",        "#81C784",
        "#2F7D31",        "#1A5E20",        "#7FDEEA",        "#00838F",
        "#004D41",        "#90CAF9",        "#029BE5",        "#01579B",
        "#DBDBDB",        "#000000"
    ];

    static chartColors = [
        '#FF5733','#33FF57','#3357FF','#FF33A6','#33A6FF','#A633FF','#FFD700','#ADFF2F','#8A2BE2','#E6E6FA',
        '#7FFF00','#D2691E','#FF4500','#FF6347','#40E0D0','#6495ED','#FF1493','#FF8C00','#98FB98','#F0E68C',
        '#C71585','#6A5ACD','#FF00FF','#7B68EE','#4B0082','#5F9EA0','#D8BFD8','#A52A2A','#D3D3D3','#A9A9A9',
        '#808080','#800000','#FFFF00','#008000','#00FFFF','#0000FF','#FF00FF','#FFC0CB','#FFD700','#C0C0C0',
        '#F5DEB3','#FFE4B5','#8B4513','#2F4F4F','#6B8E23','#FF6347','#FFFACD','#BDB76B','#000080','#008B8B',
        '#B8860B','#A9A9A9','#006400','#8B0000','#CD5C5C','#DC143C','#F4A460','#9ACD32','#20B2AA','#BC8F8F',
        '#8B008B','#556B2F','#FF4500','#7CFC00','#D2B48C','#98FB98','#FFFFE0','#6B8E23','#800080','#FF69B4',
        '#C71585','#E0FFFF','#00BFFF','#4169E1','#1E90FF','#B0E0E6','#ADD8E6','#87CEEB','#87CEFA','#00FFFF',
        '#00CED1','#40E0D0','#48D1CC','#98C9E2','#A2B5CD','#5F9EA0','#F08080','#F0E68C','#EEE8AA','#BDB76B',
        '#F4A460','#FFDEAD','#D2691E','#F4A300','#FFFFF0','#B0E0E6','#8B4513','#2E8B57','#A52A2A','#D3D3D3'
    ];

    static dspColor = [
        {dspName : "Youtube"                        , colour : "#fe0808"},
        {dspName : "Spotify"                        , colour : "#25d865"},
        {dspName : "Apple"                          , colour : "#f21147"},
        {dspName : "Apple Music"                    , colour : "#5DC4F9"},
        {dspName : "Soundtrack Your Brand colour"   , colour : "#f23a46"},
        {dspName : "Sound track Your Brand colour"  , colour : "#f23a46"},
        {dspName : "Tidal"                          , colour : "#152dff"},
        {dspName : "7Digital"                       , colour : "#ff6f00"},
        {dspName : "Amazon"                         , colour : "#FF9900"},
        {dspName : "CapCut"                         , colour : "#4ECDC4"},
        {dspName : "iTunes"                         , colour : "#d36fe9"},
        {dspName : "Soundcloud"                     , colour : "#FF5500"},
        {dspName : "Deezer"                         , colour : "#a53eff"},
        {dspName : "Snap"                           , colour : "#fffc00"},
        {dspName : "qobuz"                          , colour : "#16878f"},
        {dspName : "redeye distribution"            , colour : "#f04734"},
        {dspName : "ADRev"                          , colour : "#63255e"},
        {dspName : "Pias"                           , colour : "#ff776c"} 
    ]

    static currencySymbols = [
        { "currency": "EUR", "symbols": "€"     },
        { "currency": "AED", "symbols": "د.إ"   },
        { "currency": "AFN", "symbols": "؋"     },
        { "currency": "XCD", "symbols": "$"     },
        { "currency": "ALL", "symbols": "L"     },
        { "currency": "AMD", "symbols": "֏"     },
        { "currency": "ANG", "symbols": "ƒ"     },
        { "currency": "AOA", "symbols": "Kz"    },
        { "currency": "ARS", "symbols": "$"     },
        { "currency": "USD", "symbols": "$"     },
        { "currency": "AUD", "symbols": "A$"    },
        { "currency": "AWG", "symbols": "ƒ"     },
        { "currency": "AZN", "symbols": "₼"     },
        { "currency": "BAM", "symbols": "KM"    },
        { "currency": "BBD", "symbols": "$"     },
        { "currency": "BDT", "symbols": "৳"     },
        { "currency": "BGN", "symbols": "лв"    },
        { "currency": "BHD", "symbols": "د.ب"   },
        { "currency": "BIF", "symbols": "Fr"    },
        { "currency": "BMD", "symbols": "$"     },
        { "currency": "BND", "symbols": "$"     },
        { "currency": "BOB", "symbols": "Bs"    },
        { "currency": "BRL", "symbols": "R$"    },
        { "currency": "BSD", "symbols": "$"     },
        { "currency": "BTN", "symbols": "Nu"    },
        { "currency": "NOK", "symbols": "kr"    },
        { "currency": "BWP", "symbols": "P"     },
        { "currency": "BYR", "symbols": "Br"    },
        { "currency": "BZD", "symbols": "$"     },
        { "currency": "CAD", "symbols": "$"     },
        { "currency": "CDF", "symbols": "Fr"    },
        { "currency": "CHF", "symbols": "CHF"   },
        { "currency": "CNY", "symbols": "¥"     },
        { "currency": "COP", "symbols": "$"     },
        { "currency": "HRK", "symbols": "kn"    },
        { "currency": "CSD", "symbols": "din"   },
        { "currency": "CRC", "symbols": "₡"     },
        { "currency": "CUP", "symbols": "₱"     },
        { "currency": "CVE", "symbols": "$"     },
        { "currency": "DKK", "symbols": "kr"    },
        { "currency": "DOP", "symbols": "$"     },
        { "currency": "DZD", "symbols": "دج"    },
        { "currency": "EGP", "symbols": "ج.م"   },
        { "currency": "MAD", "symbols": "د.م."  },
        { "currency": "ERN", "symbols": "Nfk"   },
        { "currency": "ETB", "symbols": "ታብ"   },
        { "currency": "FJD", "symbols": "$"     },
        { "currency": "FKP", "symbols": "£"     },
        { "currency": "GEL", "symbols": "₾"     },
        { "currency": "GHC", "symbols": "₵"     },
        { "currency": "GIP", "symbols": "£"     },
        { "currency": "GMD", "symbols": "D"     },
        { "currency": "GNF", "symbols": "Fr"    },
        { "currency": "GBP", "symbols": "£"     },
        { "currency": "GTQ", "symbols": "Q"     },
        { "currency": "GYD", "symbols": "$"     },
        { "currency": "HKD", "symbols": "HK$"   }
    ] 

    static getCSymbols(value:any){
        const currencyList = CommonUtilities?.currencySymbols?.find((dsp:any) => dsp.currency?.toLowerCase().includes(value?.toLowerCase()));
        return currencyList? currencyList.symbols : value;
    }

    static headerName (value:any){
        return value?.replace(/\b\w/g, function(char :any) {
            return char?.toUpperCase();
        });
    }

    static getDSPColor (value:any){
        const dsp = CommonUtilities?.dspColor?.find((dsp:any) => dsp.dspName?.toLowerCase().includes(value?.toLowerCase()));     
        return dsp ? dsp.colour : "#212121";

    }

    static isNullOrEmpty(value: string) {
        return value === undefined || value === null || value === '';
    } 
}