import React, { useEffect, useState } from 'react';
import Navbar from './navbar';
import korrectLogo from "../../assets/images/korrectLogo.png";
import dashboardService from '../Dashboard/dashboardServices';
import globalSearchService from '../commonSearch/globalSearchServices';
import PeriodService from '../PeriodSelection/PeriodService';
import directoryServices from '../directories/directoryServices';

export default function Header(param: any) {

    const [flag, setFlag] = useState(true);

    useEffect(() => {
        dashboardService.tagColor().then((res:any) => {
            global.tagColor = res;
        });

        globalSearchService.lookUpTypeList().then((res:any) => {
            global.lookUpTypeList = res;
        });

        
        directoryServices.getClientDetails().then((res:any)=>{ 
            global.clientDetail = res[0]
        })

        PeriodService.PeriodCount() .then((res) => {
            global.periodCount = res;

            for (var $i = 0; $i < res.length && flag == true; $i++) {
                if (res[$i].periodCurrent == 1) {
                    global.currectPeriod = res[$i].periodDesc;
                    global.periodSelected = res[$i];
                    setFlag(false); 
                }
            }
        });
        
        console.log(param)
    }, [param.Title]);


    return (
        <div className='ws-12'>
            <div className='ws-12' >
                <a className='mtl mll pull-left' href='../../'><img src={korrectLogo} height='49px' /></a>
                <img className='mtl pull-left' src={global.portalLogo ? global.portalLogo : '/assets/images/logo.png'} height='49px' />

                <Navbar />

            </div>

            {(param.Title) && <div className='box'>
                <div className='box-header-main'>
                    <h2 className='ws-8'>{param.Title ? param.Title : ''}</h2>

                    
                {(param.Title == 'Contacts' || param.Title == 'Contracts' || param.Title == 'Projects') &&   <div className='pull-right'>

                    {(param.Title != 'Projects') && 
                        <a href="../../apps/projects/" className="btn-info btn-s" >Projects <i className="icon-Grid_ProjectContract"/> 
                    </a>}                  

                    {(param.Title != 'Contracts') && 
                        <a href="../../apps/contracts/" className="btn-info btn-s" >Contracts <i className="icon-Grid_Contracts"/>
                    </a>}

                    {(param.Title != 'Contacts') && 
                        <a href="../../apps/contacts/" className="btn-info btn-s"> Contacts <i className="icon-Grid_Contacts"/>
                    </a>}
                </div>                    
                }
                </div>

            </div>}
        </div>
    )


}