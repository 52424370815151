import Drawer from 'react-modern-drawer';
import globalSearchService from './globalSearchServices';
import React, { Suspense, useState, useEffect } from "react";

export default function CommonSearchSlider(param: any) {
 
    let [lookUpTypeList, setlookUpTypeList] = useState<any>([]);
    let [SliderField, setSliderField] = useState<any>([]);
    let [conAdditional, setcontractAdditional] = useState<any>([]);

    var pickerColors = [
        '#EF534F', '#C62928', '#980A0B',
        '#BA69C8', '#7B1FA2', '#4A148C',
        '#F57C02', '#E65100',
        '#81C784', '#2F7D31', '#1A5E20',
        '#7FDEEA', '#00838F', '#004D41',
        '#90CAF9', '#029BE5', '#01579B',
        '#DBDBDB', '#000000'
    ];

    useEffect(() => {
        lookUpTypeList = global.lookUpTypeList
        setlookUpTypeList(lookUpTypeList);

        if (param.conAdditional) {
            conAdditional = param.conAdditional
            setcontractAdditional(conAdditional)
        }

        if (param.open) {
            globalSearchService.globalGrid(param.type)  .then((res) => {  
                SliderField=res;
                setSliderField(SliderField); 
                console.log(SliderField)
                param.onchangeTag({ SliderField, conAdditional }) 
            })
        }

    }, []);

    const closePage = () => { 
        param.onchange(false);
    }

    const formHandler = (event: any) => {

        console.log(event)
        const field_name = event.target.name; let field_value = event.target.value;

        var varLen = field_value.length;
        if (event.target.type == "checkbox") {
            field_value = (event.target.value == 'on' ? 1 : 0);
            varLen = 1;
        }
        
        console.log("FieldType: " + event.target.type + " FieldName: "+field_name+ " FieldValue: "+field_value+ " FieldValueType: "+typeof field_value )
        console.log(varLen) 

        if(varLen > 0){
            setcontractAdditional({ ...conAdditional, ...{ [field_name]: [field_value] } });
        }       

        console.log(conAdditional)

    }

    useEffect(() => {
        param.onchangeTag({ SliderField, conAdditional })
    }, [conAdditional]);

    const removeTag = (fieldName: any, value: any) => {

        Object.keys(conAdditional).map((key:any, index) => {

            for (var i = 0; i < conAdditional[key].length; i++) {

                if (conAdditional[key][i] == value) {

                    setcontractAdditional((prevUser: any) => ({
                        ...prevUser,
                        [fieldName]: conAdditional[fieldName].filter((hobby: any) => hobby !== value)
                    }));
                    param.onchangeTag({ SliderField, conAdditional })
                }
            }
        })
    }

    const onchangeSearch = () => {

        param.onchangeSearch(conAdditional)
        closePage();
    }

    return (<>

        <Drawer open={param.open}
            onClose={closePage}
            direction='right'
            className='react-drawer-left allowHscroll'>

            <div className="box ws-6 pull-right full-height-vh">
                <div className="box-header-main">
                    <h2 className="pull-left"> Search {param.title}</h2>
                </div>

                <div className="box-content">

                    {(() => {
                        return ( SliderField.map((value:any) => {

                            if (value.fieldType == 'Text' && value.filterName != "ProjectTag" && value.isActive) {
                                return (
                                    <div className='form-format ws-6'>
                                        <label>{value.displayName}</label>
                                        <input name={value.filterName} type="text" placeholder={value.displayName} onChange={(e) => formHandler(e)} className="ws-11"></input>
                                    </div>
                                )
                            }

                            if (value.fieldType == 'DropDown' && value.filterName != "ProjectTag" && value.isActive) {
                                return (
                                    <div className='form-format ws-6'>
                                        <label>{value.displayName}</label>

                                        <div className="label-entries ws-11">
                                            <select name={value.filterName} onChange={(e) => formHandler(e)}>
                                                <option value="" >Choose {value.displayName}</option>
                                                {/**/} {lookUpTypeList.map((lookUpListItem: any) => {

                                                    if (lookUpListItem.lookUpType == value.filterName) {
                                                        return (
                                                            <option >{lookUpListItem.value}</option>
                                                        )
                                                    }
                                                })}
                                            </select>
                                        </div>

                                    </div>
                                )
                            }

                            if (value.fieldType == 'Slider' && value.isActive) {
                                return (
                                    <div className='form-format ws-6'>
                                        <label>{value.displayName}</label>

                                        <label className="switch" >
                                            <input type="checkbox" name={value.filterName} checked={(conAdditional[value.filterName] == 1 || !(conAdditional[value.filterName])) } onChange={(e) => formHandler(e)} />
                                            {(conAdditional[value.filterName] == 1 || !(conAdditional[value.filterName])) && <span className="slider round text-left white ps">Yes</span>}
                                            {(conAdditional[value.filterName] == 0) && <span className="slider round text-right white ps sliderOff">No</span>}

                                        </label>

                                    </div>
                                )
                            }
                        }))
                    })()}

                </div>

                <div className="box-footer">
                    {SliderField.map((value: any) => {
                        return (Object.keys(conAdditional).map((key: any, index: any) => {
                            if (key == value.filterName && conAdditional[key].length > 0) {

                                return (
                                    <div className="tagging-container" style={{ color: pickerColors[index] }} >
                                        <div className="tagging">
                                            <div className="pull-left pts mrs" style={{ color: pickerColors[index] }}>{value.displayName}:</div>

                                            {(() => {

                                                return ((conAdditional[key]).map((value: any) => {
                                                    return (<>
                                                        <span className="mrs" style={{ background: pickerColors[index], color : "#ffffff" }} > {value}
                                                            <i className="icon-cancel2 pls pull-right" onClick={() => removeTag(key, value)}></i>
                                                        </span>
                                                    </>)
                                                }))
                                            })()}
                                        </div>

                                    </div>
                                )

                            }
                        }))
                    }) }

                    {(() => {
                        if (conAdditional['searchFor']) {
                            return (
                                Object.keys(conAdditional).map((key: any, index: any) => {

                                    if (key == 'searchFor' && conAdditional[key].length > 0) {

                                        return (
                                            <div className="tagging-container" style={{ color: pickerColors[index] }} >
                                                <div className="tagging">
                                                    <div className="pull-left pts mrs" style={{ color: pickerColors[index] }}>{'SearchFor'}:</div>

                                                    {(() => {

                                                        return ((conAdditional[key]).map((value: any) => {
                                                            return (<>
                                                                <span className="mrs" style={{ background: pickerColors[index], color : "#ffffff" }} > {value}
                                                                    <i className="icon-cancel2 pls pull-right" onClick={() => removeTag(key, value)}></i>
                                                                </span>
                                                            </>)
                                                        }))
                                                    })()}
                                                </div>
                                            </div>
                                        )
                                    }

                                })
                            )
                        }

                    })()}

                    <button className="btn-danger pull-right" onClick={closePage}><i className="icon-close" ></i> Close</button>
                    <button className="btn-success pull-right" onClick={onchangeSearch}><i className="icon-search" ></i> Search</button>
                </div>
            </div>
        </Drawer>

    </>)
}