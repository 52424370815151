import CommonSearch from "../../components/commonSearch/CommonSearch";
import globalSearchService from "../../components/commonSearch/globalSearchServices";
import React, { Suspense, useState, useEffect } from "react";
import directoryServices from "./directoryServices";
import AddAccountSlider from "./Add Slider/AddAccountSlider";
import Pagination from "../Header/Pagination";
import { CommonUtilities } from "../../commonUtils";
import Summary from "./summary";
import DirectoryAnalytic from "./Analytic/DirectoryAnalytic";

export default function Accounts(param: any) {
       
    let [totalRow, settotalRow] = useState(0); 
    let [exclude ,setExclude] = useState<any>([]);
    let [viewAllList, setviewAllList] = useState("");
    let [detailView, setdetailView] = useState(false);
    let [openInclude ,setOpenInclude] = useState(false); 
    let [detailMainTab, setdetailMainTab] = useState(1); 
    let [categoryList, setCategoryList] = useState<any[]>([]);  
    let [selectedDetail, setselectedDetail] = useState<any>([]);   
    let [creatorAssettotalRow, setcreatorAssettotalRow] = useState(0);    
    let [directoryViewAll, setdirectoryViewAll] = useState<any[]>([]);
    let [dialogueAddOpenView, setdialogueAddOpenView] = useState(false);  
    let [AnalysisinvoicesList, setAnalysisinvoicesList] = useState<any>([]); 
    let [directoryFullListing, setdirectoryFullListing] = useState<any[]>([]);    
    let [AnalysisinvoicesListTemp, setAnalysisinvoicesListTemp] = useState<any>([]);
    let [associatedclassification, setassociatedclassification] = useState<any>([]);
    let [associatedSubclassification, setassociatedSubclassification] = useState<any>([]); 
    let [listYearlyDSPs, setYearlyDSPs] = useState<any>([]); 
    let [itemCurrentYear, setCurrentYear] = useState<any>(2025); 

    var loop = 0;

    var payLoad = {  pageNumber: 1, pageSize: 10 }
    var pagination = {'pageSize': 10,'pageNumber': 1,"display": "Category", "type": "Accounts" ,exclude : "" , searchFor :""}   
    var SubclassFicationPayload = {area: "Accounts", referenceCode: "",text: "", pageNumber: 1,pagesize: 10}

    useEffect(() => { 
        if (loop == 0) {
            LandingListing();
            console.log(loop)
            loop = loop + 1;
        }
        setdetailMainTab(1);
        setdirectoryViewAll([]);       
        setviewAllList("");

        globalSearchService.lookUpTypeList()
        .then((res) => {
            global.lookUpTypeList = res;
        });

    }, []) 

    const LandingListing = () => {
        console.log("LandingListing")
        setdirectoryViewAll([]);        
        setviewAllList("");
        directoryServices.AccountListing({ ...pagination }).then((res) => {
            res.map((values: any) => {
                if (values.categoryName != ' ' && (categoryList.filter((e:any)=> e == values.categoryName))?.length ==0 ){ 
                    categoryList.push(values.categoryName)
                    setCategoryList(categoryList)
                    setExclude(categoryList)
                }

                if(values.categoryName == ' ' && (categoryList.filter((e:any)=> e == "Uncategorised"))?.length ==0 ){                        
                    categoryList.push("Uncategorised")
                    setCategoryList(categoryList)
                    setExclude(categoryList)
                }
            });
            directoryFullListing = res;
            setdirectoryFullListing(directoryFullListing);
            settotalRow(res[0] ? res[0].totalRows : 0);
        }) 
    }

    let [searchFor,setsearchFor] = useState<any>([]);
    const SearchForHandle = (e: any, sts: any) => { 
        console.log(e)
        console.log(sts)
        if(sts == false || sts == true){          
            searchFor  =e;
            setsearchFor(e)   
            pagination.searchFor =  e?.searchFor; 
            pagination = {...pagination,...searchFor}     
        }

        if ((sts == true)) {

            directoryServices.AccountListing({ ...e, ...pagination }).then((res) => {
                res.map((values: any) => {

                    if (values.categoryName != ' ' && (categoryList.filter((e:any)=> e == values.categoryName))?.length ==0 ){ 
                        categoryList.push(values.categoryName)
                        setCategoryList(categoryList)
                        setExclude(categoryList)
                    }

                    if(values.categoryName == ' ' && (categoryList.filter((e:any)=> e == "Uncategorised"))?.length ==0 ){                        
                        categoryList.push("Uncategorised")
                        setCategoryList(categoryList)
                        setExclude(categoryList)
                    }
                })
                setdirectoryFullListing(res)
                settotalRow(res[0] ? res[0].totalRows : 0)

            })
        } 
    }

    const viewAll = (e: any) => { 
        setviewAllList(e)
        directoryServices.AccountListing({ ...{ "categoryName": e.categoryName }, ...pagination }).then((res) => {
            for(var $i =0 ; $i < res?.length ; $i++){
                
                if(res[$i].categoryName == e){ 
                    directoryViewAll= res[$i].categoryDetails
                    setdirectoryViewAll(directoryViewAll) 
                }
            } 
        })        
    }

    const paginationAllCategory =(e:any) =>{
        console.log(e);
        pagination.pageNumber   = e.pagination.pageNumber;
        pagination.pageSize     = e.pagination.pageSize;
        viewAllList             = e.categoryName
        setviewAllList(viewAllList) 
        
        viewAll(viewAllList);        
    }  

    const creatorAsset = (e: any) => { 
        console.log(selectedDetail)
        setselectedDetail(selectedDetail) 
        SubclassFicationPayload.referenceCode = selectedDetail.code;
        directoryServices.tagListing(SubclassFicationPayload).then((res) => {
            setassociatedSubclassification(res)
        })

        directoryServices.musicDirectoryCategoryListing({ code: selectedDetail.code, CategoryType: "Accounts" }).then((res) => {
            setassociatedclassification(res)
        })

    } 

    const onChangeClose = (e: any) => {
        if(selectedDetail){
            console.log(selectedDetail)            
            console.log(e)
            selectedDetail.logo = e.logo ;                    
            selectedDetail.code = e.code;
            selectedDetail.fullName = e.fullName;
        }
        setdialogueAddOpenView(false)
    } 

    const getInvoiceList = () => { 
        setdetailMainTab(3);

        loadYearlyDSPs();

        var Invoicepayload= { Type : "Invoices" , AccountCode :selectedDetail.code }
        directoryServices.genericAPIListing(Invoicepayload).then((value: any) => { 
            setAnalysisinvoicesList( (CommonUtilities.relativeDataRemapping(value.data, value.headers.original) ).slice((1 -1 ) * 10,(10*1 ))  )
            setAnalysisinvoicesListTemp( (CommonUtilities.relativeDataRemapping(value.data, value.headers.original) ))
            
            console.log( (CommonUtilities.relativeDataRemapping(value.data, value.headers.original) ) );
            console.log(value?.length )
            setcreatorAssettotalRow(AnalysisinvoicesListTemp?AnalysisinvoicesListTemp?.length  : 0);
        })
    }

    const fePagination = (e: any) => {

        payLoad.pageNumber = e.pageNumber;
        payLoad.pageSize = e.pageSize;

        if(detailMainTab == 3){
            setAnalysisinvoicesList(AnalysisinvoicesListTemp.slice((payLoad.pageNumber - 1) * payLoad.pageSize, payLoad.pageSize * payLoad.pageNumber));  
        }

    }

    const loadYearlyDSPs = ()=>{ 
        directoryServices.getYearlyDSPs( { type : 'DSPSummary' , AccountCode : selectedDetail.code , yearly : itemCurrentYear}).then((value: any) => {  
            setYearlyDSPs((CommonUtilities.relativeDataRemapping(value.data, value.headers.original)));
            console.log((CommonUtilities.relativeDataRemapping(value.data, value.headers.original)));

            //setCurrentYear((CommonUtilities.relativeDataRemapping(value.data, value.headers.original))[0][]);
        });
    }

    const removeTabName     = (value:any)=>{ 
        console.log(value)
        console.log(exclude.filter((e:any)=> e != value) )
        exclude =  exclude.filter((e:any)=> e != value)
        setExclude(exclude); 
        splitArray(exclude)
    }

    const addTabName        = (value:any)=>{  
        if(exclude.filter((e:any)=> e == value).length ==0){ 
            exclude.push(value)
            setExclude(exclude);
            splitArray(exclude)
        }           
    }

    const splitArray =(value :any)=>{
        var string = "";
         
        const resultArray1 = categoryList.filter(item => !exclude.includes(item));
        console.log(resultArray1.toString())

        if(resultArray1.length > 0){
            string = resultArray1.toString();
        }

        if(exclude.length == categoryList.length){
            string = "";
        }

        if(exclude.length == 0){
            string = categoryList.toString(); 
        }

        if(exclude.length == categoryList.length){            
            pagination.exclude = "";
        }

        pagination.exclude = string;  
        directoryServices.AccountListing({ ...pagination }).then((res) => { 
            setdirectoryFullListing(res)
            settotalRow(res[0] ? res[0].totalRows : 0)

        })
    }

    const ExportResultSet =()=>{
        console.log(pagination)
        var payload = {...pagination, ...{exportType : "exportType" ,export:1}}
        console.log(payload)
        directoryServices.excelGenericExport(payload)
    }

    return (
    <div className="landing ws-12">  

        {(detailView == false) && <div className="ws-12 plxl prxl ptxl">

            <div className="ws-12 ptl">
                <div className="ws-9">
                    <span className="ws-12 text-bold text-xl">Music Directories</span>
                    <span className="ws-12 ptl text-bold text-m turquoise">Accounts</span>
                </div>

                <div className="ws-3">
                    <a className="btn btn-success pull-right" onClick={()=>ExportResultSet()}>Export </a>
                    <a className="btn bg-level7 white pull-right pm btn-m forceSingleLine" onClick={() => { setdialogueAddOpenView(true);setselectedDetail([])}}>Add new Account</a>                            
                </div>
 
            </div>                     

            <div className="ws-12 ptxl">
                <CommonSearch SearchForHandle={SearchForHandle} type="AccountGrid" title="Accounts" width="ws-12 pull-left" />                       
            </div> 
                        
            <div className="ws-12 mtl mbl">  
                    
                <div className="ws-12 box"> 

                    <div className="pm ws-12"> 
                        <span className="pull-left pt text-bold text-m mr mt">Classes : </span>
                        
                        {exclude?.map((list: any) => { return (
                            <>{<div className="tagging bg-level1 mr forceSingleLine">
                                <span className="bgTransparent text-bold">{list}</span>
                                <a className="btn-info pull-left btn-xxs borderNone bgTransparent m0" onClick={()=>removeTabName(list)}>
                                    <i className="icon-cross1 text-s mt"></i>
                                </a>
                            </div>}</>
                        )} )} 
                        <a className="p0 pull-left btn-success borderNone bgTransparent m0" onClick={()=>setOpenInclude(!openInclude)}><i className="icon-plus text-xxl"></i></a>
                    </div>

                    {openInclude && <> <hr/>
                        <div className="pm ws-12"> 
                        {categoryList?.map((list: any) => { 
                            return (<>
                            {(() => { 
                                if(exclude.filter((e:any)=> e == list).length == 0 ){
                                    return ( 
                                    <div className="tagging bg-level1 mr forceSingleLine">
                                        <span className="bgTransparent text-bold">{list}</span>
                                        <a className="btn-info pull-left btn-xxs borderNone bgTransparent" onClick={()=>addTabName(list)}><i className="icon-plus2 text-s mt"></i></a>                                                 
                                    </div>
                                    )
                                }
                            })()} 
                            </>)
                        })} 
                        </div>
                    </>}

                </div>

                {(directoryFullListing?.length  > 0) && <div className="ws-12 ptl pb">
                    <div className="ws-12 pt pb">  
                        <span className="text-bold text-m">Showing <span className="info">{totalRow}</span> results</span>    
                    </div> 

                    {directoryFullListing?.map((valueObj: any) => {
                        return (<>
                        {(valueObj && valueObj?.categoryName != "Uncategorised") && <> 
                        
                            {<div className="ws-12 mt">
                                <div className="ws-6">
                                    <span className="ws-12 pl pt">
                                        <span className="text-bold pt">{valueObj?.categoryName}</span>
                                        <span className="info text-bold pl">(Showing {valueObj?.categoryDetails?.length} out of  {valueObj?.categoryDetails ? valueObj?.categoryDetails[0]?.totalSubRows : 0})</span>
                                    </span>
                                </div>

                                {(valueObj && valueObj?.categoryDetails && valueObj?.categoryDetails[0] && valueObj?.categoryDetails[0]?.totalSubRows ) && <div className="ws-6">
                                    {(valueObj?.categoryDetails[0]?.totalSubRows > valueObj?.categoryDetails?.length) && 
                                        <a className="btn-primary pull-right bg-level15 white borderNone hide" onClick={() => { viewAll(valueObj?.categoryName);}}> View All </a>
                                    }
                                </div>}

                            </div>}

                            {<div className="ws-12 mt">
                                {valueObj.categoryDetails?.map((value: any, index: any) => {

                                    if (!(directoryViewAll[0] && valueObj.categoryName == directoryViewAll[0].categoryName && viewAllList == valueObj.categoryName) && viewAllList != valueObj.categoryName) {
                                        return (
                                        <>{(index < 10) && <div className="creatorImg pm pull-left">

                                            <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); setdetailMainTab(1); selectedDetail = (value); creatorAsset("All") }}>

                                                <div className="coverPicture">
                                                    <img className="ws-12" src={"data:image/jpeg;base64,"+value.logo} />
                                                </div>

                                                <div className="bottomOverlayLabel">
                                                    <label className="control-label ws-12"> {value.fullName}</label> 
                                                </div>
                                            </div>
                                        </div>}</>
                                        )
                                    } 
                                })}

                                {(() => {                                                        
                                    if (viewAllList == valueObj.categoryName && directoryViewAll?.length > 0) {
                                        return (<>{directoryViewAll?.map((values:any) => {
                                                return (<div className="creatorImg pm pull-left">
                                                    <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); setdetailMainTab(1); selectedDetail = (values); creatorAsset("All") }}>

                                                        <div className="coverPicture">
                                                            <img className="ws-12" src={"data:image/jpeg;base64,"+values.logo} onError= { (e : any) => { e.src='../../../../assets/images/NoReleaseImage.jpg' } } />
                                                        </div>
                                                        <div className="bottomOverlayLabel"> 
                                                            <label className="control-label ws-12"> {values.fullName}</label> 
                                                        </div>
                                                    </div>
                                                </div>)
                                        })}</> )
                                    }
                                })() } 
                                
                                {(valueObj.categoryDetails[0].totalSubRows > 10) && <div className="ws-12">
                                    <div className="ptl">
                                        <Pagination onChange={(e:any)=>paginationAllCategory({pagination:e , categoryName :valueObj.categoryName})}  totalRows={valueObj.categoryDetails[0].totalSubRows} />
                                    </div>                           
                                </div>}
                            </div>}
                        </>}</>)
                    })}

                    {directoryFullListing?.map((valueObj: any) => {
                        return ( <>
                        {(valueObj?.categoryName == "Uncategorised") && <>
                            {<div className="ws-12 mt">
                            
                                <span className="ws-12 mt pl"> 
                                    <span className="text-bold pull-left"> Uncategorised</span>
                                    <span className="info text-bold pl">(Showing {valueObj?.categoryDetails?.length} out of {valueObj.categoryDetails ? valueObj.categoryDetails[0].totalSubRows : 0})</span>
                                </span>                           

                                <>{(valueObj.categoryDetails[0].totalSubRows > valueObj?.categoryDetails?.length) && 
                                    <a className="btn-primary pull-right bg-level15 white borderNone hide" onClick={() => { viewAll(valueObj.categoryName ); }}> View All </a>
                                } </>
                            </div>}                       

                            {valueObj.categoryDetails.map((value: any, index: any) => {
                                if (viewAllList != valueObj.categoryName && valueObj.categoryName == "Uncategorised") {
                                    return (
                                        <>{(index < 10) && <div className="creatorImg pm pull-left">

                                            <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (value); creatorAsset("All") }}>

                                                <div className="coverPicture">
                                                    <img className="ws-12" src={"data:image/jpeg;base64,"+value.logo} />
                                                </div>
                                                <div className="bottomOverlayLabel">
                                                    <label className="control-label ws-12"> {value.fullName}</label>
                                                    <label className="control-label ws-12 hide"> {value.fullName} </label>
                                                </div>
                                            </div>
                                        </div>}</>
                                    )
                                }
                            })}

                            {(() => {
                                if (viewAllList == "Uncategorised" && directoryViewAll?.length > 0 && valueObj.categoryName == "Uncategorised") {

                                    return (<>
                                        {directoryViewAll.map((values) => {
                                            return (
                                                <div className="creatorImg pm pull-left">

                                                    <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (values); creatorAsset("All") }}>

                                                        <div className="coverPicture">

                                                            <img className="ws-12" src={"data:image/jpeg;base64,"+values.logo} />

                                                        </div>
                                                        <div className="bottomOverlayLabel">
                                                            <label className="control-label ws-12"> {values.fullName}</label>
                                                            <label className="control-label ws-12 hide"> {values.fullName} </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}</>
                                    )
                                }
                            })()} 

                            {(valueObj.categoryDetails[0].totalSubRows > 10) && <div className="ws-12">
                                <div className="ptl">
                                    <Pagination onChange={(e:any)=>paginationAllCategory({pagination:e , categoryName :valueObj.categoryName})}  totalRows={valueObj.categoryDetails[0].totalSubRows} />
                                </div>
                            </div>}
                            
                        </>}
                        </>)
                    })}
                </div>}
 
            </div>
        </div>}

        {(detailView == true) && <>
            <div className="ws-12 ">
                <div className="ws-12 pLarge">
                    <button className="btn-primary btn-sm mrl" onClick={() => {setdetailView(false);selectedDetail=[];setselectedDetail([])}}><i className="icon-angle-left"></i> </button>

                    <div className="mt">
                        <span className="text-bold pt" style={{ textDecoration: "underline" }}> Accounts directory</span>
                        <span className="text-bold pt"> {">"} {selectedDetail.code}</span>
                    </div>

                </div>
                <hr/>
            </div>

            <div className="ws-12 pLarge">
                <div className="ws-12">
                    <div className=" box  shadow" style={{ width: "100px", height: "100px" }}>
                        <div className="coverPicture" style={{ height: "200px" }}>
                            <img className="ws-12 hide" src={"data:image/jpeg;base64,"+selectedDetail.logo} />
                            <img className="ws-12" src={"data:image/jpeg;base64,"+selectedDetail.logo}/>
                        </div>
                    </div>

                    <div className="ws-8 pll">
                        <div className="ws-12 text-xl text-bold mbs ptl pl">{selectedDetail.code}</div>

                        <>
                        {associatedclassification?.map((values: any) => {
                            return (
                                <span className="btn pull-left btn-black">{values.categoryName}</span>
                            )
                        }) }
                        </>

                        <>
                        {associatedSubclassification?.map((values: any) => {
                            return (
                                <span className="btn pull-left btn-blackBorder">{values.text}</span>
                            )
                        }) }
                        </> 
                    </div>

                    <div className="verticalMediumWide hover-box">
                        <ul className="hover-block textboxDropdownList box pLarge defaultColor rightReset ws-2">
                            <li className="ws-12 pbs">
                                <a onClick={() => {setdialogueAddOpenView(true) }}>Edit Profile</a>
                            </li>

                        </ul>
                        <button className="btn btn-info pull-right pt">
                            <i className="icon-grid m0 p0"></i>
                        </button>
                    </div>

                </div>

                <div className="ws-12 ">
                    <ul className="nav nav-tabs ws-12 mbll mtll p0 ws-12">
                        <li className={detailMainTab == 1 ? "mrl active" : "mrl"} onClick={() => { setdetailMainTab(1); }}>Summary</li>
                        <li className={detailMainTab == 2 ? "mrl active hide" : "mrl hide"} onClick={() => { setdetailMainTab(2); }}>Details</li>
                        <li className={detailMainTab == 3 ? "mrl active" : "mrl"} onClick={() => { setdetailMainTab(3); getInvoiceList(); }}>Transactions</li>
                        <li className={detailMainTab == 4 ? "mrl active" : "mrl"} onClick={() => { setdetailMainTab(4); }}>Analytics</li>
                    </ul>
                </div>

                {(detailMainTab == 1) && <div className="forceSingleLine pull-left ws-12">                    
                    <Summary type="DSP" code={{"AccountCode":selectedDetail.code}}/>                    
                </div>}                             

                {(detailMainTab == 3) && <div className="forceSingleLine pull-left ws-12 mtl box">
                    <h2>Showing <span className="info">{creatorAssettotalRow}</span> results</h2>

                    { (creatorAssettotalRow > 0) && <table className="ws-11 mtl table tablePresentation">
                        <thead>
                            <tr>
                                <th>Account Code</th>
                                <th>Header Type</th>
                                <th>Transaction Ref</th>
                                <th>InvoiceNum</th>
                                <th>Royalty Period</th>                                                
                                <th>Operation</th>
                            </tr>
                        </thead>
                        <tbody>
                        {AnalysisinvoicesList.map((values: any) => {
                            return (
                                <tr>
                                    <td>{values.accountCode}</td>
                                    <td>{values.headerType}</td>
                                    <td>{values.transactionRef}</td>
                                    <td>{values.invoiceNum}</td>
                                    <td>{values.royaltyPeriod}</td>
                                    <td><a className="btn btn-xs btn-danger hide"> <i className="icon-trashcan"></i></a></td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>}

                    <div className="ws-12 mtl ptl">
                        
                        <div className="bgDark width200 centerWindow">
                            
                            <a className="btn-success" onClick={()=>{ setCurrentYear(itemCurrentYear - 1); loadYearlyDSPs();  }}>
                                <i className="icon-angle-left text-m"></i>
                            </a>
                            
                            <a className="btn-primary borderNone">
                                <span>{itemCurrentYear}</span>
                            </a>
                            
                            <a className="btn-success" onClick={()=>{ setCurrentYear(itemCurrentYear + 1); loadYearlyDSPs();  }}>
                                <i className="icon-angle-right text-m "></i>
                            </a>
                        </div>
                    </div>

                    <div className="ws-12 height400 ptl mtl">
                        { listYearlyDSPs?.map((values: any) => {
                            return (
                                <div className="ws-3 ptl">
                                    <div className="creatorImg pm pull-left">
                                        <div className="ws-12 text-center">
                                            { CommonUtilities.monthShort[ values.monthNos - 1 ]}
                                        </div>
                                        <div className="ws-12 text-center">
                                            <a className="btn-grey ws-12 mt">
                                                <span className="ws-12">{CommonUtilities.getCSymbols(global.clientDetail.homeCurrency)} {values.totalValue?.toFixed(2) }</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}                 
                    </div>


                    {listYearlyDSPs == 0 && <div className="ws-12 mtl">
                        <span className="alert alert-success ws-12 text-center">No data Available</span>
                    </div>}

                </div>}

                {(detailMainTab == 4) && <div className="forceSingleLine pull-left ws-12 ml mtl">
                    <DirectoryAnalytic selectedDetail={selectedDetail} DAOpen= {detailMainTab == 4?"Account":"Close"}/>
                </div>}
                
                {(() => {
                    if (creatorAssettotalRow && creatorAssettotalRow > 10 && detailMainTab != 4 && detailMainTab != 2 && detailMainTab != 1) {
                        return <Pagination onChange={fePagination} totalRows={creatorAssettotalRow} />
                    }
                })()}                                 
            </div>
        </>}                    

        {(() => {

            if (dialogueAddOpenView) {
                return (<AddAccountSlider onChangeClose={onChangeClose} open={dialogueAddOpenView} selectedDetail={selectedDetail} />)
            }

        })()}
    </div>
    )
}