import React, { useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { CommonUtilities } from "../../commonUtils";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

export default function HorizontalBarChart(chartPassedData : any) {
   
  const options = {
    ...chartPassedData.chartPassedData.options,
    indexAxis: 'y',
    responsive: true,
    plugins: {
      ...chartPassedData.chartPassedData.plugins,
    },
 
  };
 
  return (
    <div className="ws-12">
      {chartPassedData?.chartPassedData?.data?.labels.length > 0 && <div style={CommonUtilities.parseStyleString(chartPassedData?.style)}>
        <Bar options={options} data={chartPassedData.chartPassedData.data}  />
      </div>}

      {chartPassedData?.chartPassedData?.data?.labels.length ==0 && <span className="text-center text-bold ws-12 mtl grey"> No Data Available. </span>}
      
    </div>

  )
}