import React, { useState, useEffect } from 'react';  
import { CommonUtilities } from './commonUtils';

export class Filter {

    static DateTimeFormat (input : string) { 
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        if (input === '0001-01-01T00:00:00') {
            return <span>-</span>;
        } else { 
            const today  = new Date(input);

            const month = months[today.getMonth()];;
            const year = today.getFullYear();
            //const date = today.getDate();
            const date = String(today.getDate()).padStart(2, '0');
            const hours = String(today.getHours()).padStart(2, '0');
            const minutes = String(today.getMinutes()).padStart(2, '0');
            const seconds = String(today.getSeconds()).padStart(2, '0');

            return `${year} ${month} ${date} ${hours}:${minutes}:${seconds}`;         
        }
    }

    static NumToDate (input : string) { 
        //2024 Jan 01 
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var YearVal     = input?.substring(0, 4);
        var MonthVal    = Number( input?.substring(4, 6) ) ;
        var DayVal      = Number( input?.substring(6, 8) ) ;
 
        const periodName = YearVal?.toString() + " " + months[MonthVal-1]?.toString() + " " + DayVal?.toString()
        //console.log(periodName)
        return `${periodName}`;
    }

    static DateTimeFormatFullMonth (input : string) { 
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        if (input === '0001-01-01T00:00:00') {
            return <span>-</span>;
        } else { 
            const today  = new Date(input);

            const month = months[today.getMonth()];;
            const year = today.getFullYear();
            ///const date = today.getDate();
            const date = String(today.getDate()).padStart(2, '0');
            const hours = String(today.getHours()).padStart(2, '0');
            const minutes = String(today.getMinutes()).padStart(2, '0');
            const seconds = String(today.getSeconds()).padStart(2, '0');

            return `${year} ${month} ${date} ${hours}:${minutes}:${seconds}`;         
        }
    }

    static DateTimeFormatNumber (input : string) {  

        if (input === '0001-01-01T00:00:00') {
            return <span>-</span>;
        } else { 
            const today  = new Date(input);

            if (isNaN(today.getTime())) {
                return <span>Invalid Date</span>;
            }

            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(today.getDate()).padStart(2, '0');

            return `${year}${month}${day}`;         
        }
    }

    static convertToDateString  (input: string) {        
        //console.log(input)
        const year = input.substring(0, 4);
        const month = input.substring(4, 6);
        const day = input.substring(6, 8);
    
        // Return the formatted string in YYYY-MM-DDT00:00:00 format
        return `${year}-${month}-${day}T00:00:00`;

    };

    static monthAndDay(input: string){
        var MonthVal    = Number( input?.substring(5, 6) ) ;
        var DayVal      = Number( input?.substring(6, 8) ) ;

        return CommonUtilities.monthFull[Number( input?.substring(5, 7))-1]+ ' ' + input?.substring(8, 10) ;         
    }
}