import CommonSearch from "../../components/commonSearch/CommonSearch"; 
import React, { Suspense, useState, useEffect } from "react";
import directoryServices from "./directoryServices";
import AddImprint from "./Add Slider/addImprint";
import DirectoryAnalytic from "./Analytic/DirectoryAnalytic";
import Pagination from "../Header/Pagination";
import DeleteDialogueBox from "../Common/deleteDialogue";
import Summary from "./summary";

export default function Imprint(param: any) {
 
    let [totalRow, settotalRow] = useState(0); 
    let [deleteJob, setDelete] = useState(false);   
    let [exclude ,setExclude] = useState<any>([]);
    let [viewAllList, setviewAllList] = useState("");
    let [detailView, setdetailView] = useState(false);
    let [detailSubTab, setdetailSubTab] = useState(1);    
    let [openInclude ,setOpenInclude] = useState(false);
    let [detailMainTab, setdetailMainTab] = useState(5);
    let [categoryList, setCategoryList] = useState<any[]>([]);
    let [selectedDetail, setselectedDetail] = useState<any>([]);
    let [creatorAssetList, setcreatorAssetList] = useState<any>([]);
    let [creatorAssettotalRow, setcreatorAssettotalRow] = useState(0);
    let [directoryViewAll, setdirectoryViewAll] = useState<any[]>([]);
    let [dialogueAddOpenView, setdialogueAddOpenView] = useState(false);
    let [activedetailCategory, setActivedetailCategory] = useState("All");
    let [creatorRelatedArtist, setcreatorRelatedArtist] = useState<any>([]);
    let [creatorAssetCategory, setcreatorAssetCategory] = useState<any>([]);
    let [directoryFullListing, setdirectoryFullListing] = useState<any[]>([]);
    let [associatedclassification, setassociatedclassification] = useState<any>([]);
    let [associatedSubclassification, setassociatedSubclassification] = useState<any>([]);

    var loop = 0;
    var pagination = {'pageSize': 10,'pageNumber': 1,"display": "Category","type": "Imprints","categoryName" : "","searchFor" :  "","export" : 0 ,exclude:""}
    var creatorAssetPayLoad = {assetType: "T", categoryName: "", imprintCode: selectedDetail.kontactCode,display: "Category",pageNumber: 1,pageSize: 10 }
    var relatedAssetPayLoad = { imprintCode: selectedDetail.kontactCode,UnRelatedAssets: 1,pageNumber: 1, pageSize: 10}
    var SubclassFicationPayload = {area: "Imprints",referenceCode: "", text: "", pageNumber: 1,pagesize: 10}   

    useEffect(() => { 
        if (loop == 0) {
            LandingListing();
            console.log(loop)
            loop = loop + 1;
        }

    }, [param])

    const LandingListing = () => {
        setdirectoryViewAll([]);        
        setviewAllList("");
        directoryServices.labelSearchListing({ ...pagination }).then((res) => { 
            res.map((values: any) => {

                if (values.categoryName != ' ' && (categoryList.filter((e:any)=> e == values.categoryName))?.length ==0 ){ 
                    categoryList.push(values.categoryName)
                    setCategoryList(categoryList)
                    setExclude(categoryList)
                }

                if(values.categoryName == ' ' && (categoryList.filter((e:any)=> e == "Uncategorised"))?.length ==0 ){                        
                    categoryList.push("Uncategorised")
                    setCategoryList(categoryList)
                    setExclude(categoryList)
                }
            })
            setdirectoryFullListing(res)
            settotalRow(res[0] ? res[0].totalRows : 0)

        })
    }

    let [searchFor,setsearchFor] = useState<any>([]);
    const SearchForHandle = (e: any, sts: any) => { 
        console.log(e)
        console.log(sts)
        if(sts == false || sts == true){          
            searchFor  =e;
            setsearchFor(e)   
            pagination.searchFor =  e?.searchFor; 
            pagination = {...pagination,...searchFor}     
        }

        if ((sts == true)) {

            directoryServices.labelSearchListing({ ...e, ...pagination }).then((res) => {
                console.log(res)
                res.map((values: any) => {
                    if (values.categoryName != ' ' && (categoryList.filter((e:any)=> e == values.categoryName))?.length ==0 ){ 
                        categoryList.push(values.categoryName)
                        setCategoryList(categoryList)
                        setExclude(categoryList)
                    }

                    if(values.categoryName == ' ' && (categoryList.filter((e:any)=> e == "Uncategorised"))?.length ==0 ){                        
                        categoryList.push("Uncategorised")
                        setCategoryList(categoryList)
                        setExclude(categoryList)
                    }
                })
                setdirectoryFullListing(res)
                settotalRow(res[0] ? res[0].totalRows : 0);
            })
        }
    }

    const viewAll = (e:any) => {
        console.log(e);
        viewAllList = e;
        setviewAllList(viewAllList);
        console.log(viewAllList);
        pagination.categoryName =e;
        directoryServices.labelSearchListingAll(pagination).then((res) => {
            console.log(res);
            directoryViewAll = res;
            setdirectoryViewAll(directoryViewAll)
            console.log(directoryViewAll)
            //setSubtotalRow (res[0]?res[0].totalRows : 0)
        })
    }

    const paginationViewAll = (e:any) => {
        console.log(e);
        pagination.pageNumber   = e.pagination.pageNumber;
        pagination.pageSize     = e.pagination.pageSize;
        viewAllList             = e.categoryName
        setviewAllList(viewAllList)         

        viewAll(viewAllList);
    } 

    const creatorAsset = (e: any) => {
    
        if (e != "All") {
            creatorAssetPayLoad.categoryName = e;
        } else { creatorAssetPayLoad.categoryName = ""; }

        if (detailSubTab == 1) {
            creatorAssetPayLoad.assetType = 'T';
        } else if (detailSubTab == 2) {
            creatorAssetPayLoad.assetType = "S";
        } else if (detailSubTab == 3) { creatorAssetPayLoad.assetType = "R"; }

        console.log(detailSubTab)
        setselectedDetail(selectedDetail)

        console.log(selectedDetail)
        creatorAssetPayLoad.imprintCode = selectedDetail.companyName;
        relatedAssetPayLoad.imprintCode = selectedDetail.companyName;

        if (detailSubTab < 4) {
            directoryServices.MainAssetListing(creatorAssetPayLoad).then((res) => {
                setcreatorAssetList(res)
                setcreatorAssettotalRow(res[0] ? res[0].totalRows : 0)
                for (var $i = 0; $i < res.length; $i++) {

                    if (res[$i].categoryName != ' ' && e == "All" && (creatorAssetCategory.length < res[$i].length - 1)) {
                        //console.log("enter " + res[$i].categoryName)
                        creatorAssetCategory.push(res[$i].categoryName)
                        setcreatorAssetCategory(creatorAssetCategory) 
                    }
                }
            })
        } 

        SubclassFicationPayload.referenceCode = selectedDetail.companyName;
        directoryServices.tagListing(SubclassFicationPayload).then((res) => {
            setassociatedSubclassification(res)
        })

        directoryServices.musicDirectoryCategoryListing({ code: selectedDetail.companyName, CategoryType: "Imprints" }).then((res) => {
            setassociatedclassification(res)
        })
    
    }

    useEffect(() => {
        if (activedetailCategory == "All") {
            creatorAssetCategory = []
            setcreatorAssetCategory(creatorAssetCategory)
        }

        for (var $i = 0; $i < creatorAssetList.length; $i++) {
            if (creatorAssetList[$i].categoryName != ' ' && activedetailCategory == "All" && (creatorAssetCategory.length <= creatorAssetList.length)) {
                creatorAssetCategory.push(creatorAssetList[$i].categoryName)
                setcreatorAssetCategory(creatorAssetCategory)
            }
        }

    }, [creatorAssetList])

    const set_detailSubTab = (e: any) => {
        detailSubTab = e
        setActivedetailCategory('All')
        setdetailSubTab(detailSubTab)
    }

    const relatedArtistListing = () => {
        relatedAssetPayLoad.imprintCode = selectedDetail.companyName;
        directoryServices.creatorsListingAll(relatedAssetPayLoad).then((res) => {
            setcreatorRelatedArtist(res)
            console.log(res)
            setcreatorAssettotalRow(res[0] ? res[0].totalRows : 0)
        })
    } 
    
    const onChangeClose = (e: any) => { 
        if(detailView){  
            selectedDetail.labelImage = e.labelImage
        }
        if(!detailView){ 
            setselectedDetail([]);
        }
        setdialogueAddOpenView(false);
        refreshDataListing(detailMainTab);
    }

    const refreshDataListing = (tabNum:any)=>{
        if( tabNum== 1){
            creatorAsset('All');
        } 
        if( tabNum== 3){
            relatedArtistListing();
        }
    }
 
    const Confirmation = (e: any) => {

        if (e == 1) {
            directoryServices.deleteImprint(selectedDetail.labelID)
            setdetailView(false);
            LandingListing();
        }
        setDelete(false);
    }

    const removeTabName     = (value:any)=>{ 
        console.log(value)
        console.log(exclude.filter((e:any)=> e != value) )
        exclude =  exclude.filter((e:any)=> e != value)
        setExclude(exclude); 
        splitArray(exclude)
    }

    const addTabName        = (value:any)=>{  
        if(exclude.filter((e:any)=> e == value).length ==0){ 
            exclude.push(value)
            setExclude(exclude);
            splitArray(exclude)
        }           
    }

    const splitArray =(value :any)=>{
        var string = "";
        
        console.log(exclude) 
        console.log(categoryList)

        const resultArray1 = categoryList.filter(item => !exclude.includes(item));
        console.log(resultArray1.toString())

        if(resultArray1.length > 0){
            string = resultArray1.toString();
        }

        if(exclude.length == categoryList.length){
            string = "";
        }

        if(exclude.length == 0){
            string = categoryList.toString(); 
        }

        if(exclude.length == categoryList.length){            
            pagination.exclude = "";
        }

        pagination.exclude = string;  
        directoryServices.labelSearchListing({ ...pagination }).then((res) => { 
            setdirectoryFullListing(res)
            settotalRow(res[0] ? res[0].totalRows : 0)

        })
    }

    const ExportResultSet =()=>{
        console.log(pagination)

        var typeName = pagination.type =='T' ? 'T' :'T'
        var payload = {...pagination, ...{exportType : "exportType" ,export:1  }}
        console.log(payload)
        directoryServices.excelGenericExport(payload)
    }

    return (
    <div className="landing ws-12">

        <div className="ws-12">
            {(detailView == false) && <div className="plxl prxl">

                <div className="ptxl mtl ptl">
                    <div className="ws-9">
                        <span className="ws-12 text-bold text-xl">Music Directories</span>
                        <span className="ws-12 ptl text-bold text-m turquoise">Imprints</span>
                    </div>
                    <div className="ws-3">
                        <a className="btn btn-success pull-right m0" onClick={()=>ExportResultSet()}>Export </a>
                        <a className="btn bg-level7 white pull-right pm btn-m forceSingleLine" onClick={() => { setdialogueAddOpenView(true);selectedDetail=[];}}>Add new Imprint</a>                            
                    </div> 
                </div> 

                <div className="ws-12 ptxl">
                    <CommonSearch SearchForHandle={SearchForHandle} type="ImprintGrid" title="Imprints" width="ws-12 pull-left" />
                </div>

                <div className="ws-12 mtl mbl">
                
                    <div className="ws-12 box"> 

                        <div className="pm ws-12"> 
                            <span className="pull-left pt text-bold text-m mr mt">Classes : </span>
                            
                            {exclude?.map((list: any) => { return (
                                <>{<div className="tagging bg-level1 mr forceSingleLine">
                                    <span className="bgTransparent text-bold">{list}</span>
                                    <a className="btn-info pull-left btn-xxs borderNone bgTransparent m0" onClick={()=>removeTabName(list)}>
                                        <i className="icon-cross1 text-s mt"></i>
                                    </a>
                                </div>}</>
                            )} )} 
                            <a className="p0 pull-left btn-success borderNone bgTransparent m0" onClick={()=>setOpenInclude(!openInclude)}><i className="icon-plus text-xxl"></i></a>
                        </div>
                    
                        {openInclude && <> <hr/>
                            <div className="pm ws-12"> 
                            {categoryList?.map((list: any) => { 
                                return (<>
                                {(() => { 
                                    if(exclude.filter((e:any)=> e == list).length == 0 ){
                                        return ( 
                                        <div className="tagging bg-level1 mr forceSingleLine">
                                            <span className="bgTransparent text-bold">{list}</span>
                                            <a className="btn-info pull-left btn-xxs borderNone bgTransparent m0" onClick={()=>addTabName(list)}><i className="icon-plus2 text-s mt"></i></a>                                                 
                                        </div>
                                        )
                                    }
                                })()} 
                                </>)
                            })} 
                            </div>
                        </>}

                    </div>

                    <div className="ws-12 ptl pt pb"> 
                        <span className="text-bold text-m">Showing <span className="info">{totalRow}</span> results</span>                         
                    </div> 
                    
                    {(directoryFullListing.length > 0) && <>
                        {directoryFullListing?.map((valueObj: any) => {
                            return ( <div className="ws-12 mt pt pb">
                                {(valueObj && valueObj.categoryName != "Uncategorised") && <> 
                                    {<span className="ws-12 pt">
                                        <span className="text-bold">{valueObj.categoryName}</span>
                                        <span className="info text-bold pl">({valueObj.categoryDetails ? valueObj.categoryDetails[0].totalSubRows : 0} out of {valueObj.categoryDetails ? valueObj.categoryDetails[0].totalSubRows : 0})</span>
                                        {((valueObj.categoryDetails && valueObj.categoryDetails[0].totalSubRows) > 5) && <a className="btn-primary pull-right bg-level15 white borderNone hide" onClick={() => { viewAll(valueObj.categoryName); }}> View All </a>}
                                    </span>}

                                    {valueObj.categoryDetails?.map((value: any, index: any) => {

                                        if (viewAllList != valueObj.categoryName) {
                                            return (
                                                <>{(index < 10) && <div className="creatorImg pm pull-left">

                                                    <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = value; creatorAsset("All") }}>

                                                        <div className="coverPicture">
                                                            <img className="ws-12" src={value.labelImage} onError= { (e : any) => { e.src='../../../../assets/images/NoReleaseImage.jpg' } } />
                                                        </div>
                                                        <div className="bottomOverlayLabel">
                                                            <label className="control-label ws-12"> {value.companyName}</label>
                                                            <label className="control-label ws-12 hide"> {value.companyName} </label>
                                                        </div>
                                                    </div>
                                                </div>}
                                                </>
                                            )
                                        }

                                        if (viewAllList == valueObj.categoryName && directoryViewAll?.length > 0) {

                                            return (<>{directoryViewAll.map((values) => {
                                                return (
                                                    <div className="creatorImg pm pull-left">

                                                        <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = value; creatorAsset("All") }}>

                                                            <div className="coverPicture">
                                                                <img className="ws-12" src={values.labelImage} onError= { (e : any) => { e.src='../../../../assets/images/NoReleaseImage.jpg' } } />
                                                            </div>
                                                            <div className="bottomOverlayLabel">
                                                                <label className="control-label ws-12"> {values.companyName}</label>
                                                                <label className="control-label ws-12 hide"> {values.companyName} </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                })}  
                                                                 
                                            </>)
                                        }
                                    })}
                                                                                    
                                    {(valueObj.categoryDetails[0].totalSubRows > 10 ) && <div className="ws-12"> 
                                        <div className="ptl">
                                            <Pagination onChange={(e:any)=>paginationViewAll({pagination:e , categoryName :valueObj.categoryName})} totalRows={ directoryViewAll[0].totalRows ? directoryViewAll[0].totalRows : 0 } />

                                        </div> 
                                    </div> }  
                                    
                                </>}
                            </div>)
                        })}

                        {directoryFullListing?.map((valueObj: any) => {
                            return ( <div className="ws-12 mt pt pb">
                                {(valueObj.categoryName == "Uncategorised") && <>
                                    {<span className="ws-12 pt">
                                        <span className="text-bold pt"> Uncategorised</span>
                                        <span className="info text-bold pl">({valueObj.categoryDetails ? valueObj.categoryDetails[0].totalSubRows : 0} out of {valueObj.categoryDetails ? valueObj.categoryDetails[0].totalSubRows : 0})</span>
                                        {((valueObj.categoryDetails && valueObj.categoryDetails[0].totalSubRows) > 5) && <a className="btn-primary pull-right bg-level15 white borderNone hide" onClick={() => { viewAll("Uncategorised"); }}> View All </a>}
                                    </span>}

                                
                                    {valueObj.categoryDetails.map((value: any, index: any) => {

                                        if (viewAllList != "Uncategorised" && valueObj.categoryName == "Uncategorised") {
                                            return (
                                            <>{(index < 10) && <div className="creatorImg pm pull-left">

                                                <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = value; creatorAsset("All") }}>

                                                    <div className="coverPicture">
                                                        <img className="ws-12" src={value.labelImage} onError= { (e : any) => { e.src='../../../../assets/images/NoReleaseImage.jpg' } } />

                                                    </div>
                                                    <div className="bottomOverlayLabel">
                                                        <label className="control-label ws-12"> {value.companyName}</label>
                                                        <label className="control-label ws-12 hide"> {value.companyName} </label>
                                                    </div>
                                                </div>
                                            </div>}</>
                                            )
                                        }

                                    }) }                                 
                                
                                    { (() => {  
                                        if (valueObj.categoryName == "Uncategorised" && viewAllList == valueObj.categoryName && viewAllList == "Uncategorised") {

                                            return (<>
                                                {directoryViewAll.map((values) => {
                                                    return (
                                                        <div className="creatorImg pm pull-left">

                                                            <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = values; creatorAsset("All") }}>

                                                                <div className="coverPicture">
                                                                    <img className="ws-12" src={values.labelImage} onError= { (e : any) => { e.src='../../../../assets/images/NoReleaseImage.jpg' } } />
                                                                </div>
                                                                <div className="bottomOverlayLabel">
                                                                    <label className="control-label ws-12"> {values.companyName}</label>
                                                                    <label className="control-label ws-12 hide"> {values.companyName} </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}   
                                                                  
                                            </>)
                                        }  
                                    })() } 

                                    {(valueObj.categoryDetails[0].totalSubRows >10) && <div className="ws-12"> 
    
                                        <div className="ptl">
                                            <Pagination onChange={(e:any)=>paginationViewAll({pagination:e , categoryName :valueObj.categoryName})} totalRows={valueObj.categoryDetails[0].totalSubRows? valueObj.categoryDetails[0].totalSubRows: 0 } />
                                        </div>
                                        
                                    </div>} 
                                </>}
                            </div> )
                        }) }
                        
                    </>}
                </div>

            </div> }

            {(detailView == true) && <>
                <div className="ws-12 ">
                    <div className="ws-12 pLarge">
                        <button className="btn-primary btn-sm mrl" onClick={() => {setdetailView(false);selectedDetail=[];setselectedDetail([])}}>
                            <i className="icon-angle-left"></i>
                        </button>

                        <div className="mt">
                            <span className="text-bold pt" style={{ textDecoration: "underline" }}> Imprints directory</span>
                            <span className="text-bold pt"> {">"} {selectedDetail.companyName}</span>
                        </div>
                    </div>

                    <hr className="mtxl" />
                </div>

                <div className="ws-12 pLarge">
                    <div className="ws-12">
                        <div className="box shadow" style={{ width: "100px", height: "100px" }}>
                            <div className="coverPicture" style={{ height: "200px" }}>
                                <img className="ws-12" src={selectedDetail.labelImage} onError= { (e : any) => { e.src='../../../../assets/images/NoReleaseImage.jpg' } } />
                            </div>
                        </div>

                        <div className="ws-8 mtl pt pll">
                            <span className="ws-12 text-xl text-bold mbl ptl pl">{selectedDetail.companyName}</span> 
                            <>
                            { associatedclassification?.map((values: any) => {
                                return (  <span className="btn pull-left btn-black">{values.categoryName}</span> )
                            }) }
                            </>

                            <>
                            {associatedSubclassification?.map((values: any) => {
                                return (  <span className="btn pull-left btn-blackBorder">{values.text}</span> )
                            })}
                            </>

                        </div>

                        <div className="verticalMediumWide hover-box">
                            <ul className="hover-block textboxDropdownList box pLarge defaultColor rightReset ws-2">
                                <li className="ws-12 pbs">   <a onClick={() => {setdialogueAddOpenView(true) }}>Edit Profile</a> </li>
                                <li className="ws-12 danger"> <a onClick={() => setDelete(true)} style={{ color: "red" }}>Delete Imprint</a> </li>
                            </ul>
                            <button className="btn btn-info pull-right pt">
                                <i className="icon-grid m0 p0"></i>
                            </button>
                        </div>
                    </div>

                    <div className="ws-12 ">
                        <ul className="nav nav-tabs ws-12 mbll mtll  ws-12">
                            <li className={detailMainTab == 5 ? "mrl active" : "mrl"} onClick={() => setdetailMainTab(5)} >Summary</li>
                            <li className={detailMainTab == 4 ? "mrl active" : "mrl"} onClick={() => setdetailMainTab(4)}>Analytics</li>
                            <li className={detailMainTab == 1 ? "mrl active" : "mrl"} onClick={() => { setdetailMainTab(1); creatorAsset("All") }}>Assets</li>
                            <li className={detailMainTab == 3 ? "mrl active" : "mrl"} onClick={() => { setdetailMainTab(3); relatedArtistListing() }}>Related Artists</li>
                        </ul>
                    </div>

                    {(detailMainTab == 1) && <div className="forceSingleLine pull-left">
                        <ul className="nav nav-tabs ws-12">
                            <li className={detailSubTab == 1 ? " plxl prxl active" : " plxl prxl"} onClick={() => { set_detailSubTab(1); creatorAsset('All') }}>Tracks</li>
                            <li className={detailSubTab == 2 ? " roundButtons plxl prxl active" : " plxl prxl"} onClick={() => { set_detailSubTab(2); creatorAsset('All') }}> Songs </li>
                            <li className={detailSubTab == 3 ? " plxl prxl active" : " plxl prxl"} onClick={() => { set_detailSubTab(3); creatorAsset('All') }}>Releases</li>
                        </ul>
                    </div>}

                </div>

                <div className="landing ws-12 pll">
                    {(detailMainTab == 1) && <div className="forceSingleLine pull-left ws-12">
                        <div className="box-content p0 mt">
                            <h2>Showing <span className="info">{creatorAssettotalRow}</span> results</h2>

                            {(detailSubTab < 4 && creatorAssettotalRow > 0) && <div className="ws-9 pl ml">
                                <ul className="nav nav-tabs p0 m0">
                                    <li key='All' className={activedetailCategory == 'All' ? "m0 mt mr plxl prxl active" : "m0 mt mr plxl prxl "}
                                        onClick={() => { setActivedetailCategory('All'); creatorAsset('All') }}> All
                                    </li>
                                    {  creatorAssetCategory?.map((values: any) => {
                                        return (
                                        <li key='All' className={activedetailCategory == values ? "m0 mt mr plxl prxl active" : "m0 mt mr plxl prxl "}
                                            onClick={() => { setActivedetailCategory(values); creatorAsset(values) }}>
                                            {values}
                                        </li>
                                        )
                                    }) }
                                </ul>
                            </div>}
                        </div>

                        <div className="ws-12">{
                            (() => {
                                if (detailSubTab < 4) {

                                    return (
                                        creatorAssetList?.map((value: any) => {
                                            return (value.categoryDetails?.map((values: any) => {
                                                return (
                                                    <div className="creatorImg pm pull-left" >

                                                        <div className="box shadowCoverPicture shadow miniCreatorImg">
                                                        <div className="coverPicture">
                                                            <img className="ws-12" src={values.assetImage} onError= { (e : any) => { e.src='../../../../assets/images/NoReleaseImage.jpg' } } />
                                                        </div>

                                                        <i className="icon-pictures1 primary pull-none coverPicture ptl hide" ></i>

                                                        <div className="bottomOverlayLabel">
                                                            <label className="control-label ws-12">{values.name}</label>
                                                            <label className="control-label ws-12 text-bold text-uppercase"> {values.assetContactCode}</label>
                                                        </div>
                                                    </div>

                                                </div>)
                                            }))
                                        })
                                    )
                                }

                                if (detailSubTab == 4) {
                                    return (
                                        creatorAssetList?.map((values: any) => {
                                            return (
                                            <div className="creatorImg pm pull-left" >
                                                <div className="box shadowCoverPicture shadow miniCreatorImg">
                                                    <div className="coverPicture">
                                                    <img className="ws-12" src={values.assetImage} onError= { (e : any) => { e.src='../../../../assets/images/NoReleaseImage.jpg' } } />
                                                    </div>
                                                    <i className="icon-pictures1 primary pull-none coverPicture ptl hide" ></i>
                                                    <div className="bottomOverlayLabel">
                                                        <label className="control-label ws-12">{values.bundleTitle}</label>
                                                        <label className="control-label ws-12 text-bold text-uppercase"> {values.bundleCode}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        })
                                    )
                                }
                            })()
                        }</div>
                    </div>}

                    {(detailMainTab == 3) && <div className="forceSingleLine pull-left">

                        <h2>Showing <span className="info">{creatorAssettotalRow}</span> results</h2>
                        {creatorRelatedArtist?.map((values: any) => {
                            return (
                            <div className="creatorImg pm pull-left" >

                                <div className="box shadowCoverPicture shadow miniCreatorImg">
                                    <div className="coverPicture">
                                        <img className="ws-12" src={values.creatorImage} onError= { (e : any) => { e.src='../../../../assets/images/NoReleaseImage.jpg' } } />
                                    </div>

                                    <i className="icon-pictures1 primary pull-none coverPicture ptl hide" ></i>

                                    <div className="bottomOverlayLabel">
                                        <label className="control-label ws-12">{values.kontactDisplay}</label>
                                        <label className="control-label ws-12 text-bold text-uppercase"> {values.kontactCode}</label>
                                    </div>
                                </div>

                            </div>
                            )
                        })}
                    </div>}

                    {(detailMainTab == 4) && <div className="forceSingleLine pull-left ws-12">
                        <DirectoryAnalytic selectedDetail={selectedDetail} DAOpen= {detailMainTab == 4?"Imprint":"Close"}/>
                    </div>}

                    {(detailMainTab == 5) && <div className="forceSingleLine pull-left ws-12">
                        <Summary type="Imprint" code={{"AssetImprint":selectedDetail.companyName}}/>                                
                    </div>}   

                    {(creatorAssettotalRow == 0) && (detailMainTab < 4) && <div className="ws-12 mtl prl pbl">
                        <span className="alert alert-success ws-12 text-center">No data Available</span>
                    </div>}
                </div>

            </>}
        </div>        

        {(() => {

            if (dialogueAddOpenView) {
                return (<AddImprint onChangeClose={onChangeClose} open={dialogueAddOpenView} selectedDetail={selectedDetail} />)
            }

            if (deleteJob) {
                return <DeleteDialogueBox param={true} message={"delete contact " + selectedDetail.companyName} onChange={Confirmation} />
            }
        })()}
    </div>
    )
}   