import React, { useState, useEffect }   from "react";
import Drawer                           from "react-modern-drawer";
import commonServices                   from "./commonServices";
import { CommonUtilities }              from "../../commonUtils";
import Pagination                       from "../../components/Header/Pagination";

export default function MonitoringSlider(event: any) {

    let [totalRows, setTotalRows]       = useState(0);
    let [SearchFor, setSearchFor]       = useState("");
    let [execHeader, setexecHeader]     = useState<any>([]);
    let [execListing, setexecListing]   = useState<any>([]);

    const toggleDrawer = () => { event.CloseDetail(true) }
    const Data: any = { pageNumber: 1, pageSize: 10 }

    const SearchResult = () => {
        var payload = {   
            ErrorMessage    :   event?.errorName?.sQLErrorName  , 
            MonitoringClient:   event?.errorName?.sQLClient     , 
            PageNumber      :   Data.pageNumber                 , 
            PageSize        :   Data.pageSize                   ,
            SearchFor       :   SearchFor                       ,
            MonitoringType  :   event.operation
        }

        commonServices.MonitorDetail(payload).then((values) => {
            setexecListing(CommonUtilities.relativeDataRemapping(values.data, values.headers.original))
            setTotalRows(values.totalRows)
            execHeader = [];
            Object.entries(values.headers.display).forEach(([key, value]) => { execHeader.push(value) });
            setexecHeader(execHeader)
            console.log(CommonUtilities.relativeDataRemapping(values.data, values.headers.original))
        })
    }

    const handleNameOnChange = (n: any) => {
        Data.pageNumber = n.pageNumber;
        Data.pageSize = n.pageSize;
        SearchResult();
    }

    const download = () => {
        var payload = { 
            MonitoringType  : event?.errorName?.errorType ,ErrorMessage: event?.errorName?.errorName,
            MonitoringClient: event?.errorName?.dBNAME,PageNumber: Data.pageNumber, 
            PageSize: Data.pageSize , type: "errorListing",exportType: "errorListing"
        }

        commonServices.excelGenericExport(payload) 
    }

    const saveSQLCheck=()=>{

    }

    useEffect(() => {
        SearchResult();
    }, []);

    return (<>

        <Drawer open={event.openDetail}
            onClose={toggleDrawer}
            direction='right'
            className='react-drawer-left'>

            <div className='box ws-6 pull-right  full-height-vh allowHscroll'>

                <div className="box-header  noBorderRadius bg-blue ws12"><h2 style={{ color: "White" }}>{event?.errorName?.errorName}</h2></div>

                <div className="ws-12 pull-right pLarge"> 
 
                    <fieldset className="scheduler-border p0 m0">
                        <legend className="scheduler-border">
                            <h2 style={{ color: "#47529d" }}>  <i className="fa fa-sticky-note-o"></i> Error Info </h2>
                        </legend>
                        
                        <div className="ws-12">

                            <div className="ws-6">
                                <div className="form-format">
                                    <label className="control-label ws-4 text-left plxl"> Error Name : </label>
                                    <div className="label-entries ws-8"> {event?.errorName?.sQLErrorName} </div>
                                </div>
                            </div>

                            <div className="ws-6">
                                <div className="form-format">
                                    <label className="control-label ws-4 text-left plxl"> Client Name:  </label>
                                    <div className="label-entries ws-8"> {event?.errorName?.sQLClient} </div>
                                </div>
                            </div> 

                        </div> 
                    </fieldset>  

                    {event && event.operation =='view' && <>
                        <div className="ws-12 mbl ptl pr mr"> 
                            <div className='ws-10'>
                                <input type="text" className="m0 heightReset noBorderRadius pull-left full-width" value={SearchFor} placeholder="Type to search" onChange={(e) => setSearchFor(e.target.value)} />
                            </div>
                            <div className='ws-2'>
                                <button className="btn-success text-center full-width" onClick={()=>SearchResult()} > Search  </button>
                            </div>
                        </div>

                        <div className="ws-12 text-bold mbl">
                            <span className="pull-left mt">Showing ( {totalRows} ) results </span>
                        </div>                   

                        <div className="ws-12 pull-left box pll">

                            <div className="table-responsive mrl allowVscroll">

                                <table className="table mbxl mtl">
                                    <tr>
                                        {execHeader.map((values: any, index: any) => {
                                            if (values != 'totalRow' && values != 'rowNumber') { return (<> <th> {CommonUtilities.headerName(values)} </th> </>) }
                                        })}
                                    </tr>

                                    {execListing.map((value: any) => {
                                        return (<>
                                            <tr>
                                                {execHeader.map((Hvalues: any, index: any) => {
                                                    if (Hvalues != 'totalRow' && Hvalues != 'rowNumber') { return (<> <td> {value[Hvalues]} </td> </>) }
                                                })}
                                            </tr>
                                        </>)
                                    })}
                                </table>
                            </div>

                            <div className="ws-12">
                                {(() => {
                                    if (totalRows && totalRows > 10) {
                                        return <Pagination onChange={handleNameOnChange} totalRows={totalRows} />
                                    }
                                })()}
                            </div>
                        </div>

                        <div className="box-footer alignBottom">
                            <button type="button" className="btn-primary pull-right" onClick={() => download()}><i className="icon-download1"></i>  </button>
                            <button className="btn-danger pull-right hide" onClick={() => SearchResult()}>Search</button>
                            <button className="btn-danger pull-right" onClick={() => toggleDrawer()}> <i className="icon-close"></i> </button>
                        </div>
                    </>}

                    {event && event.operation =='edit' && 
                    <div className='mtl'>
                        <div className='ws-12'> 
                            <span className='text-bold ws-12 text-left mtl mb'>Invalid check SQL</span>                    
                            <textarea id="NoteDetails" disabled={true} className='mrl' value={execListing[0]?.storeProc} style={{ width: "100%", height: "150px" }}></textarea> 
                            
                            <span className='text-bold ws-12 text-left mtl mb'>valide check SQL</span>                    
                            <textarea id="NoteDetails" disabled={true} className='mrl' value={execListing[0]?.validSQL}  style={{ width: "100%", height: "150px" }}></textarea>                     
                        
                            <span className='text-bold ws-12 text-left mtl mb'>SQL Run Time</span>   
                            <select className="ws-12"  value={execListing[0]?.runningTime}>
                                <option className="width200">Choose Run Time</option> 
                                <option className="width200" label='Daily'   value={'daily'}>Daily</option>          
                                <option className="width200" label='Weekly'  value={'weekly'}>Weekly</option>  
                                <option className="width200" label='Monthly' value={'monthly'}>Monthly</option>                      
                            </select>                          
                            
                            <div className='ws-6'>
                                <span className='text-bold ws-12 text-left mtl mb'>Disable SQL</span>
                                <label className="switch mr">
                                    <input type="checkbox" value={execListing[0]?.activeSQL} checked={execListing[0]?.activeSQL == 1 ? true:false}/>
                                    {execListing[0]?.activeSQL == 1 && <span className="slider round text-left white pm pts">Yes</span>}
                                    {execListing[0]?.activeSQL != 1 && <span className="slider round text-right white pm pts sliderOff">No</span>} 
                                </label>
                            </div>

                            <div className='ws-6'>
                                <span className='text-bold ws-12 text-left mtl mb'>Run SQL for Project Validation</span>   
                                <label className="switch mr">
                                    <input type="checkbox" value={execListing[0]?.pSQL} checked={execListing[0]?.pSQL == 1 ? true:false}/>
                                    {execListing[0]?.pSQL == 1 && <span className="slider round text-left white pm pts">Yes</span>}
                                    {execListing[0]?.pSQL != 1 &&<span className="slider round text-right white pm pts sliderOff">No</span>} 
                                </label>
                            </div>
                        </div>
                        
                        <div className="box-footer alignBottom"> 
                            <button className="btn-success pull-right"  onClick={() => saveSQLCheck()}> <i className="icon-save-disk"></i></button>
                            <button className="btn-danger pull-right"   onClick={() => toggleDrawer()}> <i className="icon-close"></i> </button>
                        </div>

                    </div>}
                </div>
            </div>
        </Drawer>
    </>)
}