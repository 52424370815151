import React, { Suspense, useState, useEffect, useRef } from "react";
import { Modal } from 'react-responsive-modal';

export default function TableConfirmation(param: any) {
    const [openFirst, setOpenFirst] = React.useState(false);

    const returnValue = (e: any) => {
        param.onClearConfirmation(e)
        setOpenFirst(false)
    }


    return (<>

        <Modal open={param.open} onClose={() => returnValue(0)} classNames={{ overlay: '', modal: ' ws-10 mrxl bgTransparent allowHscroll allowVscroll mtxl'}}>

            <div className="box-header-fix box ws-5 pull-right" >

                <div className="box-header-main">
                    <h2><i className="fa fa-question-circle"></i> Clear Table?</h2>
                </div>

                <div className="box-content test"><span>Are you sure you want to clear the table?</span></div>

                <div className="box-footer">
                    <button className="btn-danger" onClick={() => returnValue(2)} > Cancel</button>
                    <button className="btn-success" onClick={() => returnValue(1)} > Confirm</button>
                </div>

            </div>
        </Modal>
    </>)
}