import Drawer from 'react-modern-drawer';
import React, { useEffect, useState } from 'react';
import TransactionServices from './TransactionServices';
import commonServices from '../../components/Common/commonServices';
import Pagination from '../Header/Pagination';
export default function ItemAnalysisFormatsTerritory(params: any) {

    let [name, setName] = useState("");
    let [code, setCode] = useState("");
    let [artist, setArtist] = useState("");
    let [activeTab, setactiveTab] = useState(1);
    let [totalRows, setTotalRows] = useState(0);
    let [payLoad, setpayLoad] = useState<any>([]);
    let [dataListing, setdataListing] = useState<any>();
    let [selectedListing, setselectedListing] = useState<any>([]);

    var obj = {
        ContainerType   : params.type == "Contract"? "ContractRefNum" : params.type,
        option          : "code",
        projectId       : params && params.mainItemCode && params.mainItemCode.projectId    ? params.mainItemCode.projectId : "",
        KontactCode     : params && params.mainItemCode && params.mainItemCode.CreatorCode  ? params.mainItemCode.CreatorCode : "",
        pageNumber      : 1,
        pageSize        : 10,
        code            : code,
        name            : name,
        artist          : artist
    }

    useEffect(() => {        
        const element = document.querySelector('[id^="EZDrawer"]');
        element?.classList.add("full-height-vh");
        SearchData();
    }, [params.type]);
    
    useEffect(() => {
        setdataListing({})
        setTotalRows(0)
    }, [params.open]);

    const SearchData = () => {

        if (params.type == 'itemCode') {
            if (activeTab == 1) {
                TransactionServices.getItemCodeSoldTracks(payLoad).then((values) => {
                    //console.log(values.track)
                    setdataListing(values.track);
                    setTotalRows(values.track.length)
                })
            }

            if (activeTab == 2) {
                TransactionServices.getItemCodeSoldCompilations(payLoad).then((values) => {
                    //console.log(values.compilation);
                    setdataListing(values.compilation);
                    setTotalRows(values.compilation.length)
                })
            }

            if (activeTab == 3) {
                TransactionServices.getItemCodeSoldContracts(payLoad).then((values) => {
                    //console.log(values.contract)
                    setdataListing(values.contract);
                    setTotalRows(values.contract.length)
                })
            }
        }
        if (params.type == "AnalysisCode" || params.type == "Formats" || params.type == "Territories" || params.type == 'AssetCode' ||params.type == 'ArtistCode' || params.type == 'AccountCode'|| params.type == 'SubProvider' || params.type == "Contract") {
            //assetType: track
            if(activeTab == 1) {   obj = {...obj , ...{"assetType": "track"}} }
            if(activeTab == 2) {   obj = {...obj , ...{"assetType": "song"}} }
            if(activeTab == 3) {   obj = {...obj , ...{"assetType": "release"}} }

            commonServices.listContainerCodes(obj).then((values) => {
                dataListing = values;
                setdataListing(dataListing);
                setTotalRows(dataListing.length > 0 ? dataListing[0].totalRows : 0);
            })
        }

    }
    console.log(params.type)
    const formHandle = (e: any) => {
        var objName = e.target.name;
        var objValue = e.target.value;
        console.log(objName)
        console.log(objValue)
        setpayLoad((previous: any) => ({ ...previous, [objName]: [objValue] }))
    }

    const closePage = () => {
        params.closePage();
    }

    const selectDate = (e: any) => {
        console.log(e)
        selectedListing = e;
        setselectedListing(selectedListing);
        params.ItemSelected(selectedListing);
        //closePage();
    }    

    const paginationViewAll =(e :any)=>{
        obj.pageNumber = e.pageNumber;
        obj.pageSize = e.pageSize;
        SearchData();
    }

    return (

    <Drawer open={params.open}  onClose={closePage} direction='left' className='react-drawer' >
        <div className="box noBorderRadius noBorder ws-6 pbl pull-left full-height-vh allowVscroll">

            <div className="box-header-main">  <h2>Search By {params.type}</h2>  </div>

            <div className="box-content">

                <div className="ws-12">
                    {params.type == 'itemCode' && <ul className="nav nav-tabs">
                        <li className={activeTab == 1 ? "nav-horizontal active" : "nav-horizontal"} onClick={() => { setactiveTab(1); setTotalRows(0) }}>ISRC</li>
                        <li className={activeTab == 2 ? "nav-horizontal active" : "nav-horizontal"} onClick={() => { setactiveTab(2); setTotalRows(0) }}>Release Code</li>
                        <li className={activeTab == 3 ? "nav-horizontal active" : "nav-horizontal"} onClick={() => { setactiveTab(3); setTotalRows(0) }}>Contract</li>
                    </ul>}

                    {params.type == 'AssetCode' && <ul className="nav nav-tabs">
                        <li className={activeTab == 1 ? "nav-horizontal active" : "nav-horizontal"} onClick={() => { activeTab=1 ; setactiveTab(1); setTotalRows(0); SearchData();}}> Tracks</li>
                        <li className={activeTab == 2 ? "nav-horizontal active" : "nav-horizontal"} onClick={() => { activeTab=2 ; setactiveTab(2); setTotalRows(0); SearchData();}}> Songs</li>
                        <li className={activeTab == 3 ? "nav-horizontal active" : "nav-horizontal"} onClick={() => { activeTab=3 ;setactiveTab(3); setTotalRows(0); SearchData();}}> Release</li>
                    </ul>}

                    {(params.type == 'Formats' || params.type == 'Territories' || params.type == 'AssetCode' || params.type == 'Contract') && <div className="ws-12 mbl mtl">
                        <label className={params.type == 'Formats' ? "w-25" : "w-20"}> <input type="radio" value="code" name="6798" /> Code </label>
                        <label className={params.type == 'Formats' ? "w-25" : "w-20"}> <input type="radio" value="AnalysisDIM1" name="6798" /> Group 1 </label>
                        <label className={params.type == 'Formats' ? "w-25" : "w-20"}> <input type="radio" value="AnalysisDIM2" name="6798" /> Group 2  </label>
                        <label className={params.type == 'Formats' ? "w-25" : "w-20"}> <input type="radio" value="AnalysisDIM3" name="6798" /> Group 3 </label>
                        {(params.type == 'Territories' || params.type == 'AssetCode') &&
                            <label className={params.type == 'Formats' ? "w-25" : "w-20"}> <input type="radio" value="AnalysisDIM3" name="6798" /> Group 4 </label>}
                    </div>}

                </div>

                <div className="row-fluid mll pll ws-12" >

                    {activeTab == 1 && params.type == 'itemCode' && <div className="ws-12">

                        <div className="form-format">
                            <label className="control-label ">ISRC</label>

                            <div className="ws-10">
                                <input type="text" placeholder="Enter an ISRC to search" name='intStdRecCde' onChange={(e) => formHandle(e)}
                                    value={payLoad.intStdRecCde ? payLoad.intStdRecCde : ''} className="form-control ws-12" id="ISRC" />
                            </div>

                            <div className="ws-2">
                                <a className="btn-success" onClick={SearchData}>
                                    <i className="icon-search"></i>
                                </a>
                            </div>
                        </div>

                        {totalRows == 0 && <div className="alert alert-info mt">No tracks to display, search for tracks</div>}
                        <div className="alert alert-danger mt hide">Enter at least a 3 characters query</div>

                        {totalRows > 0 && <div className="table-responsive_big ws-11 mtl">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Track Title</th>
                                        <th>Artist Name</th>
                                        <th>Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataListing.map((values: any) => {
                                        return (
                                            <tr >
                                                <td >{values.intStdRecCode}</td>
                                                <td >{values.trackTitle}</td>
                                                <td >{values.isrcArtistName}</td>
                                                <td><a className="btn btn-primary" onClick={() => selectDate(values)}> Select</a></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>}
                    </div>}

                    {activeTab == 2 && params.type == 'itemCode' && <div className="ws-12">
                        <div className="form-format">
                            <label className="control-label ">Releases</label>
                            <div className="ws-10">
                                <input type="text" placeholder="Enter a release to search" className="form-control ws-12" id="release" name='catLogNum'
                                    onChange={(e) => formHandle(e)} value={payLoad.catLogNum ? payLoad.catLogNum : ''} />
                            </div>
                            <div className="ws-2">
                                <a className="btn-success" onClick={SearchData}> <i className="icon-search"></i>  </a>
                            </div>
                        </div>
                        {totalRows == 0 && <div className="alert alert-info mt">No releases to display, search for releases</div>}
                        <div className="alert alert-danger mt hide">Enter at least a 3 characters query</div>

                        {totalRows > 0 && <div className="table-responsive_big ws-11 mtl">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>CatLogNumber</th>
                                        <th>Format</th>
                                        <th>Release Title</th>
                                        <th>Artist Name</th>
                                        <th>Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        dataListing.map((values: any) => {
                                            return (
                                            <tr >
                                                <td >{values.catLogNumber}</td>
                                                <td >{values.format}</td>
                                                <td >{values.releaseTitle}</td>
                                                <td >{values.artistName}</td>
                                                <td><a className="btn btn-primary" onClick={() => selectDate(values)}> Select</a></td>
                                            </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>}

                    </div>}

                    {activeTab == 3 && params.type == 'itemCode' && <div className="ws-12">
                        <div className="form-format">
                            <label className="control-label ">Contract</label>
                            <div className="ws-10">
                                <input type="text" placeholder="Enter a contract to search" className="form-control ws-12" id="contract" name="conRefNum" onChange={(e) => formHandle(e)} value={payLoad.conRefNum ? payLoad.conRefNum : ''} />
                            </div>
                            <div className="ws-2" onClick={SearchData}>
                                <a className="btn-success"> <i className="icon-search"></i> </a>
                            </div>
                        </div>
                        {totalRows == 0 && <div className="alert alert-info mt">No contracts to display, search for contracts</div>}
                        <div className="alert alert-danger mt hide" >Enter at least a 3 characters query</div>

                        {totalRows > 0 && <div className="table-responsive_big ws-11 mtl">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>ContractRefNum</th>
                                        <th>Contract Title</th>
                                        <th>Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {dataListing.map((values: any) => {
                                    return (
                                    <tr >
                                        <td >{values.contractRefNum}</td>
                                        <td >{values.contractTitle}</td>
                                        <td><a className="btn btn-primary" onClick={() => selectDate(values)}> Select</a></td>
                                    </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>}

                    </div>}

                    {(params.type == 'AnalysisCode' || params.type == 'Territories') && <div className="ws-12">
                        <div className="form-format ws-12">
                            <label className="control-label">Search</label>
                            <div className="label-entries ws-8 mt">
                                <div className="prl ws-4">
                                    <input type="text" className="form-control" placeholder="Code" onChange={(e) =>setName(e.target.value)} value={code?code:""}/>
                                </div>
                                <div className="prl ws-8">
                                    <input type="text" className="form-control" placeholder="Name" onChange={(e) =>setName(e.target.value)} value={name?name:""}/>
                                </div>
                                <div className="prl ws-4 hide">
                                    <input type="text" className="form-control" placeholder="Artist" onChange={(e) =>setArtist(e.target.value)} value={artist?artist:""}/>
                                </div>
                            </div>

                            <div className="pull-left ptxl mtl">
                                <a className="btn btn-small btn-success mt pull-left" onClick={SearchData}> Search</a>
                            </div>
                        </div>
                    </div>}

                    {(params.type == 'Formats' || params.type == 'AssetCode') && <div className="ws-12">
                        <div className="form-format ws-10">
                            <label className="control-label ws-12" >Search</label>
                            <div className="label-entries ws-12 mt">
                                <div className="prl ws-4">
                                    <input type="text" className="form-control ws-12" placeholder="Code" onChange={(e) =>setCode(e.target.value)} value={code?code:""}/>
                                </div>
                                <div className="prl ws-4" >
                                    <input type="text" className="form-control ws-8" placeholder="Name" onChange={(e) =>setName(e.target.value)} value={name?name:""}/>
                                </div>
                                <div className="prl ws-4" >
                                    <input type="text" className="form-control ws-8" placeholder="Artist" onChange={(e) =>setArtist(e.target.value)} value={artist?artist:""}/>
                                </div>
                            </div>

                        </div>

                        <div className="ws-2 ptxl mtl">
                            <a className="btn btn-small btn-success mt pull-left" onClick={SearchData}> Search</a>
                        </div>
                    </div>}

                    {(params.type == 'AccountCode' || params.type == 'SubProvider' || params.type == 'Contract' || params.type == 'ArtistCode') && <div className="ws-12">
                        <div className="form-format ws-10">
                            <label className="control-label ws-12" >Search</label>
                            <div className="label-entries ws-12 mt">
                                <div className="prl ws-4">
                                    <input type="text" className="form-control ws-12" placeholder="Code" onChange={(e) =>setCode(e.target.value)} value={code?code:""}/>
                                </div>
                                <div className="prl ws-8" >
                                    <input type="text" className="form-control ws-8" placeholder="Name" onChange={(e) =>setName(e.target.value)} value={name?name:""}/>
                                </div>
                            </div>

                        </div>

                        <div className="ws-2 ptxl mtl">
                            <a className="btn btn-small btn-success pull-left" onClick={SearchData}> Search</a>
                        </div>
                    </div>}

                </div>

                <div className="ws-12">

                    {(params.type == 'AnalysisCode' || params.type == 'Formats' || params.type == 'Territories' || params.type == 'AssetCode' || params.type == 'AccountCode' || params.type == 'SubProvider' || params.type == 'ArtistCode') && <>
                        <div className="ws-12 text-bold mbl">Showing ({totalRows}) results</div>
                        {totalRows == 0 && <div className="alert alert-info mt ">No records to display </div>}
                        {totalRows > 0 && <div className="table-responsive_big">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Name</th>
                                        <th>Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {dataListing?.map((values: any) => {
                                    return (
                                    <tr>
                                        <td >{values.code}</td>
                                        <td >{values.name}</td>
                                        <td><a className="btn btn-primary" onClick={() => selectDate(values)}> Select</a></td>
                                    </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>}
                    </>}

                </div>

            </div>

            <div className="ws-12 pm">
            {(() => {
                if (totalRows >  10) {
                    return <div className="ptl prl"><Pagination onChange={paginationViewAll} totalRows={totalRows} /></div>
                }
            })() }
            </div>  

            <div className="box footer pm noBorderRadius">
                <button className="btn-danger pull-right" onClick={closePage}>Close</button>
            </div>
        </div>            
    </Drawer>
    )
}