import React, { Suspense, useState, useEffect, useRef } from "react";
import { Modal } from 'react-responsive-modal';
import directoryServices from "../directoryServices";
import { CommonUtilities } from "../../../commonUtils";

export default function FileValidation(param: any) {

    let [openEdit, setOpenEdit] = useState(false);     
    let [commitSuc, setCommitSuc] = useState(false);     
    let [ErrorName, setErrorName] = useState<any>([]);
    let [ErrorFound, setErrorFound] = useState(false);
    let [EditValue, setEditValue] = useState<any>([]);
    let [ErrorListing, setErrorListing] = useState<any>([]);   
    let [assetvalidation, setassetvalidation] = useState<any>([]);

    useEffect(() => {
        validationFile();
        setOpenEdit(false)
    }, [param.open]); 

    const returnValue = (e: any) => {
        param.onFileValidation(e);
    }

    const validationFile = () => {
        var type = ""
        if (param.type == "Tracks") { type = 'tracks' }
        if (param.type == "Releases") { type = 'releases' }
        if (param.type == "ReleasesTracks") { type = 'releaseTrack' }
        if (param.type == "DeaperPrice") { type = 'Dealerprice' }
        if (param.type == "Bundle") { type = 'bundles' }
        
        directoryServices.validation({ "type": type }).then((res: any) => {
            setassetvalidation(res);  
            checkErrorFound(res);
        })
    }

    const checkErrorFound = (tempvalue:any) =>{
        var tempError = tempvalue?.filter((tempvalue: any) => tempvalue.count > 0);
        console.log(tempError.length)
        if (tempError.length > 0) {
            ErrorFound = false;
            setErrorFound(false)
        }

        if (tempError == 0) {
            ErrorFound = true;
            setErrorFound(true)
        }
    }

    const validationList = (e: any) => {
        console.log(e)
        setErrorName(e)
        if (param.type == 'Tracks') {
            directoryServices.TrackvalidationList({ "error": e }).then((res: any) => {
                setErrorListing(res);
                //checkErrorFound(res);
            })
        }

        if (param.type == 'Releases') {
            directoryServices.ReleasevalidationList({ "error": e }).then((res: any) => {
                setErrorListing(res);
            })
        }

        if (param.type == 'ReleasesTracks') {
            directoryServices.ReleaseTrackvalidationList({ "error": e }).then((res: any) => {
                setErrorListing(res);
                //checkErrorFound(res);
            })
        }

        if (param.type == 'DeaperPrice') {
            directoryServices.DealerPricevalidationList({ "error": e }).then((res: any) => {
                setErrorListing(res);
                //checkErrorFound(res);
            })
        }
    }

    const editUploader = (e: any) => {
        EditValue = e;
        setEditValue(EditValue)
        setOpenEdit(true)
    }

    const deleteUploader = (e: any) => {
        console.log(e)
        if (param.type == 'Tracks') {
            directoryServices.assetUploaderTrackDelete(e.trackUploadId).then((res: any) => { })
        }

        if (param.type == 'Releases') {
            directoryServices.assetUploaderReleaseDelete(e.releaseUploadId).then((res: any) => { })
        }

        if (param.type == 'ReleasesTracks') {
            directoryServices.assetUploaderReleaseTrackDelete(e.releaseTracksUploadId).then((res: any) => { })
        }

        if (param.type == 'DeaperPrice') {
            directoryServices.assetUploaderDealerPriceDelete(e.dealerPriceUploadID).then((res: any) => { })
        }

        validationFile();
    }

    const saveUploader = (e: any) => {
        if (param.type == 'Tracks') {
            directoryServices.assetUploaderTrackSave(EditValue).then((res: any) => { })
        }

        if (param.type == 'Releases') {
            directoryServices.assetUploaderReleaseSave(EditValue).then((res: any) => { })
        }

        if (param.type == 'ReleasesTracks') {
            directoryServices.assetUploaderReleaseTrackSave(EditValue).then((res: any) => { })
        }

        if (param.type == 'DeaperPrice') {
            directoryServices.assetUploaderDealerPriceSave(EditValue).then((res: any) => { })
        }
        validationFile();
    }

    const EditHandler = (e: any) => {
        var field_name = e.target.name;
        var field_value = e.target.value;
        setEditValue({ ...EditValue, ...{ [field_name]: field_value } });
    }

    const commitFile = () =>{

        CommonUtilities.BtnLoading("commitFile")
        console.log(param)
        if(param.type == "Bundle"){
            directoryServices.fileCommit("Bundles",null).then(()=> {CommonUtilities.RemoveBtnLoading("commitFile"); setCommitSuc(true)})
        } 
        if(param.type == "Tracks"){
            directoryServices.fileCommit("tracks",0).then(()=> {CommonUtilities.RemoveBtnLoading("commitFile"); setCommitSuc(true)})
        }        
        if(param.type == "Release"){
            directoryServices.fileCommit("release",0).then(()=> {CommonUtilities.RemoveBtnLoading("commitFile"); setCommitSuc(true)})
        }
    }
    return (  <>
        <Modal open={param.open} onClose={() => returnValue(0)} classNames={{ overlay: '', modal: 'bgTransparent allowHscroll allowVscroll'}}>

            <div className="ws-8 box pull-right allowHscroll allowVscroll"> 
                <div className="box-header-main ws-12">
                    <h2 className="pull-left ws-10"> File Validation</h2>
                    <div className="ws-1 toolbox pull-right prlxl">
                        <button className="btn-pink pull-right" onClick={validationFile}>Reset</button>
                    </div>
                </div>

                <div className="box-content">
                    <div className="table-responsive box mbl">
                        <table className="table">
                            <tbody>

                                { assetvalidation?.map((values: any) => {
                                    return (
                                        <tr>
                                            <th style={{ width: "25%" }} className="text-right ">{values.title}</th>
                                            <td style={{ width: "25%" }}>
                                                {(values.count > 0) && <span style={{ color: "red" }} onClick={() => { validationList(values.title) }}>{values.count}</span>}
                                                {(values.count == 0) && <span>{values.count}</span>}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    {(ErrorListing && (ErrorListing.length) > 0) && <div className="box mtll">
                        <div className="box-header well" style={{ width: "500px !important" }}>
                            <h2 className="pull-left ws-10">{ErrorName}</h2>
                        </div>

                        <div className="table-responsive">
                            {<div className="table-responsive allowVscroll ws-12">
                                <table className="table box">
                                    <thead>
                                        <tr className='forceSingleLine'>

                                            {directoryServices.columnUploadName?.map((values: any) => {

                                                if (values.route == param.type) {
                                                    return (
                                                        <th className='forceSingleLine'>{values.header}</th>
                                                    )
                                                }

                                            })}

                                            <th className='forceSingleLine'>Operation</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ErrorListing?.map((values: any) => {
                                            if (ErrorListing) {

                                                return (
                                                    <tr>
                                                        {directoryServices.columnUploadName?.map((value: any) => {
                                                            if (value.route == param.type) {
                                                                return (<td className='forceSingleLine'>{values[value.mapping]}</td>)
                                                            }
                                                        })}
                                                        <td width="150px">
                                                            <a onClick={() => { editUploader(values); setOpenEdit(true); }} className="btn-sm btn-success"><i className="icon-edit1"></i> </a>
                                                            <a onClick={() => { deleteUploader(values) }} className="btn-sm btn-danger"> <i className="icon-trashcan"></i> </a>
                                                        </td>
                                                    </tr>
                                                )
                                            }

                                        })}

                                        {(() => {
                                            if (openEdit == true) {
                                                return (
                                                    <tr>
                                                        { directoryServices.columnUploadName?.map((value: any) => {
                                                            if (value.route == param.type) {
                                                                return (<td className='forceSingleLine'>
                                                                    <input key={value.mapping} value={EditValue[value.mapping]} name={value.mapping} onChange={(e: any) => EditHandler(e)} /> </td>)
                                                            }
                                                        }) }
                                                        <td width="150px">
                                                            <a onClick={() => { saveUploader("") }} className="btn-sm btn-success"> Save</a>
                                                            <a onClick={() => { setOpenEdit(false) }} className="btn-sm btn-danger">Cancel </a>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        })()}

                                    </tbody>
                                </table>
                            </div>}
                        </div>
                    </div>}
                </div>

                <div className="box-footer pull-right">
                    <div className="pull-right">
                        <button className="btn btn-danger" style={{ marginLeft: "5px" }} onClick={() => returnValue(0)}><i className="icon-close"></i> Close</button>
                    </div>
                    {!commitSuc && <div className="pull-right">
                        <button className="btn btn-success buttonLoad" id="commitFile" disabled={!ErrorFound} onClick={commitFile}><i className="icon-tick"></i> Commit</button>
                    </div>}
                    {commitSuc && <div className="pull-right"> <p className="ms" > <button type="button" className="btn-primary" >View Job Status</button></p>  </div>}

                    {((param.type == 'ReleasesTracks') || (param.type == 'Releases')) && <div className="ws-2 pull-right">
                        <input className="btn pull-right" key="Master" type="checkbox" />
                        <span className="forceSingleLine ws-12">Release Code As Master</span>
                    </div>}
                </div>
            </div>

        </Modal>
    </>  )
}