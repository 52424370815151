import React, { Suspense, useState, useEffect, useRef } from "react";
import Drawer from 'react-modern-drawer';
import PeriodService from "./PeriodService";
import { format } from 'date-fns';
import { Filter } from "../../filter";

export default function PeriodSelectionForm(Param: any) {
 
    let [yearList, setYearList] = useState<any[]>([]);
    let [monthList, setMonthList] = useState<any[]>([]); 
    let [selectedYearTo, setselectedYearTo] = useState("");
    let [selectedMonthTo, setselectedMonthTo] = useState("");
    let [accountingPeriod, setAccountingPeriod] = useState(1);
    let [selectedYearFrom, setselectedYearFrom] = useState("");
    let [dateTo, setdateTo] = useState({ month: "", year: "" });
    let [selectedMonthFrom, setselectedMonthFrom] = useState("");
    let [dateFrom, setdateFrom] = useState({ month: "", year: "" })

    useEffect(() => {
        selectAccountingPeriod(accountingPeriod);          
        const element = document.querySelector('[id^="EZDrawer"]');
        element?.classList.add("full-height-vh");
    }, []);

    const selectAccountingPeriod = (e: any) => {
        yearList = [];
        monthList = [];

        PeriodService.PeriodListingSelection(e).then((res) => { 
            for (var $i = 0; $i < res.length; $i++) {

                if (e == 3) {
                    if ($i % 2 == 0) { yearList.push(res[$i]); }
                    if ($i < 2) { monthList.push(res[$i]); }
                }
                if (e == 2) {
                    if ($i % 4 == 0) { yearList.push(res[$i]); }
                    if ($i < 4) { monthList.push(res[$i]); }
                }
                if (e == 1) {
                    if ($i % 12 == 0) { yearList.push(res[$i]); }
                    if ($i < 12) { monthList.push(res[$i]); }
                }
            }

            setMonthList(monthList)
            setYearList(yearList);           

            if(!Param.periodFrom){
                setselectedMonthFrom("");
                setselectedYearFrom(""); 
                setdateFrom({ month: "", year: "" }); 
            }           
            
            if(!Param.periodTo){
                setselectedMonthTo("")
                setselectedYearTo("") 
                setdateTo({ month: "", year: "" });
            }       

            var periodName = {periodTo : ""}
            if(Param.periodFrom){  
                periodName.periodTo = Param.periodFrom;
                setSelectedDateFrom(periodName,"Month" )
                setSelectedDateFrom(periodName,"Year")                
            }
    
            if(Param.periodTo){
                periodName.periodTo = Param.periodTo;
                setSelectedDateTo(periodName,"Month" )
                setSelectedDateTo(periodName,"Year")
            }              
        })
    } 

    const setSelectedDateFrom = (data: any, type: any) => {
        if (type == "Month") {
            console.log(data.periodTo)
            var post = data?.periodTo?.substring(8, 10);
            selectedMonthFrom = data?.periodTo?.replace(post , '01');
            setselectedMonthFrom(data?.periodTo?.replace(post , '01'));
            setselectedYearFrom(selectedYearFrom);
            dateFrom.month = format(new Date(selectedMonthFrom), 'MMMM ') + '01';
        }
        if (type == "Year") {
            selectedYearFrom = data.periodTo;
            setselectedYearFrom(data.periodTo);
            setselectedMonthFrom(selectedMonthFrom);
            dateFrom.year = format(new Date(selectedYearFrom), 'yyyy');
        }
        setdateFrom(dateFrom); 
    }

    const setSelectedDateTo = (data: any, type: any) => {
        if (type == "Month") {
            selectedMonthTo = data.periodTo;
            setselectedMonthTo(data.periodTo);
            setselectedYearTo(selectedYearTo);
            dateTo.month = format(new Date(selectedMonthTo), 'MMMM dd');
        }
        if (type == "Year") {
            console.log(monthList)
            selectedYearTo = data.periodTo;
            setselectedYearTo(data.periodTo);
            setselectedMonthTo(selectedMonthTo);
            dateTo.year = format(new Date(selectedYearTo), 'yyyy');
 
            if( Number(format(new Date(selectedYearTo), 'yyyy'))  % 4 == 0){
                
                console.log( Number(monthList[1].periodDesc?.toString().substring(0,6)+"29")) 
                monthList[1].periodDesc = Number(monthList[1].periodDesc?.toString().substring(0,6)+"29")
                console.log(monthList[1].periodDesc) 
                setMonthList(monthList)
            } 
        }
        setdateTo(dateTo);
    }

    const returnDate = (e: any) => {
        var dateString = " "
        if (e.month) {
            dateString += e.month;
        }
        if (e.year) {
            dateString += " , " + e.year;
        }

        return dateString;
    }

    const confirmDate = (index: any) => {

        if (index == 1) {
            var obj = { "periodTo": dateTo, "periodFrom": dateFrom }
            Param.onChange(obj);
        }

        Param.closePeriod(true); 
    }
    
    return (<> 
        
        <Drawer open={Param.open}
            onClose={()=>confirmDate(2)}
            direction='right'
            className='react-drawer-left ws-10 allowVscroll'>

            <div className="ws-6 box pull-right full-height-vh">
                <div className="box-header-main">
                    <h2 className="ws-12">Select period(s)</h2>
                </div>

                <div className="box-content">
                    <div className="ws-12"> 
                        <div className="ws-12 mbl">
                            <div className="ws-12 pm pll mbl">
                                <ul className="nav nav-tabs m0 p0 pb pull-right">
                                    <li className={accountingPeriod == 1 ? "btn active" : "btn"} onClick={() => { setAccountingPeriod(1); selectAccountingPeriod(1) }}>Monthly</li>
                                    <li className={accountingPeriod == 2 ? "btn active" : "btn"} onClick={() => { setAccountingPeriod(2); selectAccountingPeriod(2) }}>Quarterly</li>
                                    <li className={accountingPeriod == 3 ? "btn active" : "btn"} onClick={() => { setAccountingPeriod(3); selectAccountingPeriod(3) }}>Bi-Annually</li>
                                </ul>
                            </div>

                            <div className="ws-12">
                                <div className="ws-6 prx"> 
                                    <div className="ws-12 pm pll">
                                        <span className="text-bold ws-3 pt"> From :</span>
                                        <input type="text" className="ws-8 " value={returnDate(dateFrom)} />
                                    </div>
                                </div>

                                <div className="ws-6 prx">                                                 
                                    <div className="ws-12 pm pll">
                                        <span className="text-bold ws-3 pt"> To :</span>
                                        <input type="text" className="ws-8 " value={returnDate(dateTo)} />
                                    </div>
                                </div>
                                <hr/>
                            </div>
                            
                            <div className="ws-6 prx">
                                <div className="ws-6 pll prl">
                                    <div className="text-bold">
                                        <label className="landing text-center ws-12 pm ml mb dark">Year</label>
                                    </div>
                                    <div className="ws-12">
                                        {yearList.map(post => (
                                            <a className={ post.periodFrom?.substring(0, 4)  == selectedYearFrom?.substring(0, 4) ? "ws-12 nav-horizontal mts text-center ps active" : "ws-12 nav-horizontal mts text-center ps"}
                                                onClick={() => { setSelectedDateFrom(post, "Year") }}>
                                                {format(new Date(post.periodFrom), 'yyyy')}
                                            </a>
                                        ))}
                                    </div>
                                </div>

                                <div className="ws-6 pll prl">
                                    <div className="text-bold"> <label className="landing text-center ws-12 pm ml mb dark">Month</label> </div>

                                    <div className="ws-12">
                                        {monthList.map((post: any) => (
                                            <a className={Filter.monthAndDay(post.periodFrom) == Filter.monthAndDay(selectedMonthFrom) ? "ws-12 nav-horizontal mts text-center ps active" : "ws-12 nav-horizontal mts text-center ps"}
                                                onClick={() => { setSelectedDateFrom(post, "Month") }}>
                                                {format(new Date(post.periodFrom), 'MMMM dd')}
                                            </a>
                                        ))}
                                    </div>
                                </div>

                            </div>

                            <div className="ws-6 border-left plx"> 
                                <div className="ws-6 pll prl">
                                    <div className="text-bold">
                                        <label className="landing text-center ws-12 pm ml mb dark">Year</label>
                                    </div>
                                    <div className="ws-12">
                                        {yearList.map(post => (
                                            <a className={post.periodTo?.substring(0, 4)  == selectedYearTo?.substring(0, 4)? "ws-12 nav-horizontal mts text-center ps active" : "ws-12 nav-horizontal mts text-center ps"}
                                                onClick={() => { setSelectedDateTo(post, "Year") }}>
                                                {format(new Date(post.periodTo), 'yyyy')}
                                            </a>
                                        ))}
                                    </div>
                                </div>

                                <div className="ws-6 pll prl">
                                    <div className="text-bold">
                                        <label className="landing text-center ws-12 pm ml mb dark">Month</label>
                                    </div>
                                    <div className="ws-12">
                                        {monthList.map((post: any) => (
                                            <a className={Filter.monthAndDay(post.periodTo) == Filter.monthAndDay(selectedMonthTo)  ? "ws-12 nav-horizontal mts text-center ps active" : "ws-12 nav-horizontal mts text-center ps"}
                                                onClick={() => { setSelectedDateTo(post, "Month") }}>
                                                {format(new Date(post.periodTo), 'MMMM dd')}
                                            </a>
                                        ))}

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="box-footer pull-right pLarge">
                    <button className="btn-danger" onClick={() => { confirmDate(2); }}> Close </button>
                    <button className="btn-success" onClick={() => { confirmDate(1); }}> Select </button>
                </div>
            </div>

        </Drawer>
        
    </>)
};