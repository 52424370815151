import Drawer from 'react-modern-drawer';
import { CommonUtilities } from '../../../commonUtils';
import React, { Suspense, useState, useEffect } from "react";
import ContainerServices from "../../../components/container/containerServices";
import ItemAnalysisFormatsTerritory from "../../../components/Transaction/ItemAnalysisFormatsTerritory"; 
import NewContainer from './newContainer';

export default function ContainerSlider(param: any) {

    let [Code, setCode] = useState("");
    let [panelOpen, setpanelOpen] = useState(false);
    let [ContainerCount, setContainerCount] = useState(0);
    let [newPanelOpen, setNewPanelOpen] = useState(false);
    let [ContainerFor, setContainerFor] = useState<any>([]);
    let [ContainerSelected, setContainerSelected] = useState(0);
    let [ContainerListing, setContainerListing] = useState<any>([]);
    let [ContainerSelectedList, setContainerSelectedList] = useState<any>([]);
         
    var payload = {
        projectId       : "",
        userId          : CommonUtilities.getAuthAccess().userId,
        containerArea   : "",
        KontactCode     : '',
        companyName     : '',
        assetCode       : '',
        SubProvider     : ''
    }

    useEffect(() => {
        const element = document.querySelector('[id^="EZDrawer"]');
        element?.classList.add("full-height-vh");

        if(param.type == "Creator"){ 
            var code = { KontactCode : param?.selectedDetail?.kontactCode }
            payload.KontactCode =param?.selectedDetail?.kontactCode;
            setContainerFor(code)
            console.log(param?.selectedDetail?.kontactCode)
        }
        
        if(param.type == "Imprint"){ 
            var code1 = { companyName : param?.selectedDetail?.companyName }
            payload.companyName =param?.selectedDetail?.companyName;
            setContainerFor(code1)
            console.log(param?.selectedDetail?.companyName)
        }
        if(param.type == "Asset"){ 
            var code2 = { assetCode : param?.selectedDetail?.assetCode }
            payload.assetCode =param?.selectedDetail?.assetCode;
            setContainerFor(code2)
            console.log(param?.selectedDetail?.assetCode)
        }
        if(param.type == "Account"){ 
            var code3 = { SubProvider : param?.selectedDetail?.code }
            payload.SubProvider =param?.selectedDetail?.code;
            setContainerFor(code3)
            console.log(param?.selectedDetail?.SubProvider)
        }
        apiCall();
    }, [param.open]);

    const apiCall =()=>{
        ContainerServices.filter(payload).then((res) => {
            setContainerListing(res);
            setContainerCount(res?.length);

            if(res){
                selectedtransactionRef(res[ContainerSelected]?.transaction_Ref)
            }
        })
    }

    const selectedtransactionRef = (transaction_Ref:any) =>{
        ContainerServices.filter({
            userId: CommonUtilities.getAuthAccess().userId,
            transaction_Ref: transaction_Ref
        }).then((res) => { 
            setContainerSelectedList(res);
        })
    }

    const closeContainerSlider =()=>{

    }

    const existCode = (codeName :any ,Containerobj :any) =>{ 
        //console.log( Containerobj[0]?.containerSet?.filter( (e:any)=>  e.containerType_description == codeName)  ) ;
        //console.log(codeName)        
        var exitobj = Containerobj[0]?.containerSet?.filter( (e:any)=>  e.containerType_description == codeName);
        if(exitobj?.length>0){
            return "show";
        }else{
            return "hide";
        } 
    }

    const existObjCode = (codeName :any ,Containerobj :any) =>{ 
        return Containerobj[0]?.containerSet?.filter( (e:any)=>  e.containerType_description == codeName)[0].containerData; 
    }

    const buttonAction = (event :any) =>{
        
        if(event == "Next"){
            setContainerSelected(ContainerSelected+1);
            selectedtransactionRef(ContainerListing[(ContainerSelected+1)]?.transaction_Ref);
        }

        if(event == "Prev"){
            setContainerSelected(ContainerSelected-1);
            selectedtransactionRef(ContainerListing[(ContainerSelected-1)]?.transaction_Ref);
        }
    }

    const IAFTSelected = (e:any) =>{

        var saveCodePayload = {
            assetType            :    "Code",
            containerCode        :    "",
            containerTypeid      :    1,
            transSettingsId      :    2,
            transaction_Ref      :    ""
        }    
 
        saveCodePayload.containerCode   = e.code;
        saveCodePayload.transaction_Ref = ContainerListing[ContainerSelected]?.transaction_Ref;
        var TRANSSettingsPayload         = {...ContainerSelectedList[0] ,containerType:'Performance'}

        if(Code == "AssetCode")     { saveCodePayload.containerTypeid = 1 ;TRANSSettingsPayload = {...TRANSSettingsPayload , assetCode : e.code };      }       
        if(Code == "AnalysisCode")  { saveCodePayload.containerTypeid = 2 ;TRANSSettingsPayload = {...TRANSSettingsPayload , analysisCode : e.code };    }
        if(Code == "AccountCode")   { saveCodePayload.containerTypeid = 3 ;TRANSSettingsPayload = {...TRANSSettingsPayload , accountCode : e.code };     }
        if(Code == "SubProvider")   { saveCodePayload.containerTypeid = 4 ;TRANSSettingsPayload = {...TRANSSettingsPayload , subProvider : e.code };     }
        if(Code == "Contract")      { saveCodePayload.containerTypeid = 5 ;TRANSSettingsPayload = {...TRANSSettingsPayload , contractRefNum : e.code };  }        
        if(Code == "Formats")       { saveCodePayload.containerTypeid = 6 ;TRANSSettingsPayload = {...TRANSSettingsPayload , formats : e.code };         }
        if(Code == "Territories")   { saveCodePayload.containerTypeid = 7 ;TRANSSettingsPayload = {...TRANSSettingsPayload , territory : e.code };       }  
        if(Code == "ArtistCode")    { saveCodePayload.containerTypeid = 8 ;TRANSSettingsPayload = {...TRANSSettingsPayload , artistCode : e.code };      } 

        console.log(saveCodePayload)
        console.log(TRANSSettingsPayload)
        //setpanelOpen(false);
        ContainerServices.linkContainer(saveCodePayload).then((values) => { 
            ContainerServices.linkContainerSetting(TRANSSettingsPayload).then((values) => { 
                ContainerServices.linkContainerProjectMap(TRANSSettingsPayload).then((values) => { 
                    selectedtransactionRef(ContainerListing[ContainerSelected]?.transaction_Ref) 
                })
            })             
        })

        setpanelOpen(false);
    }

    const closePage =()=>{
        setpanelOpen(false);
    }

    const ContainerClose =(event :any)=>{ 
        var paylaod = { tranRef : ContainerListing[ContainerSelected]?.transaction_Ref , action : event}
        param.onChangeClose(paylaod)
    }

    const removeTag = (tag :any) =>{
        console.log(tag)
        if(tag && tag.transContainersId) {
            ContainerServices.UnlinkContainer(tag.transContainersId).then((values) => { selectedtransactionRef(ContainerListing[ContainerSelected]?.transaction_Ref) })
        }
    }

    const closeNewPanel = (e :any) =>{
        setNewPanelOpen(false);        
        apiCall();
    }

    return (<>
        
        <Drawer open={param.open}
            onClose={() => closeContainerSlider}
            direction='right'
            className='react-drawer-left  allowVscroll allowHscroll'
            style={{ width: "100%" }}>

            <div className="box ws-6 pull-right noBorderRadius noBorder"> 
                <div className="box-header-main">
                    <h2> Container Codes - {ContainerCount} record(s)</h2>
                </div> 

                <div className='box-content'>

                    <div className='ws-12'>
                        <div className="ws-3 mb pull-right">
                            <button className="btn-success pull-right" onClick={()=>setNewPanelOpen(true)}> Add New </button>
                        </div>

                        <div className="ws-7 mb" style={{marginLeft: "16%"}}>
                            <div className="ws-3">
                                <button className="btn btn-icon btn-primary pull-left ml" onClick={()=>buttonAction("Prev")} disabled={(ContainerSelected == 0) || ContainerCount== 0}>
                                    <i className="icon-arrow-left m0"></i> 
                                </button>
                            </div>
                            <div className="ws-4 ml mt">
                                <h2>Prev/Next</h2>
                            </div>
                            <div className="ws-3">
                                <button className="btn btn-icon btn-primary mr" onClick={()=>buttonAction("Next")} disabled={(ContainerSelected == (ContainerCount-1)) || ContainerCount== 0}>
                                    <i className="icon-arrow-right m0"></i> 
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="form-format">
                        <label className="control-label ng-binding" >Transaction Reference*</label>
                        <div className="label-entries">
                            <input type="text"id="searchItem.transactionReference" value={ContainerListing[ContainerSelected]?.transaction_Ref}/>
                        </div>
                    </div>

                    {ContainerServices.containerSet?.map((values :any ,index :any) =>{
                        return (<>
                            <div className="form-format">
                                <label className="control-label text-left ws-12" >{values}</label>

                                <div className='label-entries ws-12'>

                                    <div className='w-85'>
                                        <div className='tagging-container' >                                                    
                                            <div className='tagging'>
                                                {existCode(ContainerServices.containerSetMaping[index] ,ContainerSelectedList) == "show" && <> 
                                                    {existObjCode(ContainerServices.containerSetMaping[index] ,ContainerSelectedList).map((values:any,index:any)=>{
                                                        return(  <span className="mrs mb" style={{background:CommonUtilities.chartColors[index]}}> 
                                                            {values?.containerCode} 
                                                            <i className="icon-cancel2 pls pull-right" onClick={()=>removeTag(values)}></i>
                                                        </span>  )
                                                    })}
                                                </>}

                                                {existCode(ContainerServices.containerSetMaping[index] ,ContainerSelectedList) == "hide" && <div> 
                                                    <span className="mrs" style={{background:"#C62928", color : "#ffffff"}}> ALL </span>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="pull-left">
                                        <button type="button" className="btn-success btn-sm" onClick={() => { setCode(ContainerServices.containerIAFT[index]); setpanelOpen(true);}}> <i className="icon-plus1"></i></button>
                                        <button type="button" className="btn-danger btn-sm hide" > <i className="icon-refresh"></i>  </button>
                                    </div>

                                </div>

                            </div>
                        </>)
                    })}

                </div>

                <div className="box footer prl pll ptl pbxl mbl">
                    <button className="btn-danger pull-right"  onClick={()=>ContainerClose("Close")}>Close  </button>
                    <button type="button" className="btn-danger pull-right hide"><i className="icon-refresh"></i> Reset </button>
                    <button className="btn-success pull-right" onClick={()=>ContainerClose("search")}> Search</button>
                </div>
            </div>

        </Drawer >

        {(() => {
            if (panelOpen) {
                return (<ItemAnalysisFormatsTerritory open={panelOpen} type={Code} ItemSelected={IAFTSelected} closePage={closePage} mainItemCode={ContainerFor}/>)
            }

            if (newPanelOpen) {
                return (<NewContainer open={newPanelOpen} type={Code}  closeNewPanel={closeNewPanel}/>)
            }
        })()} 
        
    </>)
}