import React, { Suspense, useState, useEffect } from "react";
import HorizontalBarChart from "../Charts/HorizontalBarChart"; 
import AreaChart from "../Charts/AreaChart"; 
import directoryServices from "./directoryServices";
import { CommonUtilities } from "../../commonUtils"; 
import Pagination from "../Header/Pagination";

export default function Fraudulent(param: any) {

    var options = [ 5 ,10 ,20,50,100 ];
    let [totalRows, setTotalRows] = useState(0);
    let [TopSelection, setTopSelection] = useState(5);    
    let [execHeader, setexecHeader] = useState<any>([]);
    let [activeMenu, setActiveMenu] = useState("Fraudulent");    
    let [DisplayType, setDisplayType] = useState("GraphSummary");
    let [DetailListing    , setDetailListing] = useState<any[]>([]);   
    let [FraudulentTrack    , setFraudulentTrack] = useState<any[]>([]);
    let [FraudulentArtist   , setFraudulentArtist] = useState<any[]>([]);
    let [FraudulentRelease  , setFraudulentRelease] = useState<any[]>([]);
    let [FraudulentCountry   , setFraudulentCountry] = useState<any[]>([]);   
    let [FraudulentReportedDate   , setFraudulentReportedDate ] = useState<any[]>([]);

    const onChangeMenu = (e: any) => {
        setActiveMenu(e)
        param.onChangeMenu(e)
    }

    useEffect(() => {
        LandingListing(DisplayType);
    }, []);

    var payload = {type: "fraudulent" ,DisplayType: DisplayType, pageNumber: 1, pageSize: 10, topSelection: 5}

    const LandingListing = (DisplayType:any) => {
        setDisplayType(DisplayType);
        payload.DisplayType = DisplayType;
        directoryServices.financialPerformance(payload).then((res) => {             
            if(DisplayType != "GraphSummary") {
                setDetailListing(CommonUtilities.relativeDataRemapping(res.data, res.headers.original));    
                execHeader = [];
                Object.entries(res.headers.display).forEach(([key, value]) => { execHeader.push(value) });
                setexecHeader(execHeader)
                console.log(execHeader)
                setTotalRows(res?.totalRows ? res.totalRows : 0)
            }

            setFraudulentCountry( (CommonUtilities.relativeDataRemapping(res.data, res.headers.original)).filter((e:any)=> e.listingName.includes('COUNTRY')) );
            setFraudulentArtist( (CommonUtilities.relativeDataRemapping(res.data, res.headers.original)).filter((e:any)=> e.listingName.includes('ARTIST')) );
            setFraudulentTrack( (CommonUtilities.relativeDataRemapping(res.data, res.headers.original)).filter((e:any)=> e.listingName.includes('ISRC')) );
            setFraudulentRelease( (CommonUtilities.relativeDataRemapping(res.data, res.headers.original)).filter((e:any)=> e.listingName.includes('UPC')) );
            setFraudulentReportedDate( (CommonUtilities.relativeDataRemapping(res.data, res.headers.original)).filter((e:any)=> e.listingName.includes('ReportedDate')) );
        })
    }    

    const CallChart = (data : any) => {

        var backgroundColor : any = [];
        var borderColor : any = [];
        var percentageData : any = [];
        var labelsData : any = [];    
    
        for (var i = 0; i < data.length; i++) {
            percentageData.push(data[i].value);
            labelsData.push(data[i].label);
            
            for (var j = 0; j < CommonUtilities.chartColors.length; j++) {
                backgroundColor.push(CommonUtilities.chartColors[j]);
            }
        }
    
        return CommonUtilities.generateChartData( percentageData, labelsData, backgroundColor, borderColor, 'Status', true );
        
    }

    const handleChangeButt = (e:any) => {
        console.log(e.target.value)
        TopSelection= e.target.value;
        payload.topSelection = TopSelection;
        setTopSelection(e.target.value)
        LandingListing(DisplayType);
    }

    const handleNameOnChange = (n: any) => {
        payload.pageNumber = n.pageNumber;
        payload.pageSize = n.pageSize;
        LandingListing(DisplayType);
    }

    const exportFraudulent = () =>{

        CommonUtilities.BtnLoading("exportFraudulent");
        directoryServices.excelGenericExport({type:"fraudulent"}).then((value :any)=>{
            if(value){ CommonUtilities.RemoveBtnLoading("exportFraudulent");}
        })
    }

    return (<>
    <div className="landing ws-12">    

        <div className="ws-12">

            <div className="ws-2 box pm hide">
                <ul className="main-menu">
                    <a className="nav-header full-width text-bold text-average mtl ws-12">Music Directories</a>
                    <ul>
                        {  directoryServices.directoryMenu?.map((value: any) => {
                            return (
                            <> {(value.route != 'accounts'  && value.route != 'fraudulent' && value.route != 'summary') && <li key={value.title} className={activeMenu == value.title ? "active" : ""}>
                                    <a className={"ws-12 "} href={"#/" + value.route} onClick={() => { onChangeMenu(value.title) }}> {value.title} </a>
                            </li>}</>
                            )
                        }) }
                    </ul>

                    <a className="nav-header full-width text-bold text-average mtl ws-12">Data Directories</a>
                    <ul>
                    {directoryServices.directoryMenu?.map((value: any) => {
                        return ( <>
                                {(value.route == 'accounts' || value.route == 'fraudulent' || value.route == 'summary') &&  <li key={value.title} className={activeMenu == value.title ? "pull-left ws-12 active" : "pull-left ws-12 "}>
                                    <a className={"ws-12 "} href={"#/" + value.route} onClick={() => { onChangeMenu(value.title) }}> {value.title} </a>
                                </li>}
                        </> )
                    })}
                    </ul>
                </ul> 
            </div>

            <div className="pll prxl pb ws-12">
                <div className="ws-10 ptxl mtl ptl pl">
                    <span className="ws-12 text-bold text-xl">Music Directories</span>
                    <span className="ws-12 ptl text-bold text-m turquoise">Fraudulent</span>
                </div>

            </div>

            <div className={DisplayType =="GraphSummary" ? "ws-12 pm":"ws-12 pm box"}>
                
                <ul className="nav nav-tabs nav-justified ws-12">
                    <li onClick={()=>{setDisplayType("GraphSummary");LandingListing("GraphSummary")}}    className={DisplayType =="GraphSummary"?"active" : "" }>Summary  </li>
                    <li onClick={()=>{setDisplayType("Listing");LandingListing("Listing")}}         className={DisplayType =="Listing"?"active" : "" }>Details  </li>
                    <button className="btn-primary pull-right hide" ng-click="importFraudulent()">Upload  </button>
                    <button className="btn-success pull-right " id="exportFraudulent" onClick={exportFraudulent}> Export </button>
                </ul>

                {FraudulentReportedDate.length > 0 && <h2 className="ws-12 mtl">Flaudulent Per Date</h2>}

                {DisplayType =="GraphSummary" && <div className="ws-12"> 
                    <div className="box mtl mbl ws-12" > 
                        <div className="ws-12 mbl pm">
                            
                            <AreaChart chartPassedData={ CallChart(FraudulentReportedDate) } style={"height: 350px; width: 900px"} /> 
                        </div>
                    </div>

                    <div className="box mbl p1 prl ws-6" > 
                        <div className="ws-12 mbl pm">
                            <span className="ws-12 text-bold pb text-center">Top 
                                {FraudulentTrack.length == 0 && <span className="p1 ml"></span>}
                                {FraudulentTrack.length > 0 && <span className="p1 ml mr">
                                    <select value={TopSelection} onChange={handleChangeButt} className="p0 heightReset mbl">
                                        {options?.map((option) => ( <option value={option}>{option}</option> ))}   
                                    </select>
                                </span>}
                            Tracks</span>
                            <HorizontalBarChart chartPassedData={ CallChart(FraudulentTrack) } style={"height: 300px; width: 600px"} /> 
                        </div>
                    </div> 

                    <div className="box mbl p1 prl ws-6" > 
                        <div className="ws-12 mbl pm">
                            <span className="ws-12 text-bold pb text-center">Top 
                                {FraudulentRelease.length == 0 && <span className="p1 ml"></span>}
                                {FraudulentRelease.length > 0 &&<span className="p1 ml mr">
                                    <select value={TopSelection} onChange={handleChangeButt} className="p0 heightReset mbl">
                                        {options?.map((option) => ( <option value={option}>{option}</option> ))}   
                                    </select>
                                </span>}
                                Release
                            </span>
                            <HorizontalBarChart chartPassedData={ CallChart(FraudulentRelease) } style={"height: 300px; width: 600px"} /> 
                        </div>
                    </div> 

                    <div className="box mbl p1 prl ws-6" > 
                        <div className="ws-12 mbl pm">
                            <span className="ws-12 text-bold pb text-center">Top 
                            {FraudulentArtist.length == 0 && <span className="p1 mll mr">   </span>}
                            {FraudulentArtist.length > 0 && <span className="p1 ml mr">
                                <select value={TopSelection} onChange={handleChangeButt} className="p0 heightReset mbl"> {options?.map((option) => ( <option value={option}>{option}</option> ))}    </select>
                            </span>}
                            Artist</span>
                            <HorizontalBarChart chartPassedData={ CallChart(FraudulentArtist) } style={"height: 300px; width: 600px"} /> 
                        </div>
                    </div> 

                    <div className="box mbl p1 prl ws-6" > 
                        <div className="ws-12 mbl pm">
                            <span className="ws-12 text-bold pb text-center">Top 
                                {FraudulentCountry.length == 0 && <span className="p1 mll mr">   </span>}
                                {FraudulentCountry.length > 0 &&<span className="p1 ml mr">
                                    <select value={TopSelection} onChange={handleChangeButt} className="p0 heightReset mbl">
                                        {options?.map((option) => ( <option value={option}>{option}</option> ))}   
                                    </select>
                                </span>} Country
                            </span>
                            <HorizontalBarChart chartPassedData={ CallChart(FraudulentCountry) } style={"height: 300px; width: 600px"} /> 
                        </div>
                    </div> 

                </div>}

                {DisplayType =="Listing" && <div className="ws-12 mtxl pll">  

                    <h2>Showing results for Flaudulent ({totalRows})</h2>

                    <div className="table-responsive_big allowVscroll pt">
                        <table>
                            <thead>
                                <tr> 
                                    { execHeader?.map((headerName :any )=>{
                                        return ( <th className="text-uppercase">{headerName}</th>)
                                    }) }                                    
                                </tr>
                            </thead>
                            <tbody>
                            {DetailListing?.map((listing :any)=>{
                                return (
                                    <tr> 
                                    {execHeader?.map((headerName :any )=>{
                                        return ( <td className="text-uppercase">{listing[headerName]}</td>)
                                    })  } 
                                    </tr>                                
                                )
                            })}

                            </tbody>
                        </table>
                    </div>

                    <div className="ws-12 mtl">
                    { (() => {
                        if (totalRows && totalRows > 10) {
                            return <Pagination onChange={handleNameOnChange} totalRows={totalRows} />
                        }
                    })()  }
                    </div>
                </div>}
                
            </div>

        </div>
    </div>
    </>)
}