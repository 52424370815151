import { CommonUtilities }  from '../../../commonUtils';

export default class assetsCompareServices  {


    public static assetsCompareMenu = [
        {
            title: 'Assets Compare',
            route: 'assetCompare'
        }/*, {
            title: 'Asset Upload',
            route: 'assetUpload'
        }*/
    ]
    
    static getAssetCompare(param :any) {
        var url = 'MainAsset/AssetComparison';
        return CommonUtilities.postData(url, param);
    }

    static exportAssetCompare(param :any) {
        var url = 'MainAsset/AssetComparison/export';
        return CommonUtilities.postData(url, param);
    }
    
}