import React, { useEffect, useState } from 'react';

export default function TooltipList({ text, children }: any) {

  const [showTooltip, setShowTooltip] = useState(false);
  const handleMouseEnter = () => { setShowTooltip(true); };
  const handleMouseLeave = () => { setShowTooltip(false); };

  return (
    <div className="tooltip-cotainer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {children}

      {showTooltip && <div className="tooltip">
        <div className='arrow' />
        {text}
      </div>}
    </div>
  );
};