import React, { useState, useEffect } from 'react';
import commonServices from "./commonServices"
import { CommonUtilities } from "../../commonUtils";
import MonitoringSlider from "./monitoringSlider"; 
import { Filter } from '../../filter';

export default function Monitoring() {

    let [Expand, setExpand] = useState(''); 
    let [sqlExec, setsqlExec] = useState('');
    let [operation, setOperation] = useState('')
    let [errorName, seterrorName] = useState('')
    let [openDetail, setOpenDetail] = useState(false)
    let [execHeader, setexecHeader] = useState<any>([]);   
    let [execListing, setexecListing] = useState<any>([]);
    let [showDownload, setShowDownload] = useState(false); 
    let [MainButton, setMainButton] = useState('ErrorSummary');  
    let [exportName, setexportName] = useState('Monitoring'); 
    let [excutionStatus, setexcutionStatus] = useState(false);
    let [monitoringListing, setmonitoringListing] = useState<any>([]); 
    let [showAdminMonitoring, setShowAdminMonitoring] = useState(false);

    let substringsToCheck = ['drop', 'insert', 'delete' , 'update']; 
    let defaultListOfClient = 
        ['BellPartners_Korr','BellDistro_Korr','BELLSEK_Korr','BellGroup_Korr','CherryRed_Korr','Ninja_Korr','Silva_Korr','LNE_Korr',
         'Splice_Korr','ArtistServe_Korr','QaNew_korr','StagingNew_Korr','UFO_Korr','Ultra_Korr','GoldenChild_Korr']; 

    useEffect(() => {
        monitoringList(MainButton);        
        if(window.location.href.indexOf('QA') > 0 || window.location.href.indexOf('artistserve') > 0 || window.location.href.indexOf('staging') > 0 || window.location.href.indexOf('localhost') > 0 ){
            setShowAdminMonitoring(true);
        }else{
            setShowAdminMonitoring(false);
        }

    }, []);

    const monitoringList = (type: any) => { 
        var payload =  {MonitoringType: type , DBNAME : Expand.length > 0 ? Expand : ""}
        commonServices.MonitorListing(payload).then((values) => { 
            console.log(CommonUtilities.relativeDataRemapping(values.data, values.headers.original)) 
            setmonitoringListing(CommonUtilities.relativeDataRemapping(values.data, values.headers.original));
        })
    } 

    const executionListing = (forE: any) => {        
        const sqlExecLower = sqlExec.toLowerCase();

        if (substringsToCheck.some(substring => sqlExecLower.includes(substring))) {
            console.log("error");
            setexcutionStatus(true);
            
        }else {
            setexcutionStatus(false);
            commonServices.MonitorListing({ MonitoringType: 'Execution', Execution: sqlExec }).then((values) => {
                setexecListing(CommonUtilities.relativeDataRemapping(values.data, values.headers.original))
                execHeader = [];
                Object.entries(values.headers.original).forEach(([key, value]) => { execHeader.push(value) });
                setexecHeader(execHeader) 
                console.log(CommonUtilities.relativeDataRemapping(values.data, values.headers.original))
            }) 
        }        

        setTimeout(function(){
            setexcutionStatus(false);
        }, 3000);
    }

    const executionListingExport = (forE: any) => {

        const sqlExecLower = sqlExec.toLowerCase();
        if (substringsToCheck.some(substring => sqlExecLower.includes(substring))) {
            console.log("error");
            setexcutionStatus(true);
        }else {
            console.log(sqlExec)
            commonServices.MonitorListingExport({ MonitoringType: 'Execution', Execution: sqlExec }, exportName ? exportName : '').then((values) => { })
        }

        setTimeout(function(){
            setexcutionStatus(false);
        }, 3000);
    }

    const CloseDetail = (event: any) => {
        console.log(event)
        setOpenDetail(!event)
    }

    const refreshSection = (value: any) => {
        console.log(value);  
        var request  = {  monitoringType: 'Refresh', monitoringDetail : value.sQLErrorName, dbname : value.sQLClient ,  MonitoringFor : value.MonitoringFor ? value.MonitoringFor : ''};
        console.log(request); 
        commonServices.MonitorListing(request).then((response) => {  monitoringList(MainButton);  })

    }

    const download =(event :any)=>{
        console.log(event)
        var payload = { MonitoringType  : event.errorType , ErrorMessage: event.errorName,  MonitoringClient: event.dBNAME, PageNumber: 1,  PageSize : 1000 , type: "errorListing", exportType: "errorListing"}
        //commonServices.excelGenericExport(payload);
    } 

    const getValue = (clientName :any , assetType : any)=>{
 
        var object : any;
        var  value : any
        if(assetType == 'Contract'|| assetType == 'MetaData' || assetType == 'Transaction'){
            object  = monitoringListing.filter((e:any)=>e.assetType == assetType && e.sQLClient ==clientName)
            value   = object && object[0] && object[0].errorObtained ?object[0].errorObtained : 0;
            console.log(object)
        }else{
            object = monitoringListing.filter((e:any)=>e.assetType == assetType.replace('Date', '') && e.sQLClient ==clientName);
            value   = object && object[0] && object[0].sQLEXECDATE ?object[0].sQLEXECDATE : false;
            console.log(object);
        }

        return value;
    }
 
    const timeZoneEnter = (e:any) =>{ 
        var returnVal = ''
        if(e.runningTime == "daily" && e.dAYDifference >  0 ){
            returnVal ='topFrameFillMagenta' 
        }

        if(e.runningTime == "weekly" && e.dAYDifference >  7 ){
            returnVal ='topFrameFillMagenta' 
        }
        
        if(e.runningTime == "monthly" && e.dAYDifference >  28 ){
            returnVal ='topFrameFillMagenta' 
        } 
        return returnVal;
    }

    return (<>

        <div className="box-header noBorderRadius bg-blue">  <h1 style={{ textAlign: "center", color: "White" }}>Monitoring</h1> </div>

        <div className="box-content">
            <div className="pLarge box ws-11">                
                {showAdminMonitoring && <div className={MainButton == 'ErrorSummary' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('ErrorSummary');  monitoringList('ErrorSummary');}}> Summary</div>}
                <div className={ MainButton == 'Summary' ? "btn-innactive" : "btn-purple" } onClick={() => { setMainButton('Summary');  monitoringList('Summary');}}> Summary Current DB</div>
                {showAdminMonitoring && <div className={MainButton == 'Contract' ? "btn-innactive" : "btn-purple"}  onClick={() => { setMainButton('Contract');  monitoringList('Contract'); }}> Contractual</div> }
                {showAdminMonitoring && <div className={MainButton == 'metadata' ? "btn-innactive" : "btn-purple"}  onClick={() => { setMainButton('metadata');  monitoringList('metadata'); }}> metadata</div> }
                {showAdminMonitoring && <div className={MainButton == 'transaction' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('transaction');  monitoringList('transaction'); }}> transaction</div> }
            </div>

            <div className='ws-1'>
                <div className='verticalMediumWide hover-box pull-right'>
                    <div className="hover-block textboxDropdownList box rightReset ptl pbl pl pr text-right borderRoundedMedium">
                        <span className='text-right text-bold text-xl text-center ws-12 '> Tiptool</span>
                        <hr></hr>

                        <div className='ws-12 pm'>
                            <div className='ws-12 pt'>
                                <div className='ws-8 box pm topFrameFillRed borderRoundedMedium'> </div>
                                <span className='text-bold forceSingleLine text-right'> Warning</span>
                            </div>

                            <div className='ws-12 pt'>
                                <div className='ws-8 box pm topFrameFillMagenta borderRoundedMedium'> </div>
                                <span className='text-bold forceSingleLine text-right'> Result-set Outdate </span>
                            </div>

                            <div className='ws-12 pt'>
                                <div className='ws-8 box pm topFrameFillGreen borderRoundedMedium'> </div>
                                <span className='text-bold forceSingleLine text-right'> No Warning </span>
                            </div>
                        </div>                                                          
                    </div>
                    <a className='btn-queue pull-right'><i className='icon-info1'></i></a>
                </div>
            </div>

            

            <div className="pLarge box ptl mtl"> 

                {MainButton == 'ErrorSummary' && <div className="ws-9 pull-left"> 
                    {defaultListOfClient.map((errorValue :any)=>{
                        return ( 
                            <div className='ws-12'>                             
                                <span className='ws-2 text-bold mtl ptl text-right prl'>{errorValue}</span>
                                <div className='ws-10'>
                                    <div className='ws-12 mbl'>
                                        <div className='ws-4 pr'> 
                                            <div className={getValue(errorValue,'Contract') ? getValue(errorValue,'Contract') > 0 ? 'ws-12 box pm topFrameFillRed borderRoundedMedium' : 'ws-12 box pm topFrameFillGreen borderRoundedMedium' : 'ws-12 box pm topFrameFillMagenta borderRoundedMedium' }>                    
                                                <div className="ws-12 ">
                                                    <span className='pull-left text-bold ws-3  pbl'>Contract</span> 
                                                    <div className='verticalMediumWide hover-box pull-right'>
                                                        <div className="hover-block textboxDropdownList box rightReset ptl pbl pl pr text-right borderRoundedMedium ws-4 ">
                                                            <span className='text-bold pull-left '>Run Date:</span>
                                                            <span className='forceSingleLine'>{getValue(errorValue,'ContractDate') ? Filter.DateTimeFormat(getValue(errorValue,'ContractDate')) : "Need To Run"}</span>                                                            
                                                        </div>
                                                        <a className='pull-right'><i className='icon-info1'></i></a>
                                                    </div>
                                                </div>
                                                <div className='ws-12'>                                                     
                                                    <span className={getValue(errorValue,'Contract') > 0? "danger" : "success"}>Warning ({getValue(errorValue,'Contract')})</span>         
                                                    <div className='pull-right'>
                                                        <button type="button" className="btn-primary btn-xxs borderRoundedMedium"><i className="icon-download1"></i> </button> 
                                                        <button type="button" className='btn-success btn-xxs borderRoundedMedium' onClick={() => {refreshSection({sQLClient: errorValue, MonitoringFor:'Contract'});}} ><i className='icon-refresh'></i>   </button>
                                                    </div>                                     
                                                </div>
                                            </div> 
                                        </div> 

                                        <div className='ws-4 pr'> 
                                            <div className={getValue(errorValue,'MetaData') ? getValue(errorValue,'MetaData') > 0 ? 'ws-12 box pm topFrameFillRed borderRoundedMedium' : 'ws-12 box pm topFrameFillGreen borderRoundedMedium' : 'ws-12 box pm topFrameFillMagenta borderRoundedMedium' }>   
                                                <div className="ws-12 ">
                                                    <span className='pull-left text-bold ws-3  pbl'>MetaData</span> 
                                                    <div className='verticalMediumWide hover-box pull-right'>
                                                        <div className="hover-block textboxDropdownList box rightReset ptl pbl pl pr text-right borderRoundedMedium ws-3 ">
                                                            <span className='text-bold pull-left '>Run Date:</span>
                                                            <span>{getValue(errorValue,'MetaDataDate') ? Filter.DateTimeFormat(getValue(errorValue,'MetaDataDate')) : "Need To Run"}</span>                                                            
                                                        </div>
                                                        <a className='pull-right'><i className='icon-info1'></i></a>
                                                    </div>
                                                </div>
                                                <div className='ws-12'>                                                     
                                                    <span className={getValue(errorValue,'MetaData') > 0? "danger" : "success"}>Warning ({getValue(errorValue,'MetaData')})</span> 
                                                    <div className='pull-right'>
                                                        <button type="button" className="btn-primary btn-xxs borderRoundedMedium"><i className="icon-download1"></i> </button> 
                                                        <button type="button" className='btn-success btn-xxs borderRoundedMedium' onClick={() => {refreshSection({sQLClient: errorValue, MonitoringFor:'MetaData'});}}><i className='icon-refresh'></i>   </button>
                                                    </div>   
                                                </div>
                                            </div> 
                                        </div>  

                                        <div className='ws-4 pr'>
                                            <div className={getValue(errorValue,'TransactionDate') ? getValue(errorValue,'Transaction') > 0 ? 'ws-12 box pm topFrameFillRed borderRoundedMedium' : 'ws-12 box pm topFrameFillGreen borderRoundedMedium' : 'ws-12 box pm topFrameFillMagenta borderRoundedMedium' }>   
                                                <div className="ws-12 ">
                                                    <span className='pull-left text-bold ws-3  pbl'>Transaction</span> 
                                                    <div className='verticalMediumWide hover-box pull-right'>
                                                        <div className="hover-block textboxDropdownList box rightReset ptl pbl pl pr text-right borderRoundedMedium ws-3 ">
                                                            <span className='text-bold pull-left '>Run Date:</span>
                                                            <span>{getValue(errorValue,'TransactionDate') ? Filter.DateTimeFormat(getValue(errorValue,'TransactionDate')) : "Need To Run"}</span>        
                                                        </div>
                                                        <a className='pull-right'><i className='icon-info1'></i></a>
                                                    </div>
                                                </div>

                                                <div className='ws-12'>  
                                                    <span className={getValue(errorValue,'Transaction') > 0? "danger" : "success"}>Warning ({getValue(errorValue,'Transaction')})</span>  
                                                    <div className='pull-right'>
                                                        <button type="button" className="btn-primary btn-xxs borderRoundedMedium"><i className="icon-download1"></i> </button> 
                                                        <button type="button" className='btn-success btn-xxs borderRoundedMedium' onClick={() => {refreshSection({sQLClient: errorValue, MonitoringFor:'Transaction'});}}><i className='icon-refresh'></i>   </button>
                                                    </div>   
                                                </div>
                                            </div> 
                                        </div>                                          

                                    </div> 
                                </div> 
                            </div>
                        )
                    })}
                </div>}

                {(MainButton == 'Summary' || MainButton == 'Contract' || MainButton == 'metadata' || MainButton == 'transaction') && <div className="ws-9 pull-left ">

                    {MainButton != 'Summary' && <div className='ws-12 pbl bg-level8 pm mbl'>
                        <select className="ws-3"  value={Expand} onChange={(e)=>{Expand=e.target.value;setExpand(Expand);monitoringList(MainButton)}}>
                            <option className="width200">Choose a client</option>

                            {defaultListOfClient.map((values:any)=>{
                                return (<option className="width200">{values}</option>)
                            })} 
                        </select>                        
                        <span className='ws-8 text-center ptl text-bold'>{Expand}</span>
                    </div>}

                    {monitoringListing.map((values: any, index : any) => { 
                        return (
                            <div className={'ws-12 box borderRoundedMedium mbl '+timeZoneEnter(values)}> 
                                <div className='pLarge ws-12'> 
                                    <span className='ws-6 text-bold'>{values.sQLErrorName}</span>
                                    <span className='ws-3 danger text-center'>Warnings( {values.sQLResultCOUNT} )</span>
                                    <div className='ws-3'>
                                        <button type="button" className="btn-success btn-xxs pull-right borderRoundedMedium" onClick={() => {errorName=values;seterrorName(errorName);setOperation("view");setOpenDetail(true); }}> <i className="icon-eye"></i> </button>
                                        <button type="button" className="btn-success btn-xxs pull-right borderRoundedMedium" onClick={() => {errorName=values;seterrorName(errorName);setOperation("edit");setOpenDetail(true); }}><i className="icon-edit"></i> </button>
                                        <button type="button" className="btn-primary btn-xxs pull-right borderRoundedMedium" onClick={() => {download(values);}}><i className="icon-download1"></i>  </button>
                                        <button type="button" className="btn-success btn-xxs pull-right borderRoundedMedium" onClick={() => {refreshSection(values) }}><i className="icon-refresh"></i>  </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>}

                <div className="ws-3 pull-right">

                    {excutionStatus && <div className="alert alert-success" > Avoid having 'drop', 'insert', 'delete' , 'update' in Excution </div>}
                    <div className="box mbl pbl mll ws-12">
                        <div className="mtl prl  pll mbl ws-12">
                            <textarea placeholder="Enter SQL" id="NoteDetails" className='mrl' style={{ width: "100%", height: "200px" }}
                                onChange={(e) => { setsqlExec(e.target.value); setShowDownload(false) } }></textarea></div>
                        <div className="ws-12 pm">
                            <div className="btn-innactive ws-3" onClick={() => executionListing(sqlExec)}>Execute</div>
                            <div className= {sqlExec.length > 0  ? 'btn-innactive ws-3 pull-right' : 'hide'} onClick={() => setShowDownload(!showDownload)}>Export</div>

                            <div className = { ( showDownload && sqlExec.length > 0 ) ? 'ws-12 mtl ptl' : 'hide' }>
                                <label className='ws-12 mb ml'>File Name</label>
                                <div className="ws-8"> <input className='ws-12 pLarge' onChange={e => { setexportName(e.target.value) }} value={exportName} placeholder={'Export FileName'} ></input></div>
                                <div className="btn-innactive ws-3 pull-right" onClick={() => executionListingExport(sqlExec)}>Download</div>

                            </div>

                        </div>

                    </div>

                </div>
            </div >

        </div >

        { (() => {
            if (openDetail) {
                return <MonitoringSlider errorName={errorName} openDetail={openDetail} operation={operation} CloseDetail={CloseDetail} />;
            }
        })() }

    </>)
}