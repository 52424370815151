import { CommonUtilities }  from '../../commonUtils';

export default class ContainerServices  {

    static containerSet         = ["Asset Code","Artist Code","Analysis Code","Account Code","Sub Provider","Contract","Format","Territory"];
    static containerSetMaping   = ["Asset Code","Artist Code","Analysis Code","Account Code","Sub Provider","Contract","Formats","Territories"];
    static containerIAFT        = ["AssetCode" ,"ArtistCode" ,"AnalysisCode" ,"AccountCode" ,"SubProvider" ,"Contract","Formats","Territories"];

    static linkContainer(data: any) {
        var url = 'royalty/kor_TRANS_Containers/save';
        return CommonUtilities.postData(url, data);
    }

    static UnlinkContainer(data: any) {
        var url = 'royalty/kor_TRANS_Containers/delete/'+data;
        return CommonUtilities.deleteData(url);
    }

    static linkContainerSetting(data: any) {
        var url = 'royalty/kor_TRANS_Settings/save';
        return CommonUtilities.postData(url, data);
    }

    static linkContainerProjectMap(data: any) {
        var url = 'royalty/kor_TRANS_ProjectMap/save';
        return CommonUtilities.postData(url, data);
    }
    
    static globalSearch(param: any) {
        var url = 'metadata/globalSearch/list?'+ CommonUtilities.buildQueryString(param, true, true);
        return CommonUtilities.getData(url);
    }

    static freeText(data :any) {
        var url = 'contracts/freeText/?'+ CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static freeTextSave(data :any) {
        var url = 'contracts/freeText';
        return CommonUtilities.postData(url , data);
    }

    static freeTextDelete(data :any) {
        var url = 'contracts/freeText/'+data;
        return CommonUtilities.deleteData(url);
    }
 
    static kontactImprintTypeSave(data :any) {
        var url = 'Contract/kontactImprintType/save';
        return CommonUtilities.postData(url , data);
    }

    static validationsSummary() {
        var url = 'contracts/financialPerformance?projectId=5&type=ProjectValidation&tabName=validationCount&subTabName=&assetType=undefined&errorName=&pageNumber=1&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static validationsContractsSummary(tabName : any) {
        var url = 'contracts/financialPerformance?projectId=4&type=ProjectValidation&tabName=' + tabName + '&subTabName=validationSummary&assetType=undefined&errorName=&pageNumber=1&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static validationsAssetsSummary() {
        var url = 'contracts/financialPerformance?projectId=4&type=ProjectValidation&tabName=Assets&subTabName=validationSummary&assetType=undefined&errorName=&pageNumber=1&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static validationsTransactionsSummary() {
        var url = 'contracts/financialPerformance?projectId=4&type=ProjectValidation&tabName=Transactions&subTabName=validationSummary&assetType=undefined&errorName=&pageNumber=1&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static contractsAssets() {
        var url = 'contracts/financialPerformance?projectId=6&type=ProjectValidation&tabName=Contracts&errorName=Contract%20with%20no%20Assets&assetType=undefined&pageNumber=1&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static assetsListing() {
        var url = 'contracts/financialPerformance?projectId=4&type=ProjectValidation&tabName=Assets&errorName=Assets%20NOT%20IN%20MAIN_Assets&assetType=undefined&pageNumber=1&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static transactionListing() {
        var url = 'contracts/financialPerformance?projectId=4&type=ProjectValidation&tabName=Transactions&errorName=Unmatched%20Format%20Code&assetType=undefined&pageNumber=1&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static PeriodCount() {
        var url = 'common/periodCount/?';
        return CommonUtilities.getData(url);
    }
    static PeriodCount2() {
        var url = 'common/periodCount/?accountingPeriod=2';
        return CommonUtilities.getData(url);
    }

    static BuildWorkSpace() {
        var url = 'contracts/financialPerformance?projectId=4&projectCode=M00004_ALL_&type=BuildWorkSpace&WorkSpace=p';
        return CommonUtilities.getData(url);
    }

    static search(data :any) {
        var url = 'contracts/financialPerformance?'+ CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static financialPerformancesearch(data :any) {
        var url = 'contracts/financialPerformance?'+ CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static accountingPeriod() {
        var url = 'common/periodCount/?accountingPeriod=1';
        return CommonUtilities.getData(url);
    }

    static accountingPeriod2() {
        var url = 'common/periodCount/?accountingPeriod=2';
        return CommonUtilities.getData(url);
    }
    
    static accountingPeriod3() {
        var url = 'common/periodCount/?accountingPeriod=3';
        return CommonUtilities.getData(url);
    }

    static filter(data :any) {
        var url = 'royalty/kor_TRANS_Settings/listSettings?'+ CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static Perfomance() {
        var url = 'contracts/financialPerformance?type=Performance&Workspace=p&level=1&projectCode=M00004_ALL_&periodTo=20240331&periodFrom=20240101&activeAllTime=1';
        return CommonUtilities.getData(url);
    }

    static Royalties() {
        var url = 'contracts/financialPerformance?projectCode=M00004_ALL_&activeAllTime=1&projectId=4&level=2&Workspace=p&type=Financial&pageNumber=1&pageSize=10&periodTo=20240331&periodFrom=20240101';
        return CommonUtilities.getData(url);
    }

}