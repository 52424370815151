import CommonSearch from "../../components/commonSearch/CommonSearch";
import React, { Suspense, useState, useEffect } from "react";
import directoryServices from "./directoryServices";
import AssetUploader from "./Uploader/assetUploader";
import BundleSlider from "./Add Slider/AddBundleSlider";
import TrackSlider from "./Add Slider/AddTrackSlider";
import SongSlider from "./Add Slider/AddSongSlider";
import ReleaseSlider from "./Add Slider/AddReleaseSlider";
import LinkAssetBundle from "./Add Slider/linkAssetBundle";
import { CommonUtilities } from '../../commonUtils';
import DirectoryAnalytic from "./Analytic/DirectoryAnalytic";
import Pagination from "../Header/Pagination";
import Summary from "./summary";
import TooltipList from "../Common/tooltip";

export default function AssetDirectory(param: any) {

    let [totalRow, settotalRow] = useState(0);
    let [uploader, setuploader] = useState(0); 
    let [viewAllList, setviewAllList] = useState("");
    let [detailView, setdetailView] = useState(false);
    let [assetLinkRow, setAssetLinkRow] = useState(0);  
    let [categoryList, setCategoryList] = useState<any[]>([]);
    let [selectedDetail, setselectedDetail] = useState<any>([]);
    let [activeListing, setActiveListing] = useState("summary");
    let [activeAssetMenu, setActiveAssetMenu] = useState("Tracks");
    let [assetLinkListing, setAssetLinkListing] = useState<any>([]);
    let [directoryViewAll, setdirectoryViewAll] = useState<any>([]);
    let [CloseUploaderOpen, setCloseUploaderOpen] = useState(false);
    let [dialogueAddOpenView, setdialogueAddOpenView] = useState(false);
    let [creatorAssetCategory, setcreatorAssetCategory] = useState<any>([]);
    let [directoryFullListing, setdirectoryFullListing] = useState<any[]>([]);
    let [associatedclassification, setassociatedclassification] = useState<any>([]); 
    let [associatedSubclassification, setassociatedSubclassification] = useState<any>([]);

    var loop = 0;
    
    var bundlePayload = { pageNumber: 1, pageSize: 10 }
    var ListingForTrack = { display: "song", iSRC: "" }
    var ListingForSong = {  display: "song", songRef: "" }
    var ListingForRelease = {  display: "song", catlogNumber: "" }
    var bundlePayLoad = {  pageNumber: 1, pageSize: 10,  bundleImprint: selectedDetail.companyName }
    var SubclassFicationPayload = { area: "Imprints",  referenceCode: "", text: "", pageNumber: 1, pagesize: 10  }
    var relatedAssetPayLoad = { CreatorCode: selectedDetail.companyName, type: "Creators", pageNumber: 1, pageSize: 10 }      
    var creatorAssetPayLoad = {assetType: "T", categoryName: "",AssetImprint: selectedDetail.companyName,display: "Category",pageNumber: 1,pageSize: 10,}
    var pagination = { AssetCode: "", AssetContactName: "", AssetTitle: "",AssetType: "T",categoryName: "", display: "category", pageNumber: 1, pageSize: 10, searchFor: ""  ,exclude : ""}

    useEffect(() => { 
        if (loop == 0) {
            LandingListing();
            console.log(loop)
            loop = loop + 1;
        }

    }, [param])

    const LandingListing = () => {
        setdirectoryViewAll([]);        
        setviewAllList("");
        directoryFullListing= [];
        setdirectoryFullListing(directoryFullListing)
        setActiveAssetMenu(activeAssetMenu)
        if (activeAssetMenu == 'Tracks') {
            pagination.AssetType = 'T'
        } else if (activeAssetMenu == 'Songs') {
            pagination.AssetType = 'S'
        } else if (activeAssetMenu == 'Releases') { pagination.AssetType = 'R' }

        if (activeAssetMenu != 'Bundle') {
            directoryServices.MainAssetListing({ ...pagination }).then((res) => {
                categoryList = []
                setCategoryList(categoryList)
                res.map((values: any) => {
                    if (values.categoryName != ' ' && (categoryList.filter((e:any)=> e == values.categoryName))?.length ==0 ){ 
                        categoryList.push(values.categoryName)
                        setCategoryList(categoryList);
                        setExclude(categoryList)    
                    }
                    if(values.categoryName == ' ' && (categoryList.filter((e:any)=> e == "Uncategorised"))?.length ==0 ){                        
                        categoryList.push("Uncategorised")
                        setCategoryList(categoryList)
                        setExclude(categoryList)
                    }
                })
                setdirectoryFullListing(res)
                settotalRow(res[0] ? res[0].totalRows : 0)

            })
        } else if (activeAssetMenu == 'Bundle') {
            directoryServices.BundleListing(bundlePayload).then((res) => {
                setdirectoryFullListing(res)
                settotalRow(res[0] ? res[0].totalRows : 0)
            })

        }
 
    }

    const SearchForHandle = (e: any, sts: any) => {
        if(activeAssetMenu == "Tracks"){
            pagination.AssetType = 'T'
        }

        if(activeAssetMenu == "Releases"){
            pagination.AssetType = 'R'
        }

        if(activeAssetMenu == "Songs"){
            pagination.AssetType = 'S'
        }

        if ((sts == true)) {
            var ARRAY_NEW :any = [];
            var payload = { ...e, ...pagination };

            if(e.searchFor){
                Object.values(e.searchFor).forEach(function (key) {
                    ARRAY_NEW.push(key)
                })
            }

            payload.searchFor = ARRAY_NEW;
            if (activeAssetMenu != 'Bundle') {
                directoryServices.MainAssetListing(payload).then((res) => { 
                    res?.map((values: any) => {
                        if (values.categoryName != ' ' && (categoryList.filter((e:any)=> e == values.categoryName))?.length ==0 ){ 
                            categoryList.push(values.categoryName)
                            setCategoryList(categoryList)
                            setExclude(categoryList)
                        }
    
                        if(values.categoryName == ' ' && (categoryList.filter((e:any)=> e == "Uncategorised"))?.length ==0 ){                        
                            categoryList.push("Uncategorised")
                            setCategoryList(categoryList)
                            setExclude(categoryList)
                        }
                    })
                    setdirectoryFullListing(res)
                    settotalRow(res[0] ? res[0].totalRows : 0);
                })
            }

            if (activeAssetMenu == 'Bundle') {
                directoryServices.BundleListing(payload).then((res) => {
                    //console.log(res)
                    setdirectoryFullListing(res)
                    settotalRow(res[0] ? res[0].totalRows : 0)

                })
            }

        } 
    }

    const viewAll = (e:any) => {
        console.log(e);

        if(activeAssetMenu == "Tracks"){ pagination.AssetType = 'T' }
        if(activeAssetMenu == "Releases"){ pagination.AssetType = 'R'  }
        if(activeAssetMenu == "Songs"){ pagination.AssetType = 'S' }

        setviewAllList(e);

        pagination.categoryName = e;
 
        directoryServices.MainAssetListing(pagination).then((res) => {            

            for(var $i =0 ; $i < res.length; $i++){
                
                if(res[$i].categoryName == e){ 
                    directoryViewAll= res[$i].categoryDetails;
                    setdirectoryViewAll(directoryViewAll);
                }
            } 
        })
    }  

    const creatorAsset = (e: any) => {

        if (e != "All") {creatorAssetPayLoad.categoryName = e;
        } else { creatorAssetPayLoad.categoryName = ""; } 

        setActiveListing("summary")
        setselectedDetail(selectedDetail);
        creatorAssetPayLoad.AssetImprint = selectedDetail.assetCode;
        bundlePayLoad.bundleImprint = selectedDetail.assetCode;
        relatedAssetPayLoad.CreatorCode = selectedDetail.assetCode;

        if (activeAssetMenu != 'Bundle') {
            directoryServices.MainAssetListing(creatorAssetPayLoad).then((res) => {  
                for (var $i = 0; $i < res.length; $i++) {

                    if (res[$i].categoryName != ' ' && e == "All" && (creatorAssetCategory.length < res[$i].length - 1)) { 
                        creatorAssetCategory.push(res[$i].categoryName)
                        setcreatorAssetCategory(creatorAssetCategory) 
                    }
                }
            })

            SubclassFicationPayload.referenceCode = selectedDetail.assetCode;
            directoryServices.tagListing(SubclassFicationPayload).then((res) => {
                setassociatedSubclassification(res)
            })

            directoryServices.musicDirectoryCategoryListing({ code: selectedDetail.assetCode, CategoryType: activeAssetMenu }).then((res) => {
                setassociatedclassification(res)
            })
        }

        if (activeAssetMenu == 'Bundle') {
            //directoryServices.BundleListing(bundlePayLoad).then((res) => {  setcreatorAssettotalRow(res[0] ? res[0].totalRows : 0);  })
            subListing("track");
        }
    }

    const onAddClose = (e: any) => { 
        if(selectedDetail){
            console.log(selectedDetail)            
            console.log(e)
            selectedDetail.assetImageTH = e.image ;           
            selectedDetail.assetImage   = e.image ;            
            selectedDetail.assetContactCode = e.artist;
            selectedDetail.assetContactName = e.artistName;
        }
        setdialogueAddOpenView(false);
    }

    const subListing = (e: any) => {
        activeListing = e;
        setActiveListing(activeListing)
        var param = {};

        if (activeAssetMenu == 'Tracks') {
            ListingForTrack.display = activeListing;
            ListingForTrack.iSRC = selectedDetail.assetCode;
            param = ListingForTrack;
        }

        if (activeAssetMenu == 'Songs') {
            ListingForSong.display = activeListing;
            ListingForSong.songRef = selectedDetail.assetCode;
            param = ListingForSong;
        }

        if (activeAssetMenu == 'Releases') {
            ListingForRelease.display = activeListing;
            ListingForRelease.catlogNumber = selectedDetail.assetCode;
            param = ListingForRelease;
        }

        if ((activeAssetMenu != 'Bundle')) {
            directoryServices.LinkAssetListing(param).then((res) => {
                setAssetLinkListing(res)
                setAssetLinkRow(res[0] ? res[0].totalRows : 0)
            })
        }

        if ((activeAssetMenu == 'Bundle')) {
            var type = ''
            if (e == 'track') {
                type = 'T';
            } else if (e == 'song') {
                type = "S";
            } else if (e == 'release') { type = "R"; }
            directoryServices.BundleAssetListing({ bundleCode: selectedDetail.bundleCode, assetType: type }).then((res) => {
                setAssetLinkListing(res)
                setAssetLinkRow(res[0] ? res[0].totalRows : 0)
            })
        };
       

    }

    const CloseUploader = (e: any) => {
        setCloseUploaderOpen(false)
        console.log("CloseUploader")
    }

    const BundleAssetUnlink = (e:any) =>{
        console.log(e)
        if(e.bundleAssetId){
            directoryServices.bundleAssetDelete(e.bundleAssetId)
        }
    }

    const paginationAllCategory =(e:any) =>{
        console.log(e);
        pagination.pageNumber   = e.pagination.pageNumber;
        pagination.pageSize     = e.pagination.pageSize;
        viewAllList             = e.categoryName
        setviewAllList(viewAllList) 

        viewAll(viewAllList);        
    }

    const exportNewTracks =(e:any) =>{
        if(e == 'ExportNewTracks'){     CommonUtilities.BtnLoading("NewTracks");}
        if(e == 'ExportAllTracks'){     CommonUtilities.BtnLoading("AllTracks");}
        if(e == 'ExportNewReleases'){   CommonUtilities.BtnLoading("NewReleases");}
        if(e == 'ExportAllReleases'){   CommonUtilities.BtnLoading("AllReleases");}
                
        directoryServices.excelGenericExport({Type : e, ExportType : e}).then((value :any)=>{
            if(e == 'ExportNewTracks'){     CommonUtilities.RemoveBtnLoading("NewTracks");}
            if(e == 'ExportAllTracks'){     CommonUtilities.RemoveBtnLoading("AllTracks");}
            if(e == 'ExportNewReleases'){   CommonUtilities.RemoveBtnLoading("NewReleases");}
            if(e == 'ExportAllReleases'){   CommonUtilities.RemoveBtnLoading("AllReleases");} 
        })
    }

    let [openInclude ,setOpenInclude] = useState(false);
    let [exclude ,setExclude] = useState<any>([]);
    const removeTabName     = (value:any)=>{ 
        console.log(value)
        console.log(exclude.filter((e:any)=> e != value) )
        exclude =  exclude.filter((e:any)=> e != value)
        setExclude(exclude); 
        splitArray(exclude)
    }

    const addTabName        = (value:any)=>{  
        if(exclude.filter((e:any)=> e == value).length ==0){ 
            exclude.push(value)
            setExclude(exclude);
            splitArray(exclude)
        }           
    }

    const splitArray =(value :any)=>{
        var string = "";
        
        console.log(exclude) 
        console.log(categoryList)

        const resultArray1 = categoryList.filter(item => !exclude.includes(item));
        console.log(resultArray1.toString())

        if(resultArray1.length > 0){
            string = resultArray1.toString();
        }

        if(exclude.length == categoryList.length){
            string = "";
        }

        if(exclude.length == 0){
            string = categoryList.toString(); 
        }

        if(exclude.length == categoryList.length){            
            pagination.exclude = "";
        }
 
        pagination.exclude = string;
        directoryServices.MainAssetListing({ ...pagination }).then((res) => { 
            setdirectoryFullListing(res)
            settotalRow(res[0] ? res[0].totalRows : 0)

        })
    }

    const bundlePagination =(e:any) =>{
        bundlePayload.pageNumber    = e.pageNumber;
        bundlePayload.pageSize      = e.pageSize;

        directoryServices.BundleListing(bundlePayload).then((res) => {
            setdirectoryFullListing(res)
            settotalRow(res[0] ? res[0].totalRows : 0)
        })
    }

    const ExportResultSet =()=>{
        console.log(pagination) 
        var payload = {...pagination, ...{exportType : "exportType" ,export:1  ,type :"DirectoryAsset" }}
        console.log(payload)
        directoryServices.excelGenericExport(payload)
    }

    return (
    <div className="landing ws-12"> 

        <div className="ws-12 plxl prxl ptl">
            <div className="ws-12 ptxl ptl">
                <div className="ws-4">
                    <span className="ws-12 text-bold text-xl">Music Directories</span>
                    <span className="ws-12 ptl text-bold text-m turquoise">Assets</span>
                </div>

                {(detailView == false) && <div className="pull-right ws-8">
                    <a className="btn btn-success pull-right m0" onClick={()=>ExportResultSet()}>Export </a>
                    {(activeAssetMenu == 'Tracks') && <>
                        <a className="btn bg-level7 white pull-right pm btn-m forceSingleLine" onClick={() => { setuploader(7); setdialogueAddOpenView(true);selectedDetail={};setselectedDetail(selectedDetail);}}>Add new Track</a>
                        <a className="btn bg-level7 white pull-right pm btn-m forceSingleLine" onClick={() => { setuploader(1); setCloseUploaderOpen(true) }}>Upload Track</a>
                    </>}

                    {(activeAssetMenu == 'Songs') && <>
                        <a className="btn bg-level7 white pull-right pm btn-m forceSingleLine" onClick={() => { setuploader(8); setdialogueAddOpenView(true);selectedDetail={};setselectedDetail(selectedDetail);}}>Add New Song</a>
                        <a className="btn bg-level7 white pull-right pm btn-m forceSingleLine" onClick={() => { setuploader(11); setCloseUploaderOpen(true) }}>Upload Song</a>
                    </> }

                    {(activeAssetMenu == 'Releases') && <>
                        <a className="btn bg-level7 white pull-right pm btn-m forceSingleLine" onClick={() => { setuploader(9); setdialogueAddOpenView(true);selectedDetail={};setselectedDetail(selectedDetail);}}>Add Release</a>
                        <a className="btn bg-level7 white pull-right pm btn-m forceSingleLine" onClick={() => { setuploader(2); setCloseUploaderOpen(true) }}>Upload Release</a>
                        <a className="btn bg-level7 white pull-right pm btn-m forceSingleLine" onClick={() => { setuploader(3); setCloseUploaderOpen(true) }}>Release/TrackUpload</a>
                        <a className="btn bg-level7 white pull-right pm btn-m forceSingleLine" onClick={() => { setuploader(4); setCloseUploaderOpen(true) }}>Upload Dealer price</a>
                    </>}

                    {(activeAssetMenu == 'Bundle') && <> 
                        <a className="btn bg-level7 white pull-right pm btn-m forceSingleLine" onClick={() => { setuploader(5); setCloseUploaderOpen(true) }}>Upload Bundle</a>
                        <a className="btn bg-level7 white pull-right pm btn-m forceSingleLine" onClick={() => { setuploader(6); setdialogueAddOpenView(true);selectedDetail={};setselectedDetail(selectedDetail);}}>Add New Bundle</a>
                    </>}
                </div>}

            </div>

            <div className="ws-12">
                {(detailView == false) && <>

                    <div className="ws-12 mtl mb">
                        <ul className="nav nav-tabs p0 m0 mtl">
                            <li key='Tracks'    className={activeAssetMenu == 'Tracks' ? "active" : ""}     onClick={() => { activeAssetMenu = ("Tracks"); LandingListing(); }}>Tracks</li>
                            <li key='Songs'     className={activeAssetMenu == 'Songs' ? "active" : ""}      onClick={() => { activeAssetMenu = ("Songs"); LandingListing() }}>Songs</li>
                            <li key='Releases'  className={activeAssetMenu == 'Releases' ? "active" : ""}   onClick={() => { activeAssetMenu = ("Releases"); LandingListing() }}>Releases</li>
                            <li key='Bundle'    className={activeAssetMenu == 'Bundle' ? "active" : ""}     onClick={() => { activeAssetMenu = ("Bundle"); LandingListing() }}>Bundle</li>
                        </ul>   

                        <div className="pull-right">
                            <ul className="nav nav-tabs p0 m0 mtl">
                                {activeAssetMenu == "Tracks"    && <li className="btn-success pull-right m0 active buttonLoad" id="NewTracks" onClick={()=>exportNewTracks("ExportNewTracks")}>Export New Tracks</li>}
                                {activeAssetMenu == "Tracks"    && <li className="btn-success pull-right active buttonLoad" id="AllTracks" onClick={()=>exportNewTracks("ExportAllTracks")}>Export All Tracks </li>}
                                {activeAssetMenu == 'Releases'  && <li className="btn-success pull-right active buttonLoad" id="NewReleases" onClick={()=>exportNewTracks("ExportNewReleases")}> Export New Releases </li>}
                                {activeAssetMenu == 'Releases'  && <li className="btn-success pull-right active buttonLoad" id="AllReleases" onClick={()=>exportNewTracks("ExportAllReleases")}> Export All Releases  </li>}
                            </ul>
                        </div>

                    </div>
 
                    <div className="ws-12"> <CommonSearch SearchForHandle={SearchForHandle} type="AssetsGrid" title="Imprints" width="ws-12 pull-left" />  </div>
                    
                    {activeAssetMenu != 'Bundle' && <div className="ws-12 box mtl mbl"> 

                        <div className="pm ws-12"> 
                            <span className="pull-left pt text-bold text-m mr mt">Classes : </span>
                            
                            {exclude?.map((list: any) => { return (
                                <>{<div className="tagging bg-level1 mr forceSingleLine">
                                    <span className="bgTransparent text-bold">{list}</span>
                                    <a className="btn-info pull-left btn-xxs borderNone bgTransparent m0" onClick={()=>removeTabName(list)}>                                        
                                        <TooltipList text={"Remove Class"}> <i className="icon-cross1 text-s mt"></i></TooltipList>
                                    </a>

                                </div>}</>
                            )} )} 
                            <a className="p0 pull-left btn-success borderNone bgTransparent m0" onClick={()=>setOpenInclude(!openInclude)}>
                                <i className="icon-plus text-xxl"></i>
                            </a>
                        </div>

                        {openInclude && <> <hr/>
                            <div className="pm ws-12"> 
                            {categoryList?.map((list: any) => { 
                                return (<>
                                {(() => { 
                                    if(exclude.filter((e:any)=> e == list).length == 0 ){
                                        return ( 
                                        <div className="tagging bg-level1 mr forceSingleLine">
                                            <span className="bgTransparent text-bold">{list}</span>
                                            <a className="btn-info pull-left btn-xxs borderNone bgTransparent" onClick={()=>addTabName(list)}><i className="icon-plus2 text-s mt"></i></a>                                                 
                                        </div>
                                        )
                                    }
                                })()} 
                                </>)
                            })} 
                            </div>
                        </>}

                    </div>}

                    <div className="ws-12 pt pb">  
                        <span className="text-bold text-m">Showing <span className="info">{totalRow}</span> results</span>    
                    </div>                

                    {(activeAssetMenu != 'Bundle') && <div className="ws-12 ptl pt pb ">

                        <div className="ws-12 pt">
                            {(directoryFullListing?.length > 0) &&<>
                                {directoryFullListing?.map((valueObj: any) => {
                                    return ( <>

                                        {(valueObj && valueObj.categoryName != "Uncategorised") && <>  
                                            {<span className="ws-12">
                                                <span className="text-bold mt">{valueObj.categoryName}</span>
                                                <span className="info text-bold pl">(Showing {valueObj?.categoryDetails?.length} out of {valueObj.categoryDetails ? valueObj.categoryDetails[0].totalSubRows : 0})</span>
                                                {((valueObj.categoryDetails && valueObj.categoryDetails[0].totalSubRows) > 10) && <a className="btn-primary pull-right bg-level15 white borderNone hide" onClick={() => { viewAll(valueObj.categoryName); setviewAllList(valueObj.categoryName) }}> View All </a>}
                                            </span>}
                                        
                                            {valueObj?.categoryDetails?.map((value: any, index: any) => {

                                                if (viewAllList != valueObj.categoryName) {
                                                    return (<>{(index < 10) && <div className="creatorImg pm pull-left">
                                                        <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (value); creatorAsset("All") }}>

                                                            <div className="full-width coverPicture">
                                                                <img className="ws-12" src={value.assetImageTH} onError= { (e : any) => { e.src='../../../../assets/images/NoReleaseImage.jpg' } } />
                                                            </div>
                                                            <div className="bottomOverlayLabel">
                                                                <label className="control-label ws-12"> 
                                                                    <TooltipList text={value.assetContactName}> {CommonUtilities.longCharacter(value.assetContactName, 15)} </TooltipList>
                                                                </label>
                                                                <label className="control-label ws-12">
                                                                    <TooltipList text={value.name}> {CommonUtilities.longCharacter(value.name, 15)} </TooltipList>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>}</> )
                                                }
                                            })}                                         
 
                                            {(() => {                                                        
                                                if (viewAllList == valueObj.categoryName && viewAllList != "Uncategorised" && directoryViewAll?.length > 0) {
                                                    return (<>{directoryViewAll?.map((values:any) => {
                                                            return (<div className="creatorImg pm pull-left">
                                                                <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (values); creatorAsset("All") }}>

                                                                    <div className="coverPicture">
                                                                        <img className="ws-12" src={values.assetImageTH} onError= { (e : any) => { e.src='../../../../assets/images/NoReleaseImage.jpg' } } />
                                                                    </div>
                                                                    <div className="bottomOverlayLabel"> 
                                                                        <label className="control-label ws-12"> 
                                                                            <TooltipList text={values.assetContactName}> {CommonUtilities.longCharacter(values.assetContactName, 15)} </TooltipList>
                                                                        </label>
                                                                        <label className="control-label ws-12">
                                                                            <TooltipList text={values.name}> {CommonUtilities.longCharacter(values.name, 15)} </TooltipList>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>)
                                                    })}</> )
                                                }
                                            })() }  
                                        
                                            {(valueObj?.categoryDetails[0]?.totalSubRows > 10) &&  <div className="ws-12">
                                                <div className="ptl">
                                                    <Pagination onChange={(e:any)=>paginationAllCategory({pagination:e , categoryName :valueObj.categoryName})} totalRows={valueObj.categoryDetails[0].totalSubRows} />                                                    
                                                </div>                           
                                            </div> }
                                        </>}
                                    </> )
                                })}

                                {directoryFullListing?.map((valueObj: any) => {
                                    return (<>
                                        {(valueObj?.categoryName == "Uncategorised") && <>
                                            {<span className="ws-12">
                                                <div className="ws-6 mt">
                                                    <span className="text-bold"> Uncategorised</span>
                                                    <span className="info text-bold"> (Showing {valueObj?.categoryDetails?.length} out of {valueObj.categoryDetails ? valueObj.categoryDetails[0].totalSubRows : 0})</span>
                                                </div>
                                                {(valueObj.categoryDetails && valueObj?.categoryDetails[0]?.totalSubRows > 10) && 
                                                    <a className="btn-primary pull-right bg-level15 white borderNone hide" onClick={() => { viewAll(valueObj.categoryName); setviewAllList(valueObj.categoryName) }}> View All </a>
                                                }
                                            </span>}                                        

                                            {valueObj?.categoryDetails.map((value: any, index: any) => {
                                                if (viewAllList != valueObj.categoryName && valueObj.categoryName == "Uncategorised") {
                                                    return (<>{(index < 10) && <div className="creatorImg pm pull-left">
                                                        <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (value); creatorAsset("All") }}>

                                                            <div className="coverPicture">
                                                                <img className="ws-12" src={value.assetImageTH} />
                                                            </div>
                                                            <div className="bottomOverlayLabel">
                                                                <label className="control-label ws-12"> 
                                                                    <TooltipList text={value.assetContactName}> {CommonUtilities.longCharacter(value.assetContactName, 15)} </TooltipList>
                                                                </label>
                                                                <label className="control-label ws-12"> 
                                                                    <TooltipList text={value.name}> {CommonUtilities.longCharacter(value.name, 15)} </TooltipList>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>}</>)
                                                } 
                                            })}

                                            {(() => {
                                                        
                                                if (viewAllList == "Uncategorised" && directoryViewAll?.length > 0 && valueObj.categoryName == "Uncategorised") {
                                                    return (<>{directoryViewAll?.map((values:any) => {
                                                            return (<div className="creatorImg pm pull-left">
                                                                <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (values); creatorAsset("All") }}>

                                                                    <div className="coverPicture">
                                                                        <img className="ws-12" src={values.assetImageTH} onError= { (e : any) => { e.src='../../../../assets/images/NoReleaseImage.jpg' } } />
                                                                    </div>
                                                                    <div className="bottomOverlayLabel">  
                                                                        <label className="control-label ws-12"> 
                                                                            <TooltipList text={values.assetContactName}> {CommonUtilities.longCharacter(values.assetContactName, 15)} </TooltipList>
                                                                        </label>
                                                                        <label className="control-label ws-12"> 
                                                                            <TooltipList text={values.name}> {CommonUtilities.longCharacter(values.name, 15)} </TooltipList>
                                                                        </label>
                                                                    
                                                                    </div>
                                                                </div>
                                                            </div>)
                                                    })}</> )
                                                }
                                            })() }  
                                            
                                            {(valueObj?.categoryDetails && valueObj?.categoryDetails[0]?.totalSubRows > 10) && <div className="ws-12">
                                                <div className="ptl">
                                                    <Pagination onChange={(e:any)=>paginationAllCategory({pagination:e , categoryName :valueObj.categoryName})} totalRows={valueObj.categoryDetails[0].totalSubRows} />
                                                </div>                                               
                                            </div>}
                                        </>}
                                    </> )
                                })}
                            </>}

                        </div>

                    </div>}

                    {(activeAssetMenu == 'Bundle') && <div className="ws-12 landing">{
                        directoryFullListing?.map((values: any) => {
                            return (
                            <div className="creatorImg pm pull-left">

                                <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (values);setselectedDetail(values); creatorAsset("All") }}>

                                    <div className="coverPicture">
                                        <img className="ws-12" src={values.bundleImage} onError= { (e : any) => { e.src='../../../../assets/images/NoReleaseImage.jpg' } } />
                                    </div>
                                    <div className="bottomOverlayLabel">
                                        <label className="control-label ws-12"> {CommonUtilities.longCharacter(values.bundleTitle,10)}</label>
                                        <label className="control-label ws-12"> {values.bundleCode} </label>
                                    </div>
                                </div>
                            </div>
                            )
                        })
                    }
                    <div className="ws-12">
                    {(() => {
                        if (totalRow && totalRow > 10) {
                            return <Pagination onChange={bundlePagination} totalRows={totalRow} />
                        }
                    })()}  
                    </div>
                    </div>}

                </>}

                {(detailView == true) && <>
                    <div className="ws-12 ptxl pl">
                        <button className="btn-primary btn-sm mrl" onClick={() => {setdetailView(false);selectedDetail=[];setselectedDetail([])}}>
                            <i className="icon-angle-left"></i>
                        </button>

                        <div className="mt">
                            <span className="text-bold pt" style={{ textDecoration: "underline" }}> Assets directory</span>
                            {(activeAssetMenu != 'Bundle') && <span className="text-bold pt"> {">"} {selectedDetail.name}</span>}
                            {(activeAssetMenu == 'Bundle') && <span className="text-bold pt"> {">"} {selectedDetail.bundleTitle}</span>}
                        </div>
                    </div>
                    <hr/>
                    <div className="pl ws-12 landing pt">
                        <div className="box prl mrl">
                            <div className="ws-2 pll box landing ptl prl">

                                {(activeAssetMenu != 'Bundle') && <div className="ws-11 text-sl text-bold mbs">{selectedDetail.name}</div>}
                                {(activeAssetMenu == 'Bundle') && <div className="ws-11 text-sl text-bold mbs">{selectedDetail.bundleTitle}</div> }
                                {(activeAssetMenu != 'Bundle') && <><hr className="ws-11" /><div className="ws-11 mbs "> <span className="text-bold">{selectedDetail.assetContactName}</span> </div></>}

                                <hr className="ws-11 mbs" />
                                <div className="ws-12 mbs mt">
                                    {(activeAssetMenu != 'Bundle') && <span className="btn-xs btn-grey text-xs text-bold purple text-uppercase forceSingleLine m0 pl pr ws-5 text-center">{CommonUtilities.removeLastCharacter(activeAssetMenu)} code</span>}
                                    {(activeAssetMenu == 'Bundle') && <span className="btn-xs btn-grey text-xs text-bold purple text-uppercase forceSingleLine m0 pl pr ws-5 text-center">Bundle code</span>}
                                    {(activeAssetMenu != 'Bundle') && <span className="ws-6 mts forceSingleLine">{selectedDetail.assetCode}</span>}
                                    {(activeAssetMenu == 'Bundle') && <span className="ws-5 mts forceSingleLine">{selectedDetail.bundleCode}</span>}
                                </div>

                                {(activeAssetMenu != 'Bundle') && <><hr className="ws-11" />
                                    <div className="ws-11 mbs">
                                        <span className="pull-left text-bold mr">Classification: </span>
                                        {associatedclassification?.map((values: any) => {
                                            return ( <span className="pull-left pr">{values.categoryName}</span>)
                                        })}
                                </div></>}

                                {(activeAssetMenu != 'Bundle') && <><hr className="ws-11" />
                                    <div className="ws-11 mbs">
                                        <span className="pull-left text-bold mr">Sub classification: </span>
                                        {associatedSubclassification?.map((values: any) => {
                                            return (<span className="pull-left pr">{values.categoryName}</span>)
                                        })}
                                    </div>
                                <hr className="ws-11" /></>}

                                <div className="ws-12 mtl pr">
                                    <div className="box shadow mb" style={{width: "100%",height: "200px"}}>
                                        <div className="coverPicture" style={{height: "100%"}}>
                                            {(activeAssetMenu != 'Bundle') && <img className="ws-12" src={selectedDetail.assetImage} onError= { (e : any) => { e.src='../../../../assets/images/NoReleaseImage.jpg' } } />}
                                            {(activeAssetMenu == 'Bundle') && <img className="ws-12" src={selectedDetail.bundleImage} onError= { (e : any) => { e.src='../../../../assets/images/NoReleaseImage.jpg' } } /> }
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="ws-10">
                                <div className="pll ptl ws-12">
                                    <>
                                    <ul className="ws-11 nav nav-tabs p0 m0">
                                        {(activeAssetMenu != 'Bundle')      && <li onClick={()=>{setActiveListing("summary")}} className={activeListing == "summary" ? "active" : ""} >Summary</li> }
                                        {(activeAssetMenu != 'Bundle')      && <li onClick={()=>{setActiveListing("analytics")}} className={activeListing == "analytics" ? "active" : ""} >Analytics</li> }
                                        {(activeAssetMenu != 'Tracks')      && <li onClick={() => { subListing("track") }} className={activeListing == "track" ? "active" : ""}>Track Listing </li>}
                                        {(activeAssetMenu != 'Songs')       && <li onClick={() => { subListing("song") }} className={activeListing == "song" ? "active" : ""}> Song Listing</li>}
                                        {(activeAssetMenu != 'Releases')    && <li onClick={() => { subListing("release") }} className={activeListing == "release" ? "active" : ""}>Release Listing </li>}
                                    </ul>

                                    <div className="verticalMediumWide hover-box">
                                        <ul className="hover-block textboxDropdownList box pLarge defaultColor rightReset ws-3">

                                            {(activeAssetMenu == 'Songs') && <li className="ws-12 pbs">
                                                <a onClick={() => { setuploader(8); setdialogueAddOpenView(true) }}>Edit Profile</a>
                                            </li>}

                                            {(activeAssetMenu == 'Tracks') && <li className="ws-12 pbs">
                                                <a onClick={() => { setuploader(7); setdialogueAddOpenView(true) }}>Edit Profile</a>
                                            </li>}

                                            {(activeAssetMenu == 'Releases') && <li className="ws-12 pbs">
                                                <a onClick={() => { setuploader(9); setdialogueAddOpenView(true) }}>Edit Profile</a>
                                            </li>}

                                            {(activeAssetMenu == 'Bundle') && <li className="ws-12 pbs">
                                                <a onClick={() => { setuploader(10); setdialogueAddOpenView(true) }}>Add Asset</a>
                                            </li>}
                                            
                                            {(activeAssetMenu == 'Bundle') && <li className="ws-12 pbs"> 
                                                <a onClick={() => { setuploader(6); setdialogueAddOpenView(true);setselectedDetail(selectedDetail) }}>Edit Bundle</a>
                                            </li>}

                                            
                                            {(activeAssetMenu == 'Bundle') && false && <li className="ws-12 pbs">
                                                <a onClick={() => { setuploader(10); setdialogueAddOpenView(true) }}>Add Asset</a>
                                            </li>}

                                        </ul>
                                        <button className="btn btn-info pull-right">
                                            <i className="icon-grid m0 p0"></i>
                                        </button>
                                    </div>
                                    </>

                                    {(activeListing != "analytics")&& (activeListing != "summary") && <div className="ws-11" >

                                        {(assetLinkRow > 0) && <>
                                            <div className="ws-12 pt">
                                                {activeListing == "track" &&    <h2 className="ws-5">Track title</h2>}
                                                {activeListing == "song" &&     <h2 className="ws-5">Song title</h2>}
                                                {activeListing == "release" &&  <h2 className="ws-5">Release title</h2>}
                                                {<h1 className="ws-6">Artist</h1>}
                                                <hr className="ws-12  m0 p0" />
                                            </div>

                                            {assetLinkListing?.map((values: any) => {

                                                return (
                                                <div className="ws-12 pt mt"> 
                                                    <span className="ws-5 pr ml pl pt">{values.name}</span>
                                                    <span className="ws-5 pt">{values.assetContactName}</span>
                                                    {(activeAssetMenu == 'Bundle') && <div className="ws-1 pull-right">
                                                        <span className="btn-success btn btn-xs hide"><i className="icon-edit1"></i></span>
                                                        <span className="btn-danger btn btn-xs" onClick={()=>BundleAssetUnlink(values)}><i className="icon-trashcan"></i></span>
                                                    </div>}
                                                    <hr className="ws-12" />
                                                </div>
                                                )
                                            })}
                                        </>}

                                        {(assetLinkRow == 0) && <div className="ws-12 mtl">
                                            <span className="alert alert-success ws-12 text-center">No data Available</span>
                                        </div>}
                                    </div>}

                                    {(activeListing == "analytics") && <div className="ws-12 mtxl"> <DirectoryAnalytic selectedDetail={selectedDetail} DAOpen= {activeListing == "analytics"?"Asset":"Close"}/> </div>}
                                    {(activeListing == "summary") && <div className="ws-12 mtxl"> <Summary type="Asset" code={{"AssetID":selectedDetail.assetCode}}/></div>}

                                </div>
                            </div>

                        </div>
                    </div>
                </>}
            </div>

        </div>

        { (() => {

            if (uploader == 1) {
                return <AssetUploader open={CloseUploaderOpen} type={"Tracks"} CloseUploader={CloseUploader} />
            }

            if (uploader == 2) {
                return <AssetUploader open={CloseUploaderOpen} type={"Releases"} CloseUploader={CloseUploader} />
            }

            if (uploader == 3) {
                return <AssetUploader open={CloseUploaderOpen} type={"ReleasesTracks"} CloseUploader={CloseUploader} />
            }

            if (uploader == 4) {
                return <AssetUploader open={CloseUploaderOpen} type={"DeaperPrice"} CloseUploader={CloseUploader} />
            }

            if (uploader == 5) {
                return <AssetUploader open={CloseUploaderOpen} type={"Bundle"} CloseUploader={CloseUploader} />
            }

            if (uploader == 6) {
                return <BundleSlider open={dialogueAddOpenView} editData={selectedDetail} onAddClose={onAddClose} CloseUploader={CloseUploader} />
            }

            if (uploader == 7) {
                return <TrackSlider open={dialogueAddOpenView} editData={selectedDetail} onAddClose={onAddClose} CloseUploader={CloseUploader} />
            }

            if (uploader == 8) {
                return <SongSlider open={dialogueAddOpenView} editData={selectedDetail} onAddClose={onAddClose} CloseUploader={CloseUploader} />
            }

            if (uploader == 9) {
                return <ReleaseSlider open={dialogueAddOpenView} editData={selectedDetail} onAddClose={onAddClose} CloseUploader={CloseUploader} />
            }

            if (uploader == 10) {
                return <LinkAssetBundle open={dialogueAddOpenView} editData={selectedDetail} onAddClose={onAddClose} CloseUploader={CloseUploader} activeListing={activeListing}/>
            }

            if (uploader == 11) {
                return <AssetUploader open={CloseUploaderOpen} type={"Songs"} CloseUploader={CloseUploader} />
            }

        })()}
    </div>
    )
}   