
import React, { Suspense, useEffect, useState } from 'react';
import Login from './components/User/Login';
import IndexDashboard from './components/Dashboard/indexDashboard';
import MobileApp from "./components/MobileApp/MobileApp";
import {BrowserRouter, Routes, Route } from 'react-router-dom';
import 'react-responsive-modal/styles.css';
import Monitoring from './components/Common/monitor';
import DirectoriesIndex from './components/directories/standaloneDirectories';
import ContractGrid from './components/gridTemplate/contractGrid';
import ProjectGrid from './components/gridTemplate/projectGrid';
import ContactGrid from './components/gridTemplate/contactGrid';
import DataMGT from './components/Transaction/DataMGT';

declare global { var period : any ; var selectedContact : any ; var portalLogo : any ;var periodSelected: any;
  var periodCount:any ; var currectPeriod:any ;var lookUpTypeList:any ; var tagColor:any;var ClientDSP :any ;
  var systemFormatsList:any; var containers: any; var themeCustomization : any;var loadlabelList : any;
  var clientDetail : any;
}

function App() {

  if(window.sessionStorage.getItem('loggedUserRight')){

    return (
  
      <BrowserRouter basename='/'>        
        <Routes>
          <Route path='/'               element={<IndexDashboard  />} />
          <Route path='/directories/'   element={<IndexDashboard  />} />
          <Route path='/mobileApp'      element={<MobileApp       />} />
          <Route path='/login'          element={<IndexDashboard  />} />
          <Route path='/monitoring'     element={<Monitoring      />} />
          <Route path='/landing/*'      element={<IndexDashboard  />} />

          {/*<Route path='/apps/contracts/' element={<ContractGrid />} />
          <Route path='/apps/projects/' element={<ProjectGrid />} />
          <Route path='/apps/contacts/' element={<ContactGrid />} />
          <Route path='/apps/salesAnalysis/' element={<DataMGT />} /> */}
        </Routes>
      </BrowserRouter>
      
    );
  }else{
    return (<Login/>); 
  }
}

export default App;