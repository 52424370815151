import React, { Suspense, useEffect, useState } from 'react';
import Header from '../Header/header';
import { CommonUtilities } from '../../commonUtils';
import PeriodService from '../../components/PeriodSelection/PeriodService';
import globalSearchService from '../commonSearch/globalSearchServices';
import dashboardService from './dashboardServices';
import commonServices from '../../components/Common/commonServices';

const Dashboard = React.lazy(() => import("./dashboard"));
const Statements = React.lazy(() => import("./Statements"));
const ProjectContracts = React.lazy(() => import("../Dashboard/projectContracts"));


export default function IndexDashboard(autoOpen: any) {

    //console.log(autoOpen.autoOpen);  

    const [flag, setFlag] = useState(true);
    const [flagList, setFlagList] = useState(true);
    const [showTab, setShowTab] = useState(0);
    const [pageNumber, setPageNumber] = useState("");
    var userID = CommonUtilities.getAuthAccess().email;
    var [selectedMonth, setSelectedMonth] = React.useState('2010-01-31T00:00:00');

    console.log(window.location.pathname);
    useEffect(() => {
        periodListing();
        periodData();
        lookUpTypeList();
        systemFormatsList();
        themeCustomization();
        loadlabelList();
        loadTheme();
        getClientDSP();

    }, []);

    const periodData = () => {
        PeriodService.PeriodCount().then((res) => {
            global.periodCount = res;

            for (var $i = 0; $i < res.length && flag == true; $i++) {
                if (res[$i].periodCurrent == 1) {
                    global.currectPeriod = res[$i].periodDesc
                    global.periodSelected = res[$i]
                    setFlag(false);
                    //currectPeriod = global.period[$i].periodTo;
                    setSelectedMonth(global.currectPeriod);
                }
            }
        });

        dashboardService.tagColor().then((res) => {
            global.tagColor = res;
        });

    }

    const getClientDSP =()=>{
        
        dashboardService.getClientDSP().then((res) => {
            global.ClientDSP = res;
        });
    }

    const periodListing = () => {
        PeriodService.PeriodListing(userID).then((res) => {
            global.period = res;
            for (var $i = 0; $i < global.period?.length; $i++) {
                if (global.period[$i].periodCurrent == 1) {
                    //currectPeriod = global.period[$i].periodTo;
                    setFlagList(false);
                    setSelectedMonth(global.period[$i].periodTo);
                }
            }
        });
    }

    const onChangePage = (e: any) => {
        setPageNumber(e)
        setShowTab(2)
    }

    const lookUpTypeList = () => {
        globalSearchService.lookUpTypeList().then((res) => {
            global.lookUpTypeList = res;
        });
    }

    const systemFormatsList = () => {
        globalSearchService.systemFormatsList() .then((res) => {
            global.systemFormatsList = res;
        });

    }

    const loadlabelList = () => {
        globalSearchService.loadlabelList().then((res) => {
            global.loadlabelList = res;
        });

    }

    const themeCustomization = () => {
        dashboardService.themeCustomization().then((res) => {
            const styles = {
                main: {
                    backgroundColor: "#f1f1f1",
                    width: "100%",
                },
                inputText: {
                    padding: "10px",
                    color: "red",
                },
            };

            global.themeCustomization = styles;
                
        });
    }
    
    const loadTheme = () => {
        commonServices.loadTheme().then((res) => {

            var themeBuild = '';
            
            for(var i = 0; i < res.length; i++ ){
                console.log(i);

                const styleElement = document.createElement('style');
                styleElement.innerHTML = res[i].className+ '{ '+ res[i].attribute + ' : ' + res[i].value + ' !important ;} ';
                document.head.appendChild(styleElement);
            }
            console.log(themeBuild);

        });
    }

    return (
        <div className={"landingPage ws-12 box"}>
            
            <div className='ws-12 bgWhite'>
                <Header Title={"Dashboard"} />
            </div>

            <div className={showTab == 0 ? "ws-12" : "hide"}>
                <div className="ws-4">
                    <Suspense fallback={<div>Loading</div>}>
                        <Statements></Statements>
                    </Suspense>
                </div>

                <div className="ws-4 mtl bg-white">
                    <Suspense fallback={<div>Loading</div>}>
                        <Dashboard/>
                    </Suspense>
                </div>

                <div className="ws-4">
                    <Suspense fallback={<div>Loading</div>}>
                        <ProjectContracts />
                    </Suspense>
                </div>
            </div>



        </div>
    )
}