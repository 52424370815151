import { CommonUtilities } from '../../commonUtils';
import React, { Suspense, useEffect, useState } from 'react';
import DirectoriesIndex from '../directories/directoriesIndex';
import AssetCompareIndex from '../MetaData/Assets/AssetCompareIndex';

export default function Dashboard (params:any) {
 
    var userAccessContents = JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string);
    let [portalName , setPortalName] = useState("") 

    const selectSubEntity = (SubEntity:any)=>{        
        console.log(SubEntity)    
        console.log(CommonUtilities.iconUrlAssignment(SubEntity.subEntity)) 
        if(SubEntity.subEntityType == 0){
            if(CommonUtilities.iconUrlAssignment(SubEntity.subEntity)){
                const iconUrl = CommonUtilities?.iconUrlAssignment(SubEntity.subEntity) || '';
                if (iconUrl.includes('#')) { 
                    window.location.href = iconUrl;
                } else { 
                    window.location.href  = iconUrl;
                } 
            }
        }
/*
        if(SubEntity.subEntityType == 1 && SubEntity.subEntity == "Directories"){  
            portalName = "/directories/"
            setPortalName(portalName) 
        }
*/
        if(SubEntity.subEntityType == 1 ){ // && SubEntity.subEntity == "Directories"){  
            //portalName = "/directories/"
            console.log(SubEntity.subEntity);
            setPortalName(SubEntity.subEntity); 
        }
    }
    
    return (

        <div className='ws-12'>
             
            {userAccessContents.userEntityRight?.map((entrieObj :any)=>{ 
                return (
                    <div className='box mb'>
                        <div className='box-header bg-level0'> {entrieObj.entityName}  </div>
                        
                        <div className='box-content'> 
                        {entrieObj.subEntities.map((SubentrieObj :any)=>{
                        return (
                            <div className="box-primary text-center iconLogo pull-left">
                                {<a onClick={()=>selectSubEntity(SubentrieObj)}>
                                    <div className="ws-12">
                                        <i className={'box icon-' + SubentrieObj.subEntityCode} />
                                        <span className="m0 pts" >{SubentrieObj.subEntity} </span>
                                    </div>
                                </a>}
                            </div>
                        )
                        }) }
                        </div>
                    </div>
                )

            } )}

            {(() => {
                if (portalName != '' && portalName == "Directories") {
                    return <DirectoriesIndex pathname={'/directories/'}/> 
                }

                if (portalName != '' && portalName == "Asset Compare") {
                    return <AssetCompareIndex pathname={'/assetCompare/'}/> 
                }

            })() }

        </div>   
    );
}
