import React, { Suspense, useState, useEffect } from "react"; 
import 'react-responsive-modal/styles.css';
import assetsCompareServices from './assetsCompareServices';
import korrectLogo from '../../../assets/images/korrectLogoIcon.png';
import AssetCompare from "./AssetCompare";

export default function AssetCompareIndex(subEntity: any) {
    
    let [showtab, setShowTab] = useState(false);
    let [activeMenu, setActiveMenu] = useState("Assets Compare");

    useEffect(() => { 
        openModal(true);
    }, [subEntity]);    
    
    const onChangeMenu = (e: any) => {
        console.log(e)
        setActiveMenu(e)
    }

    const openModal = (e:any) =>{
        setShowTab(true);
        const modal = document.getElementById("myModal") as HTMLElement;         
        modal?.classList.remove("displayNoneItem");
        modal?.classList.add("displayItem"); 
        document.body.classList.add("HiddenoverflowX");   
    }

    const closeModal = (e:any) =>{ 
        setShowTab(false);
        const modal = document.getElementById("myModal") as HTMLElement; 
        modal?.classList.remove("displayItem"); 
        modal?.classList.add("displayNoneItem"); 
        document.body.classList.remove("HiddenoverflowX");
    }

    return (<> 

        {(showtab) &&  <div id="myModal" className="modal pLarge ws-12 ">                 
            
            <a onClick={()=>closeModal(true)} className="btn-danger btn-xxs modalClose borderNone bgTransparent" style={{position : "inherit"}} href="../../">
                <i className="icon-close3"></i>
            </a> 

            <div  className="allowHscroll allowVscroll full-height-vh90 ws-12 box" style={{minHeight:"80%"}}>
                
                

            <div className="ws-12 ">
                    <div className="ws-2 pm borderReset full-height pLarge">
                        <div className="ws-12 text-center pLarge mtll mbl">
                            <img className="pull-none" src={korrectLogo} width={50} />
                        </div>

                        <ul className="main-menu pll prl ws-12">
                            <a className="full-width text-bold text-average text-m mtl ws-12 pbl">Asset Management</a>
                            <ul className="">
                            {assetsCompareServices.assetsCompareMenu?.map((value: any) => {
                                return (<>
                                {<li key={value.title} className={activeMenu == value.title ? "active" : ""}>
                                    <a className={"ws-12 text-sl text-normal"} href={"#/" + value.route} onClick={() => { onChangeMenu(value.title) }}> {value.title} </a>
                                </li>}
                                </>)
                            })}
                            </ul>
                            
                        </ul> 
                    </div>

                    <div className="ws-10 landing">          
                       
                        {(activeMenu == 'Assets Compare')         && <AssetCompare  />}


                    </div>  
                </div> 


               
            </div>                                    
            
        </div>}
    </>)
}