
import React, { useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import Pagination from '../../../components/Header/Pagination'; 
import directoryServices from '../directoryServices';
import { ContactFormSearch } from '../../Contacts/contactFormSearch';

export default function AddCreator(param: any) {

    let [associatedSubclassification, setassociatedSubclassification] = useState<any>([]);
    let [associatedclassification, setassociatedclassification] = useState<any>([]);
    let [SubClassificationtotalRow, setSubClassificationtotalRow] = useState(1);
    let [listSubClassification, setlistSubClassification] = useState<any>([]);
    let [SubClassificationSearch, setSubClassificationSearch] = useState("");
    let [ClassificationtotalRow, setClassificationtotalRow] = useState(1);
    let [listClassification, setlistClassification] = useState<any>([]);
    let [dialogueAddOpenView, setdialogueAddOpenView] = useState(false);
    let [ClassificationSearch, setClassificationSearch] = useState("");
    let [selectContact, setselectContact] = useState<any>([]);
    let [kontactDisplay, setkontactDisplay] = useState("");
    let [selectedFile, setSelectedFile] = useState<any>(null); 
    let [saveStatus, setSaveStatus] = useState(false);

    var SubclassFicationPayload = { area: "ContactType",  referenceCode: "", text: "",  pageNumber: 1, pagesize: 10 }
    let classFicationPayload = {formArea: "Creators",pageNumber: 1, pagesize: 10,  useFreeText: ""}

    var pickerColors = [
        '#EF534F',  '#C62928',   '#980A0B',
        '#BA69C8', '#7B1FA2',   '#4A148C',
        '#F57C02', '#E65100',   '#81C784', 
        '#2F7D31', '#1A5E20',   '#7FDEEA', 
        '#00838F', '#004D41',   '#90CAF9', 
        '#029BE5', '#01579B',
        '#DBDBDB', '#000000'
    ];


    const closePage = () => { param.onChangeClose(selectContact);  }

    const onChangeContact = (e: any) => {
        console.log(e) 
 
        setdialogueAddOpenView(false)
        setkontactDisplay(e?.kontactCode ? e?.kontactDisplay + ' (' + e?.kontactCode + ')' : '' );
        selectContact= e;
        setselectContact(selectContact)
 
        loadListClassification();
    }

    const setCreator = () => {
        setselectContact((prevState: any) => ({
            ...prevState,
            creator: !prevState.creator
        }));
    }

    const handleFileChange = (e: any) => {
        var fileName = e.target.files[0];
        const file = e.target.files && e.target.files[0];
        if (e.target.files) {
            setSelectedFile(file);
            fileName = URL.createObjectURL(fileName);
            setselectContact({ ...selectContact, ...{ creatorImage: fileName } });
        }

    }

    const ClassificationListing = () => {
        if (ClassificationSearch.length > 1) {
            classFicationPayload.useFreeText = ClassificationSearch;
        }
        directoryServices.zFreeTextListing(classFicationPayload).then((res) => {
            setlistClassification(res)
            setClassificationtotalRow(res[0] ? res[0].totalRows : 0)
        })
    }

    const ClassificationSave = (e: any) => {
        console.log(e);
        if (e != false) {

            directoryServices.musicDirectoryCategorySave({ Code: selectContact.kontactCode, categoryName: e, categoryType: "Creators" });
        } else {
            directoryServices.zFreeTextsave({ formArea: "Creators", useFreeText: ClassificationSearch });
            directoryServices.musicDirectoryCategorySave({ Code: selectContact.kontactCode, categoryName: ClassificationSearch, categoryType: "Creators" });

        }
        loadListClassification();

    }

    const SubClassificationListing = () => {

        if (SubClassificationSearch.length > 1) {
            SubclassFicationPayload.text = SubClassificationSearch;
        }
        directoryServices.tagListing(SubclassFicationPayload).then((res) => {
            setlistSubClassification(res)
            setSubClassificationtotalRow(res[0] ? res[0].totalRows : 0)
        })

    }

    const SubClassificationSave = (e: any) => {
        console.log(e);
        if (e != false) {
            directoryServices.tagSave({ area: "ContactType", code: e, id: 0, isActive: 1, isCustom: 1, referenceCode: selectContact.kontactCode, text: e });
        } else {
            directoryServices.tagSave({ area: "ContactType", code: SubClassificationSearch, id: 0, isActive: 1, isCustom: 1, referenceCode: selectContact.kontactCode, text: SubClassificationSearch });
            //directoryServices.musicDirectoryCategorySave({Code:selectContact.kontactCode , categoryName:ClassificationSearch,categoryType:"Creators"} );
        }
        loadListClassification();

    }

    const deleteSubTaging = (e: any) => {
        directoryServices.tagdelete(e).then((res) => {
            SubclassFicationPayload.referenceCode = selectContact.kontactCode;
            directoryServices.tagListing(SubclassFicationPayload).then((res) => {
                setassociatedSubclassification(res)
            })

        })
    }

    const deleteTaging = (e: any) => {
        directoryServices.musicDirectoryCategoryDelete(e).then((res) => {

            directoryServices.musicDirectoryCategoryListing({ code: selectContact.kontactCode, CategoryType: "Creators" }).then((res) => {
                setassociatedclassification(res)
            })
        })
    }

    const handleNameOnChange = (n: any) => {
        classFicationPayload.pageNumber = n.pageNumber;
        classFicationPayload.pagesize = n.pageSize;
        ClassificationListing()
    }

    const handleNameOnChangeSub = (n: any) => {
        SubclassFicationPayload.pageNumber = n.pageNumber;
        SubclassFicationPayload.pagesize = n.pageSize;
        SubClassificationListing()
    }

    const SaveCreator = () => {
        console.log(selectContact);
        selectContact.creator = true;
        console.log(param.selectedDetail) 

        if(selectContact && selectContact.categoryName){
            delete selectContact.categoryName;
        }

        directoryServices.creatorsSave(selectContact, selectedFile).then((res) => {
            console.log(res)
            if (res && (!res.errorCode || !res.ExceptionMessage)) {
                setSaveStatus(true)
                selectContact.creatorImage = res.creatorImage;
                selectContact.creatorImageTH = res.creatorImageTH;
                loadListClassification();
            }
        })
    }

    const loadListClassification = () => { 

        console.log(selectContact) 

        SubclassFicationPayload.referenceCode = selectContact.kontactCode;
        

        directoryServices.tagListing(SubclassFicationPayload).then((res) => {
            setassociatedSubclassification(res)
        })

        directoryServices.musicDirectoryCategoryListing({ code: selectContact.kontactCode, CategoryType: "Creators" }).then((res) => {
            setassociatedclassification(res)
        })
    }

    useEffect(() => {
        selectContact=[];
        setselectContact(selectContact)
        console.log(param)
        if (param && param.selectedDetail && param.selectedDetail.kontactCode) {
            console.log(param.selectedDetail)
            selectContact = param.selectedDetail;
            setselectContact(selectContact)

            console.log(selectContact)
            console.log(selectContact.creatorImage)
            setkontactDisplay(param.selectedDetail.kontactCode ? param.selectedDetail.kontactDisplay + ' (' + param.selectedDetail.kontactCode + ')' : '' );
            loadListClassification();
        }
    }, [param])

    return (
        <><Drawer open={param.open}
            onClose={closePage}
            direction='right'
            className='react-drawer-left full-height-vh allowVscroll'>

            <div className="ws-6 box pull-right full-height-vh">
                <div className="box-header-main">
                    <h2>New Creator</h2>
                </div>

                <div className="box-content box">
                    <div style={{ fontStyle: "italic" }} className="pm">Search for a Contact to convert to a Creator or click to add a new Creator</div>
                    <div className="border-bottom"></div>

                    <div className="form-format ws-12">
                        <label className="control-label text-left">Contact</label>

                        <div className="label-entries ws-12">
                            <div className="ws-10">
                                <input type="text" id="Contact" name="kontactCode" placeholder="Contact" readOnly value={kontactDisplay} />
                            </div>

                            <div className="ws-2">
                                <button type="button" className="btn-success pll prl pts hide" onClick={() => { setdialogueAddOpenView(true) }}><i className="icon-user-add"></i></button>
                                <ContactFormSearch onChange={onChangeContact} data={""} open={dialogueAddOpenView} />
                            </div>
                        </div>
                    </div>

                    {(kontactDisplay.length > 0) && <div className='form-format ws-12 mtl'>
                        <label className="switch ws-3">
                            <input type="checkbox" checked={selectContact.creator} onClick={() => setCreator()} />
                            {selectContact.creator && <span className="slider round text-left white pm pts"> Yes </span>}
                            {!selectContact.creator && <span className="slider round text-right white pm pts sliderOff">No</span>}
                        </label>

                        <label className={"ws-9 ml " + selectContact.creator}>Convert to a Creator</label>
                    </div>}

                    {(kontactDisplay.length > 0) && <div className="ws-12 mtl" >
                        <label className="control-label text-left ws-12 mt text-bold">Profile Image</label>
                        <div className="ws-12 pm mb">
                            <div className="ws-4">

                                {(selectContact.creatorImage) && <div className="box shadowCoverPicture shadow" style={{ width: "100%" }}>
                                    <div className="coverPicture">
                                        <img className="ws-12" src={selectContact.creatorImage} />
                                    </div>
                                </div>}

                                {!(selectContact.creatorImage) && <table className="box coverPictureContainer shadow">
                                    <tbody><tr>
                                        <td style={{ verticalAlign: "middle"}}>
                                            <i className="icon-new-registration fullImage"></i>
                                        </td>
                                    </tr></tbody>
                                </table>}
                            </div>

                            <div className="ws-7 pm pull-right">
                                <label className="btn-primary" style={{ marginTop: "15%" }}>Upload Image
                                    <input type="file" accept="image/*" id="file-upload" className="hide" onChange={handleFileChange} />
                                </label>

                                <span className="ws-12 mt">Maximum file size 2mb file types accepted .jpg/ .png/ .bmp (Resolution Minimum 300px X 230px).</span>
                            </div>
                        </div>

                    </div>}

                    {(kontactDisplay.length > 0) && <div className='ws-12'>
                        {<div className="form-format ws-12 mtl box pLarge" >
                            <label className="control-label text-left">Classification</label>
                            <div className="label-entries ws-12">
                                <div className="ws-8">
                                    <input type="text" value={ClassificationSearch} placeholder="Type to search for classification" onChange={(e) => setClassificationSearch(e.target.value)} />
                                </div>
                                <div >
                                    <button className="btn-success pl pr pts" onClick={() => ClassificationListing()}>
                                        <i className="icon-search"></i>
                                    </button>
                                </div>
                                {(ClassificationSearch.length > 3) && <div>
                                    <button className="btn-success pl pr pts" onClick={() => ClassificationSave(false)}>
                                        <i className="icon-save"></i>
                                    </button>
                                </div>}
                            </div>

                            <div className="ws-12">
                                <table className="table ml w-76">
                                    <tbody>
                                        {listClassification?.map((values: any) => {
                                            return (
                                                <tr>
                                                    <td width="20%"> <input type="checkbox" onClick={() => ClassificationSave(values.useFreeText)} /> </td>
                                                    <td>{values.useFreeText}</td>
                                                </tr>

                                            )
                                        })
                                        }

                                    </tbody>

                                </table>

                                {(() => {
                                    if (ClassificationtotalRow && ClassificationtotalRow > 10) {
                                        return <Pagination onChange={handleNameOnChange} totalRows={ClassificationtotalRow} />
                                    }

                                })()}

                            </div>

                            {(associatedclassification.length > 0) && <div className="form-format ws-12 mtl" ng-show="directoryClassifications.length > 0">
                                <label className="control-label text-left w-30 mt">Associated Classification(s):</label>
                                {
                                    associatedclassification?.map((values: any, index: any) => {

                                        return (
                                            <div className=''>
                                                <a className="btn ms ps" style={{ background: pickerColors[index], color: "RGB(255,255,255)" }}>
                                                    <span className="mts mb text-center" style={{ color: "#fff" }}>{values.categoryName}</span>
                                                    <span className="pull-right mtss" onClick={() => deleteTaging(values.creatorCategoryId)}>
                                                        <i className="icon-close1 text-s ms pls" style={{ color: "#fff" }}></i>
                                                    </span>
                                                </a>
                                            </div>
                                        )
                                    })


                                }
                            </div>}
                            {(ClassificationtotalRow == 0) && <div className="label-entries ws-8">
                                <div className="alert alert-info">No classification available  </div>
                            </div>}

                        </div>}

                        {<div className="form-format ws-12 mtl box pLarge">
                            <label className="control-label text-left">Sub Classification</label>
                            <div className="label-entries ws-12">
                                <div className="ws-8">
                                    <input type="text" value={SubClassificationSearch} placeholder="Type to search for Sub classification" onChange={(e) => setSubClassificationSearch(e.target.value)} />
                                </div>
                                <div >
                                    <button className="btn-success pl pr pts" onClick={() => SubClassificationListing()}>
                                        <i className="icon-search"></i>
                                    </button>
                                </div>
                                {(SubClassificationSearch.length > 3) && <div>
                                    <button className="btn-success pl pr pts" onClick={() => SubClassificationSave(false)}>
                                        <i className="icon-save"></i>
                                    </button>
                                </div>}
                            </div>

                            <div className="ws-12">
                                <table className="table ml w-76">
                                    <tbody>
                                        {listSubClassification?.map((values: any) => {
                                            return (
                                                <tr>
                                                    <td width="20%"> <input type="checkbox" onClick={() => SubClassificationSave(values.code)} /> </td>
                                                    <td>{values.code}</td>
                                                </tr>

                                            )
                                        })
                                        }

                                    </tbody>

                                </table>

                                {
                                    (() => {
                                        if (SubClassificationtotalRow && SubClassificationtotalRow > 10) {
                                            return <Pagination onChange={handleNameOnChangeSub} totalRows={SubClassificationtotalRow} />
                                        }

                                    })()

                                }

                            </div>

                            {(associatedSubclassification.length > 0) && <div className="form-format ws-12 mtl">
                                <label className="control-label text-left w-30 mt ">Associated Sub Classification(s):</label>

                                {
                                    associatedSubclassification?.map((values: any, index: any) => {

                                        return (
                                            <div>
                                                <a className="btn ms ps" style={{ background: pickerColors[index], color: "#fff" }}>
                                                    <span className="mts mb text-center" style={{ color: "#fff" }}>{values.text}</span>
                                                    <span className="pull-right mtss" onClick={() => deleteSubTaging(values.id)}>
                                                        <i className="icon-close1 text-s ms pls" style={{ color: "#fff" }}></i>
                                                    </span>
                                                </a>
                                            </div>
                                        )
                                    })


                                }
                            </div>}
                            {(SubClassificationtotalRow == 0) && <div className="label-entries ws-8">
                                <div className="alert alert-info">No Subclassification available</div>
                            </div>}

                        </div>}
                    </div>}

                    <div className="box-footer">
                        <div className="pull-right">
                            <button className="btn-pink pull-right" onClick={() => closePage()}>
                                Close
                            </button>
                            <button className="btn btn-success" onClick={() => SaveCreator()}>
                                Save
                            </button>
                        </div>

                    </div>
                </div> 
            </div>

        </Drawer></>
    )
}