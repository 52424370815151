import { CommonUtilities }  from '../../commonUtils';

export default class directoryServices  {


    public static directoryMenu = [
        {
            title: 'Creators',
            route: 'creators'
        }, {
            title: 'Imprints',
            route: 'imprints'
        },{
            title: 'Assets',
            route: 'assets'
        }, {
            title: 'Accounts',
            route: 'accounts'
        }, {
            title: 'Chart Summary',
            route: 'summary'
        }, {
            title: 'Fraudulent',
            route: 'fraudulent'
        }
    ]

    public static columnName = [
        {
            header: 'Code',
            route: 'Tracks',
            type : 'textField',
            mapping: 'assetCode'
        },{
            header: 'Title',
            route: 'Tracks',
            type : 'textField',
            mapping: 'assetTitle'
        }, {
            header: 'Artist',
            route: 'Tracks',
            type : 'textField',
            mapping: 'assetContactName'
        }, {
            header: 'Label',
            route: 'Tracks',
            type : 'textField',
            mapping: 'labelName'
        },



        {
            header: 'Code',
            route: 'Releases',
            type : 'textField',
            mapping: 'assetCode'
        }, {
            header: 'Artist',
            route: 'Releases',
            type : 'textField',
            mapping: 'assetContactName'
        },{
            header: 'Title',
            route: 'Releases',
            type : 'textField',
            mapping: 'assetTitle'
        }, {
            header: 'Label',
            route: 'Releases',
            type : 'textField',
            mapping: 'labelName'
        },

        
        {
            header: 'UPC',
            route: 'ReleasesTracks',
            type : 'tableField',
            mapping: 'upc'
        },
        {
            header: 'Release Code',
            route: 'ReleasesTracks',
            type : 'textField',
            mapping: 'releaseCode'
        },
        {
            header: 'Release Title',
            route: 'ReleasesTracks',
            type : 'textField',
            mapping: 'releaseTitle'
        },        
        {
            header: 'Release Artist',
            route: 'ReleasesTracks',
            type : 'tableField',
            mapping: 'releaseArtist'
        },        
        {
            header: 'Label',
            route: 'ReleasesTracks',
            type : 'tableField',
            mapping: 'label'
        },         
        {
            header: 'Release Format',
            route: 'ReleasesTracks',
            type : 'tableField',
            mapping: 'releaseFormat'
        }, 
        {
            header: 'Tracks Title',
            route: 'ReleasesTracks',
            type : 'textField',
            mapping: 'trackTitle'
        },        
        {
            header: 'Tracks Artist',
            route: 'ReleasesTracks',
            type : 'tableField',
            mapping: 'trackArtist'
        },
        {
            header: 'ISRC',
            route: 'ReleasesTracks',
            type : 'textField',
            mapping: 'iSRC'
        },
        {
            header: 'Volume',
            route: 'ReleasesTracks',
            type : 'tableField',
            mapping: 'volume'
        },
        {
            header: 'TrackNumber',
            route: 'ReleasesTracks',
            type : 'tableField',
            mapping: 'trackNumber'
        },


        {
            header: 'CatLog Number',
            route: 'DeaperPrice',
            type : 'textField',
            mapping: 'CatLogNumber'
        }, 
        {
            header: 'Currency Code',
            route: 'DeaperPrice',
            type : 'textField',
            mapping: 'currencyCode'
        },
        {
            header: 'Currency Dealer',
            route: 'DeaperPrice',
            type : 'tableField',
            mapping: 'currencyDealer'
        },
        {
            header: 'Dealer Price',
            route: 'DeaperPrice',
            type : 'tableField',
            mapping: 'dealerPrice'
        },        
        {
            header: 'Exch Rate',
            route: 'DeaperPrice',
            type : 'tableField',
            mapping: 'exchRate'
        },
        {
            header: 'Lock prices',
            route: 'DeaperPrice',
            type : 'tableField',
            mapping: 'lockprices'
        },
        {
            header: 'Price Basis',
            route: 'DeaperPrice',
            type : 'textField',
            mapping: 'dealerPrice'
        }, {
            header: 'Territory Area',
            route: 'DeaperPrice',
            type : 'textField',
            mapping: 'territoryArea'
        },
        {
            header: 'Price ID',
            route: 'DeaperPrice',
            type : 'tableField',
            mapping: 'priceID'
        }

        /**song */
        ,{
            header: 'Code',
            route: 'Songs',
            type : 'textField',
            mapping: 'assetCode'
        },{
            header: 'Title',
            route: 'Songs',
            type : 'textField',
            mapping: 'assetTitle'
        }, {
            header: 'Artist',
            route: 'Songs',
            type : 'textField',
            mapping: 'assetContactName'
        }, {
            header: 'Label',
            route: 'Songs',
            type : 'textField',
            mapping: 'labelName'
        }

        /** Bundle*/
        ,{
            header: 'Bundle Code',
            route: 'Bundle',
            type : 'textField',
            mapping: 'bundleCode'
        },
        {
            header: 'Bundle Title',
            route: 'Bundle',
            type : 'tableField',
            mapping: 'bundleTitle'
        }        
        ,{
            header: 'Imprint',
            route: 'Bundle',
            type : 'textField',
            mapping: 'bundleImprint'
        },
        {
            header: 'Dim 1',
            route: 'Bundle',
            type : 'tableField',
            mapping: 'bundleDim1Code'
        },
        {
            header: 'Dim 2',
            route: 'Bundle',
            type : 'tableField',
            mapping: 'bundleDim2Code'
        },
        {
            header: 'Dim 3',
            route: 'Bundle',
            type : 'tableField',
            mapping: 'bundleDim3Code'
        }
    ]

    public static columnUploadName = [
        {
            header: 'ISRC',
            route: 'Tracks',
            type : 'tableField',
            mapping: 'iSRC'
        },
        {
            header: 'Title',
            route: 'Tracks',
            type : 'texttableFieldField',
            mapping: 'title'
        }, 
        {
            header: 'Artist',
            route: 'Tracks',
            type : 'tableField',
            mapping: 'artist'
        }, 
        {
            header: 'Label',
            route: 'Tracks',
            type : 'tableField',
            mapping: 'label'
        },
        {
            header: 'Dim1',
            route: 'Tracks',
            type : 'tableField',
            mapping: 'dim1'
        },
        {
            header: 'Product Status',
            route: 'Tracks',
            type : 'tableField',
            mapping: 'productstatus'
        },
        {
            header: 'Product Status Ext',
            route: 'Tracks',
            type : 'tableField',
            mapping: 'productStatusExt'
        },
        /* Releases */
        {
            header: 'Upc',
            route: 'Releases',
            type : 'tableField',
            mapping: 'upc'
        }, 
        {
            header: 'Title',
            route: 'Releases',
            type : 'tableField',
            mapping: 'title'
        },
        {
            header: 'Artist',
            route: 'Releases',
            type : 'tableField',
            mapping: 'artist'
        }, 
        {
            header: 'Label',
            route: 'Releases',
            type : 'tableField',
            mapping: 'label'
        },
        {
            header: 'Master code',
            route: 'Releases',
            type : 'tableField',
            mapping: 'masterRelease'
        },
        {
            header: 'Release Format',
            route: 'Releases',
            type : 'tableField',
            mapping: 'releaseFormat'
        },
        {
            header: 'Release Code',
            route: 'Releases',
            type : 'tableField',
            mapping: 'releaseCode'
        },
        {
            header: 'Dim1',
            route: 'Releases',
            type : 'tableField',
            mapping: 'dim1'
        },
        {
            header: 'Product Status',
            route: 'Releases',
            type : 'tableField',
            mapping: 'productStatus'
        },
        {
            header: 'Product Status Ext',
            route: 'Releases',
            type : 'tableField',
            mapping: 'productStatusExt'
        },

        /* Releases TRACKS */
        {
            header: 'UPC',
            route: 'ReleasesTracks',
            type : 'tableField',
            mapping: 'upc'
        },
        {
            header: 'Release Code',
            route: 'ReleasesTracks',
            type : 'textField',
            mapping: 'releaseCode'
        },
        {
            header: 'Release Title',
            route: 'ReleasesTracks',
            type : 'textField',
            mapping: 'releaseTitle'
        },        
        {
            header: 'Release Artist',
            route: 'ReleasesTracks',
            type : 'tableField',
            mapping: 'releaseArtist'
        },  
        {
            header: 'Master Release',
            route: 'ReleasesTracks',
            type : 'tableField',
            mapping: 'masterRelease'
        },       
        {
            header: 'Label',
            route: 'ReleasesTracks',
            type : 'tableField',
            mapping: 'label'
        },         
        {
            header: 'Release Format',
            route: 'ReleasesTracks',
            type : 'tableField',
            mapping: 'releaseFormat'
        }, 
        {
            header: 'Tracks Title',
            route: 'ReleasesTracks',
            type : 'textField',
            mapping: 'trackTitle'
        },        
        {
            header: 'Tracks Artist',
            route: 'ReleasesTracks',
            type : 'tableField',
            mapping: 'trackArtist'
        },
        {
            header: 'ISRC',
            route: 'ReleasesTracks',
            type : 'textField',
            mapping: 'iSRC'
        },
        {
            header: 'Volume',
            route: 'ReleasesTracks',
            type : 'tableField',
            mapping: 'volume'
        },
        {
            header: 'Dim1',
            route: 'ReleasesTracks',
            type : 'tableField',
            mapping: 'dim1'
        },
        {
            header: 'Product Status',
            route: 'ReleasesTracks',
            type : 'tableField',
            mapping: 'productstatus'
        },
        {
            header: 'Product Status Ext',
            route: 'ReleasesTracks',
            type : 'tableField',
            mapping: 'productStatusExt'
        },


/*DeaperPrice */
        {
            header: 'Release Code',
            route: 'DeaperPrice',
            type : 'textField',
            mapping: 'releaseCode'
        },
        {
            header: 'CurrencyCode',
            route: 'DeaperPrice',
            type : 'textField',
            mapping: 'currencyCode'
        }, 
        {
            header: 'Currency Dealer',
            route: 'DeaperPrice',
            type : 'textField',
            mapping: 'CurrencyDealer'
        },
        {
            header: 'Local Dealer',
            route: 'DeaperPrice',
            type : 'tableField',
            mapping: 'LocalDealer'
        },
        {
            header: 'Exch Rate',
            route: 'DeaperPrice',
            type : 'tableField',
            mapping: 'exchRate'
        },        
        {
            header: 'Price Basis',
            route: 'DeaperPrice',
            type : 'tableField',
            mapping: 'priceBasis'
        },
        {
            header: 'Territory Code',
            route: 'DeaperPrice',
            type : 'tableField',
            mapping: 'territoryCode'
        },
        {
            header: 'LicenseID',
            route: 'DeaperPrice',
            type : 'textField',
            mapping: 'licenseID'
        }, 
        {
            header: 'Release Artist',
            route: 'DeaperPrice',
            type : 'textField',
            mapping: 'territoryArea'
        },
        {
            header: 'Release Title',
            route: 'DeaperPrice',
            type : 'tableField',
            mapping: 'releaseTitle'
        },
        {
            header: 'Dim1',
            route: 'DeaperPrice',
            type : 'tableField',
            mapping: 'dim1'
        },
        {
            header: 'Product Status',
            route: 'DeaperPrice',
            type : 'tableField',
            mapping: 'productstatus'
        }, 

        /**SONG */
        {
            header: 'Song Code',
            route: 'Songs',
            type : 'textField',
            mapping: 'songCode'
        },
        {
            header: 'Song Title',
            route: 'Songs',
            type : 'tableField',
            mapping: 'title'
        }        
        ,{
            header: 'Writer',
            route: 'Songs',
            type : 'textField',
            mapping: 'writer'
        },
        {
            header: 'Publisher',
            route: 'Songs',
            type : 'tableField',
            mapping: 'publisher'
        },
        {
            header: 'Share',
            route: 'Songs',
            type : 'tableField',
            mapping: 'share'
        },
        {
            header: 'Imprint',
            route: 'Songs',
            type : 'tableField',
            mapping: 'imprint'
        },
        {
            header: 'Dim1',
            route: 'Songs',
            type : 'tableField',
            mapping: 'dim1'
        },
        {
            header: 'Product Status',
            route: 'Songs',
            type : 'tableField',
            mapping: 'productStatus'
        },
        {
            header: 'Product Status Ext',
            route: 'Songs',
            type : 'tableField',
            mapping: 'productStatusExt'
        }

        /** Bundle*/
        ,{
            header: 'Bundle Code',
            route: 'Bundle',
            type : 'textField',
            mapping: 'bundleCode'
        },
        {
            header: 'Bundle Title',
            route: 'Bundle',
            type : 'tableField',
            mapping: 'bundleTitle'
        }
        ,{
            header: 'AssetCode',
            route: 'Bundle',
            type : 'textField',
            mapping: 'assetCode'
        },       
        ,{
            header: 'Imprint',
            route: 'Bundle',
            type : 'textField',
            mapping: 'bundleImprint'
        },
        {
            header: 'Dim 1',
            route: 'Bundle',
            type : 'tableField',
            mapping: 'bundleDim1Code'
        },
        {
            header: 'Dim 2',
            route: 'Bundle',
            type : 'tableField',
            mapping: 'bundleDim2Code'
        },
        {
            header: 'Dim 3',
            route: 'Bundle',
            type : 'tableField',
            mapping: 'bundleDim3Code'
        }

    ]

    public static accountFiled = [
        {
            header: 'Account Code',
            route: 'Templates',
            type : 'headerName',
            mapping: 'kontactCode'
        },
        {
            header: 'Account Name',
            route: 'Templates',
            type : 'headerName',
            mapping: 'name'
        },
        {
            header: 'Template ID',
            route: 'Templates',
            type : 'headerName',
            mapping: 'templateId'
        },
        {
            header: 'Template Name',
            route: 'Templates',
            type : 'headerName',
            mapping: 'templateName'
        },


        {
            header: 'Territory',
            route: 'Territory',
            type : 'headerName',
            mapping: 'terrAlias'
        },
        {
            header: 'System Territory ',
            route: 'Territory',
            type : 'headerName',
            mapping: 'terrOurs'
        },



        {
            header: 'Format To Report',
            route: 'Formats',
            type : 'headerName',
            mapping: 'terrAlias'
        },
        {
            header: 'SubContent',
            route: 'Formats',
            type : 'headerName',
            mapping: 'terrAlias'
        },
        {
            header: 'SubProvider',
            route: 'Formats',
            type : 'headerName',
            mapping: 'terrAlias'
        },
        {
            header: 'Account Code',
            route: 'Formats',
            type : 'headerName',
            mapping: 'terrAlias'
        },


        {
            header: 'Reported Code',
            route: 'CategoryAliases',
            type : 'headerName',
            mapping: 'theirCode'
        },
        {
            header: 'Matched Code',
            route: 'CategoryAliases',
            type : 'headerName',
            mapping: 'ourCode'
        },
        {
            header: 'ISRC',
            route: 'CategoryAliases',
            type : 'headerName',
            mapping: 'iSrC'
        },
        {
            header: 'Catalog',
            route: 'CategoryAliases',
            type : 'headerName',
            mapping: 'ourCode'
        },
        {
            header: 'Track Title',
            route: 'CategoryAliases',
            type : 'headerName',
            mapping: 'trackTitle'
        },

        {
            header: 'Reported Code',
            route: 'SongAliases',
            type : 'headerName',
            mapping: 'terrAlias'
        },
        {
            header: 'Matched Code',
            route: 'SongAliases',
            type : 'headerName',
            mapping: 'ourCode'
        },
        {
            header: 'Song 1',
            route: 'SongAliases',
            type : 'headerName',
            mapping: 'songCode1'
        },
        {
            header: 'Song 2',
            route: 'SongAliases',
            type : 'headerName',
            mapping: 'songCode2'
        },
        {
            header: 'Song 3',
            route: 'SongAliases',
            type : 'headerName',
            mapping: 'songCode3'
        },
        {
            header: 'Song 4',
            route: 'SongAliases',
            type : 'headerName',
            mapping: 'songCode4'
        },
        {
            header: 'Song Title',
            route: 'SongAliases',
            type : 'headerName',
            mapping: 'songTitle'
        },
        {
            header: 'Writer',
            route: 'SongAliases',
            type : 'headerName',
            mapping: 'writerName'
        }
    ]

    static creatorsListing(param :any) {
        var url = 'MusicDirectory/creators';
        return CommonUtilities.postData(url, param);
    }

    static managementListing(param :any) {
        var url = 'contracts/genre/main/list';
        return CommonUtilities.postData(url, param);
    }

    static creatorsDeletion(param :any) {
        var url = 'contacts/info2';
        return CommonUtilities.postData(url, param);
    }

    static creatorsSave(data :any,file :any) {
        var url = 'contacts/info';
        return CommonUtilities.postUploadFile(url, file,data);
    }

    static creatorsListingAll(param :any) {
        var url = 'MusicDirectory/creators/all';
        return CommonUtilities.postData(url, param);
    }

    static MainAssetListing(param :any) {
        var url = 'MainAsset/ListByCategory';
        return CommonUtilities.postData(url, param);
    }

    static MainAssetListingAll(param :any) {
        var url = 'MainAsset/ListAll';
        return CommonUtilities.postData(url, param);
    }

    static creatorRelatedAsset(param :any) {
        console.log( CommonUtilities.buildQueryString(param , true , true))
        var url = 'MusicDirectory/musicAssetDirectory/creatorCatalogue?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url); 
    }

    static MusicAssetDirectorySave(param :any) {
        var url = 'MusicDirectory/musicAssetDirectory/save';
        return CommonUtilities.postData(url, param);
    }

    static MusicAssetDirectoryDelete(param :any) {
        var url = 'MusicDirectory/musicAssetDirectory/delete/musicCreatorAsset?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.deleteData(url);
    }

    static creatorRelatedArtist(param :any) {
        var url = 'MusicDirectory/musicCreatorKontact/relatedArtist?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url); 
    }

    static saveArtist(param :any) {
        var url = 'MusicDirectory/musicCreatorKontact/saveArtist';
        return CommonUtilities.postData(url, param);
    }

    static RelatedArtistListing(param :any) {
        var url = 'MusicDirectory/list1?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url); 
    }

    static zFreeTextListing(param :any) {
        var url = 'zFreeText/classification';
        return CommonUtilities.postData(url, param);
    }
    
    static zFreeTextsave(param :any) {
        var url = 'zFreeText/save';
        return CommonUtilities.postData(url, param);
    }

    static tagListing(param :any) {
        var url = 'contract/tag/list?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url); 
    }

    static tagSave(param :any) {
        var url = 'contract/tag/save';
        return CommonUtilities.putData(url, param);
    }

    static tagdelete(param :any) {
        var url = 'contract/tag/delete/'+param;
        return CommonUtilities.deleteData(url); 
    }

    static musicDirectoryCategorySave(param :any) {
        var url = 'musicDirectory/musicDirectoryCategory/save';
        return CommonUtilities.postData(url, param);
    }

    static musicDirectoryCategoryListing(param :any) {
        var url = 'musicDirectory/musicDirectoryCategory/list?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }
    
    static musicDirectoryCategoryDelete(param :any) {
        var url = 'musicDirectory/musicDirectoryCategory/delete/'+param;
        return CommonUtilities.deleteData(url); 
    }

    /*********************imprint**********************/
    //https://qa.onkorrect.com/Api/metadata/labelSearch

    static labelSearchListing(param :any) {
        var url = 'metadata/labelSearch'
        return CommonUtilities.postData(url, param);
    }

    static labelSearchListingAll(param :any) {
        var url = 'metadata/labelSearch/all'
        return CommonUtilities.postData(url, param);
    }

    static deleteImprint(param :any) {
        var url = 'metadata/label/'+param
        return CommonUtilities.deleteData(url);
    }

    static imprintSave(file: any, data: any) {
        var url = 'metadata/label/save'
        return CommonUtilities.postUploadFile(url, file,data);
    }

    /*********************Asset**********************/
    //https://staging.onkorrect.com/Api/MainAsset/ListAsset
    static LinkAssetListing(param :any) {
        var url = 'MainAsset/ListAsset'
        return CommonUtilities.postData(url, param);
    }
    
    static BundleListing(param :any) {
        var url = 'musicDirectory/bundle/list?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static BundleAssetListing(param :any) {
        var url = 'musicDirectory/bundleAsset/list?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url); 
    }

    static bundleAssetLink(param :any) {
        var url = 'musicDirectory/bundleAsset/save';
        return CommonUtilities.postData(url, param);
    }

    static bundleAssetDelete(param :any) {
        var url = 'musicDirectory/bundleAsset/delete'+param;
        return CommonUtilities.deleteData(url);
    }
    
    /*
     *****************************************************************************
     ****************************** Account **************************************
     *****************************************************************************
    */

    static AccountListing(param :any) {
        var url = 'client/dspSearch'
        return CommonUtilities.postData(url, param);
    }

    static saveAccountImage(file:any, filename:any) {
        var url = 'client/uploadDSPLogo?filename=' + filename
        return CommonUtilities.postUploadFile(url, file ,{} );
    }

    static saveAccount(param :any) {
        var url = 'client/saveDSP';
        return CommonUtilities.postData(url, param);
    };

    static AccountListingAll(param :any) {
        var url = 'client/dspSearch/all'
        return CommonUtilities.postData(url, param);
    }
    
    static AccountTemplate(param :any) {
        var url = 'client/listAccountTemplate'
        return CommonUtilities.postData(url,param);
    }

    static AccountTerritoryAliases(param :any) {
        var url = 'salesAnalysis/getTerritoryAliases?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static AccountFormatAlias(param :any) {
        var url = 'salesAnalysis/getFormatAlias?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static AccountRelAliases(param :any) {
        var url = 'salesAnalysis/getRelAliases?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static AccountRelAliasesPub(param :any) {
        var url = 'salesAnalysis/getRelAliasesPub?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    /*********************Upload**********************/
    static assetUploaderList(param :any) {
        var url = 'MusicDirectory/assetUploader/list?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static assetUploaderExport(param :any) {
        var url = 'MusicDirectory/assetUploader/assetExport?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.exportWithToken('GET', url, {}, '_blank', 'AssetDetails');
    }

    /*  assetUploader_release  */
    static assetUploaderReleaseList(param :any) {
        var url = 'MusicDirectory/assetUploader/release/list?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static assetUploaderReleaseExport(param :any) {
        var url = 'MusicDirectory/assetUploader/release/Export?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.exportWithToken('GET', url, {}, '_blank', 'AssetDetails');
    }

    static ReleasevalidationList(param:any) {
        var url = 'MusicDirectory/assetUploader/release/validationList?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static assetUploaderReleaseSave(param :any) {
        var url = 'MusicDirectory/assetUploader/release/save'
        return CommonUtilities.postData(url,param);
    }

    static clearUploadRelease() {
        var url = 'MusicDirectory/assetUploader/release/clear';
        return CommonUtilities.getData(url);
    }

    static assetUploaderReleaseDelete(param :any) {
        var url = 'MusicDirectory/assetUploader/release/delete/' +param
        return CommonUtilities.deleteData(url);
    }

    /* assetUploader_track  */
    static clearUploadTrack() {
        var url = 'MusicDirectory/assetUploader/track/clear';
        return CommonUtilities.getData(url);
    }

    static assetUploaderTrackList(param :any) {
        var url = 'MusicDirectory/assetUploader/track/list?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static assetUploaderTrackExport(param :any) {
        var url = 'MusicDirectory/assetUploader/track/Export?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.exportWithToken('GET', url, {}, '_blank', 'AssetDetails');
    }

    static assetUploaderTrackSave(param :any) {
        var url = 'MusicDirectory/assetUploader/track/save'
        return CommonUtilities.postData(url,param);
    }

    static assetUploaderTrackDelete(param :any) {
        var url = 'MusicDirectory/assetUploader/track/delete/' +param
        return CommonUtilities.deleteData(url);
    }

    static TrackvalidationList(param:any) {
        var url = 'MusicDirectory/assetUploader/track/validationList?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    /* releaseTrack_track  */
    static clearUploadReleaseTrack() {
        var url = 'MusicDirectory/assetUploader/releaseTrack/clear';
        return CommonUtilities.getData(url);
    }

    static releaseTrackUploadList(param :any) {
        var url = 'MusicDirectory/assetUploader/releaseTrack/list?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static releaseTrackUploadExport(param :any) {
        var url = 'MusicDirectory/assetUploader/releaseTrack/Export?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.exportWithToken('GET', url, {}, '_blank', 'AssetDetails');
    }

    static assetUploaderReleaseTrackSave(param :any) {
        var url = 'MusicDirectory/assetUploader/releaseTrack/save'
        return CommonUtilities.postData(url,param);
    }

    static assetUploaderReleaseTrackDelete(param :any) {
        var url = 'MusicDirectory/assetUploader/releaseTrack/delete/' +param
        return CommonUtilities.deleteData(url);
    }

    static ReleaseTrackvalidationList(param:any) {
        var url = 'MusicDirectory/assetUploader/releaseTrack/validationList?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    /* Upload Dealer price */
    static dealerPriceListing(param :any) {
        var url = 'MusicDirectory/assetUploader/CompPrices/dealerPrice/list?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dealerPriceExport(param :any) {
        var url = 'MusicDirectory/assetUploader/CompPrices/dealerPrice/Export?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.exportWithToken('GET', url, {}, '_blank', 'AssetDetails');
    }


    static dealerPriceUploadList(param :any) {
        var url = 'MusicDirectory/assetUploader/dealerPrice/list?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dealerPriceUploadExport(param :any) {
        var url = 'MusicDirectory/assetUploader/dealerPrice/Export?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.exportWithToken('GET', url, {}, '_blank', 'AssetDetails');
    }
    
    static clearUploadDealerPrice() {
        var url = 'MusicDirectory/assetUploader/dealerPrice/clear';
        return CommonUtilities.getData(url);
    }

    static assetUploaderDealerPriceSave(param :any) {
        var url = 'MusicDirectory/assetUploader/dealerPrice/save'
        return CommonUtilities.postData(url,param);
    }

    static assetUploaderDealerPriceDelete(param :any) {
        var url = 'MusicDirectory/assetUploader/dealerPrice/delete/' +param
        return CommonUtilities.deleteData(url);
    }

    static DealerPricevalidationList(param:any) {
        var url = 'MusicDirectory/assetUploader/dealerPrice/validationList?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    /* Song */
    static songUploadList(param :any) {
        var url = 'MusicDirectory/assetUploader/song/list?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static assetUploaderSongExport(param :any) {
        var url = 'MusicDirectory/assetUploader/song/Export?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.exportWithToken('GET', url, {}, '_blank', 'AssetDetails');
    }

    static clearUploadSongs() {
        var url = 'songLoader/clearMinSet';
        return CommonUtilities.getData(url);
    }

    static songUpload(file:any) {
        console.log(file)
        var url = 'songLoader/fileUploadMinSet';
        return CommonUtilities.postUploadFile(url, file ,null);
    }

    /* Upload Release and Tracks */
    static releaseTracklisting(param :any) {
        var url = 'MusicDirectory/assetUploader/releaseTracklisting?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static releaseTrackExport(param :any) {
        var url = 'MusicDirectory/assetUploader/releaseTracklisting/Export?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.exportWithToken('GET', url, {}, '_blank', 'AssetDetails');
    }

    /* upload */
    static assetUploaderCount(param :any) {
        var url = 'MusicDirectory/assetUploader/count?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static generateMissingUPC() {
        var url = 'contracts/mechanical/release/generateMissingUPC';
        return CommonUtilities.getData(url);
    }

    static generateMissingUPCISRC() {
        var url = 'contracts/mechanical/release/generateMissingUPCISRC';
        return CommonUtilities.getData(url);
    }
    static generateMissingISRC() {
        var url = 'metadata/track/generateMissingISRC';
        return CommonUtilities.getData(url);
    }

    /* file uploading */
    static trackUpload(file:any) {
        var url = 'MusicDirectory/musicAssetDirectory/trackUpload';
        return CommonUtilities.postUploadFile(url, file ,{} );
    }

    static releaseUpload(file:any) {
        var url = 'MusicDirectory/musicAssetDirectory/releaseUpload';
        return CommonUtilities.postUploadFile(url, file ,{} );
    }

    static validation(param:any) {
        var url = 'MusicDirectory/assetBundles/validation?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static loadIFileLog(type:any, fileName:any, pageNumber:any, pageSize:any, source:any) {
        var url =  'importLog/SearchFileLog/' + type + '/' + fileName + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&source=' + source;
        return CommonUtilities.getData(url);
    };

    static loadIFileLogDetail(source:any) {
        var url =  'importLog/searchProgressImportLog/'+ source;
        return CommonUtilities.getData(url);
    };

    /**Bundle */
    static BundleList(param:any) {
        var url = 'musicDirectory/bundle/list?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static BundleUploadList(param:any) {
        var url = 'MusicDirectory/assetBundles/listBundles?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static BundleListExport(param :any) {
        var url = 'MusicDirectory/assetBundles/bundleExport?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.exportWithToken('GET', url, {}, '_blank', 'BundleDetails');
    }    
    
    static bundleSelect = function (pageNumber :any, pageSize :any) {
        var url = 'MusicDirectory/assetBundles/listBundles?pageNumber=' + pageNumber + '&pageSize=' + pageSize;
        return CommonUtilities.getData(url);
    };

    static fileCommit = function (type :any , useReleaseCodeAsMaster :any) {
        var url = 'dataLoadContact/commit?type='+type;
        if(useReleaseCodeAsMaster){
            url = url +"&useReleaseCodeAsMaster=" + useReleaseCodeAsMaster;
        }  
        return CommonUtilities.getData(url);
    };    

    static BundleUpload(file :any) {
        var url = 'MusicDirectory/assetBundles/fileUpload';
        return CommonUtilities.postUploadFile(url, file ,{} );
    }
    static BundleClear() {
        var url = 'MusicDirectory/assetBundles/clear';
        return CommonUtilities.getData(url);
    }   

    static imprintListing() {
        var url = 'metadata/label?companyName=&orderBy=CompanyName&sortBy=ASC'
        return CommonUtilities.getData(url);
    }

    static generateItemISRC(param :any) {
        var url = 'contracts/mechanical/tracks/generateItem'
        return CommonUtilities.postData(url,param);
    }

    static generateSongRef() {
        var url = 'publishing/songProfileDetails/getSongRef'
        return CommonUtilities.getData(url);
    }
    
    static songSave(param :any, songImg :any) {
        var url = 'publishing/song/saveSongDetails'
        return CommonUtilities.postUploadFile(url, param ,songImg); 
    }
    
    static generateUPC(param :any) {
        var url = 'contracts/mechanical/release/generateItem?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }
    static trackSave(param :any) {
        var url = 'contracts/mechanical/tracks'
        return CommonUtilities.postData(url,param);
    }
    
    static ReleaseSave( payload :any,param :any) {
        var url = 'contracts/mechanical/release?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.postData(url,payload);
    }

    static uploadTrackImage(param :any, data:any){
        var url = 'MainAsset/assets/uploadImage';
        return CommonUtilities.postUploadFile(url, param ,data);
    }

    static MainAssetsave(param :any) {
        var url = 'MainAsset/save'
        return CommonUtilities.postData(url,param);
    }

    static BundleSave(file:any , param:any){
        var url = "musicDirectory/bundle/save"
        return CommonUtilities.postUploadFile(url, file ,param );
    }

    static metadataTrackListing(param :any) {
        var url = 'metadata/track/search'
        return CommonUtilities.postData(url,param);
    }
    
    static metadataSongListing(param :any) {
        var url = 'publishing/song/list'
        return CommonUtilities.postData(url,param);
    }

    static metadataReleaseListing(param :any) {
        var url = 'metadata/compilation/search/list'
        return CommonUtilities.postData(url,param);
    }

    static salesAnalysisinvoices(type:any ,param :any) {
        var url = 'salesAnalysis/invoices/'+type+'?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static deleteinvoices(param :any) {
        var url = 'salesAnalysis/deleteInvoices';
        return CommonUtilities.postData(url,param);
    }

    static financialPerformance(param :any) {
        var url = 'contracts/financialPerformance?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static genericAPIListing(param :any) {
        var url = 'common/genericListing';
        return CommonUtilities.postData(url,param)
    }

    static excelGenericExport(data: any) {
        const url = 'CrystalReport/excelGeneric/export?'+ CommonUtilities.buildQueryString(data , true , true) ;
        return CommonUtilities.exportWithToken('GET', url, {}, '_blank', 'excelGenericExport');
    };

    static singlePageSummaryListing(param :any) {
        var url = 'contracts/analytics/singlePageSummary';
        return CommonUtilities.postData(url,param)
    }    

    static getClientDetails() {
        var url = 'client/getClientDetails'
        return CommonUtilities.getData(url);
    }

    static getYearlyDSPs(param :any) {
        var url = 'common/genericListing'
        return CommonUtilities.postData(url,param)
    }
    
}